import { MetadataContent } from '@jutro/legacy/uiconfig';
import React from 'react';
import PreCode from '../../Code/PreCode';
import metadata from './WniDataTableTab.metadata.json5';
import {
    baseTableCode,
    multiTableCode,
    selectedAllTableCode,
    singleTableCode,
} from './WniDataTableTab.util';

const tableData = [
    {
        vehicleType: 'PrivatePassenger',
        effectiveDate: {
            year: 2024,
            month: 1,
            day: 20,
        },
        premium: {
            amount: 23598,
            currency: 'USD',
        },
        phoneNumber: '2011234123',
        number: 123.234,
        integer: 23.43,
    },
    {
        vehicleType: 'Truck',
        effectiveDate: {
            year: 2025,
            month: 1,
            day: 1,
        },
        premium: {
            amount: 2256598,
            currency: 'USD',
        },
        phoneNumber: '2011234145',
        number: 34123.234,
        integer: 234.43,
        unselectable: true,
    },
    {
        vehicleType: 'SpecialType',
        effectiveDate: {
            year: 2025,
            month: 1,
            day: 1,
        },
        premium: {
            amount: 12359482,
            currency: 'USD',
        },
        phoneNumber: '2011234123',
        number: 123.234,
        integer: 23.43,
    },
];

const fieldDatatype = {
    boolean: 'ToggleField',
    checkbox: 'CheckboxField',
    currency: 'CurrencyField',
    number: 'InputNumberField',
    integer: 'InputNumberField',
    radio: 'RadioButtonField',
    select: 'DropdownSelectField',
    string: 'InputField',
    tel: 'PhoneNumberField',
    text: 'InputField',
    textarea: 'TextAreaField',
    typelist: 'DropdownSelectField',
    date: 'DateField',
    year: 'YearField',
    yesno: 'ToggleField',
};
function WniDataTableTab(props) {
    const overrideProps = {
        baseTable: {
            data: tableData,
        },
        baseTableCodes: {
            data: baseTableCode,
        },
        multiTable: {
            data: tableData,
        },
        multiTableCodes: {
            data: multiTableCode,
        },
        selectedAllTable: {
            data: tableData,
        },
        selectedAllTableCodes: {
            data: selectedAllTableCode,
        },
        singleTable: {
            data: tableData,
        },
        singleTableCodes: {
            data: singleTableCode,
        },
    };
    const resolvers = {
        resolveComponentMap: {
            preCode: PreCode,
        },
        resolveCallbackMap: {},
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}

export default WniDataTableTab;

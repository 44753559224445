
import React, { useContext } from 'react';
import _ from 'lodash';
import IMTermComponent from './IMTermComponent';
import SingleClauseContext from '../../context/IMSingleClauseContext';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext';
import IMScheduleTable from '../IMScheduleTable/IMScheduleTable';
import CoverageContext from '../../context/IMCoverageContext';

function IMClauseElementComponent(props) {

    const {
        idPrefix,
        clauseCode: code,
        clausePublicID: publicID,
        isEditable,
    } = useContext(SingleClauseContext)

    const {
        visibleOfTableTitle = false,
        visibleOfDivider = false,
        scheduleTableTitle = '',
        calculateLimit = _.noop
    } = useContext(CoverageContext)
    const coveragesConfig = useContext(CoveragesConfigContext)

    const {
        terms,
        schedule,
    } = props

    const {
        limitCoverageConfig,
        covTermAvailable,
    } = coveragesConfig;

    const curCovTermAvailableConfig = _.get(covTermAvailable, code, {})

    return (
        <div
            id= {`${idPrefix}ClausetElementContainer_[${publicID}]`}
            className= 'clause_element_container'
        >
            {terms
                .filter((term) => {
                    const termCode = _.get(term, 'code_Ext')
                    const curTermAvailableFunc = _.get(curCovTermAvailableConfig, termCode)
                    if (_.isFunction(curTermAvailableFunc)) {
                        const isCurTermAvailable = curTermAvailableFunc()
                        if (!isCurTermAvailable) {
                            return false
                        }
                    }
                    // filter all limit terms in edit mode
                    if (!isEditable) {
                        return true
                    }
                    return _.get(term, 'code_Ext') !== _.get(limitCoverageConfig, code)
                })
                .map((term) => {
                    const termIndex = terms
                        .findIndex((clauseTerm) => _.get(clauseTerm, 'code_Ext') === _.get(term, 'code_Ext'))
                    const visibleOfExtendedLabel = _.get(term, 'visibleOfExtendedLabel_Ext');
                    const extendedLabel = _.get(term ,'extendedLabel_Ext');
                    return <>
                        {/* {visibleOfDivider && <hr/>} */}
                        {visibleOfExtendedLabel && <h4>{extendedLabel}</h4>}
                        <IMTermComponent
                            key={_.get(term, 'code_Ext')}
                            term={term}
                            termIndex = {termIndex}
                            calculateLimit = {() => calculateLimit(_.get(term, 'code_Ext'), code)}
                        />
                    </>
                })}
            {schedule ? <>
                {visibleOfTableTitle && <h4>{scheduleTableTitle}</h4>}
                {visibleOfDivider && <hr/>}
                <IMScheduleTable schedule={schedule} />         
            </>
         : null}
        </div>
        
    )
}

export default IMClauseElementComponent;
import { defineMessages } from 'react-intl';

export default defineMessages({
    // messageId: {
    //     id: 'quoteandbind.wizard.step.Licensed Agent',
    //     defaultMessage: 'Licensed Agent'
    // },
    quoteAdditionalProduct: {
        id: 'custom.component.common.Quote Additional Product',
        defaultMessage: 'Quote Additional Product'
    },
    personalAutoLineName: {
        id: 'gateway.directives.policy-transaction.product-name.Personal Auto',
        defaultMessage: 'Personal Auto'
    },
    homeOwnerLineName: {
        id: 'gateway.directives.policy-transaction.product-name.Homeowners',
        defaultMessage: 'Homeowners'
    },
    HomeLineName: {
        id: 'gateway.directives.policy-transaction.product-name.Home',
        defaultMessage: 'Home'
    },
    dwellingLineName: {
        id: 'gateway.directives.policy-transaction.product-name.Dwelling',
        defaultMessage: 'Dwelling'
    },
    personalUmbrellaLineName: {
        id: 'gateway.directives.policy-transaction.product-name.PersonalUmbrellaLineName',
        defaultMessage: 'Personal Umbrella'
    },
    recreationVehicleLineName: {
        id: 'gateway.directives.policy-transaction.product-name.RecreationVehicleLineName',
        defaultMessage: 'Recreation Vehicle'
    },
    motorcycleLineName: {
        id: 'gateway.directives.policy-transaction.product-name.MotorcycleLineName',
        defaultMessage: 'Motorcycle'
    },
    watercraftLineName: {
        id: 'gateway.directives.policy-transaction.product-name.WatercraftLineName',
        defaultMessage: 'Watercraft'
    },
});

import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { ConfigUtil } from 'wni-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import metadata from './OtherDetails.metadata.json5';
import ScheduledDetails from './ScheduledDetails/ScheduledDetails';
import messages from '../../../CUUnderlyingPage.messages';

const  SCHEDULED_PATH = 'scheduledItems';

function OtherDetails(props) {
    const {
        isInternal,
        currentRow,
        syncWizardData,
        writeValue = _.noop,
        onValidate = _.noop,
        handleValidation,
        showErrors,
        isReadOnly
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    const [itemSelection, updateItemSelection] = useState([]);
    const [scheduledRow, updateScheduledRow] = useState(null);

    const initScheduleData = useRef();
    const currentRowIdPath = _.get(currentRow, 'rowIdPath.value');

    const highlightRowFn = (vm) => {
        WniTableRowUtil.setTablePublicIDSelected(_.get(vm, 'rowIdPath.value'), 'scheduledItemsTable');
    };

    useEffect(() => {
        const newCurrentRow = viewModelService.clone(currentRow);
        const scheduledData = _.get(newCurrentRow, `${SCHEDULED_PATH}.value`, []);
        initScheduleData.current = scheduledData;
    }, [currentRowIdPath]);

    useEffect(() => {
        highlightRowFn(scheduledRow);
    }, [scheduledRow]);

    const syncCurrentRow = (rowData) => {
        if(!rowData) {
            updateScheduledRow(null);
            return false;
        };
        const childrenVM = _.get(currentRow, `${SCHEDULED_PATH}.children`, []);
        const currentIndex = childrenVM.findIndex((vm) => _.get(vm, 'rowIdPath.value') === _.get(rowData, 'rowIdPath.value'));
        if(!_.isNil(currentIndex)) {
            _.set(currentRow.value, `${SCHEDULED_PATH}[${currentIndex}]`, rowData.value);
            syncWizardData(currentRow);
        }
    };

    const addItem = () => {
        const childrenVM = _.get(currentRow, SCHEDULED_PATH, {});
        const { _dtoName, _xCenter } = childrenVM;
        const defaultValue = {
            rowIdPath: ConfigUtil.getUuid()
        };
        const newVm = viewModelService.create(defaultValue, _xCenter, _dtoName);
        const scheduledData = _.get(currentRow, `${SCHEDULED_PATH}.value`, []);
        _.set(currentRow.value, SCHEDULED_PATH, [...scheduledData, newVm.value]);
        updateScheduledRow(newVm);
        syncWizardData(currentRow);
        updateItemSelection([]);
    };

    const removeItem = () => {
        const scheduledData = _.get(currentRow, `${SCHEDULED_PATH}.value`, []);
        const newData = _.filter(scheduledData, (item) => !_.includes(itemSelection, item.rowIdPath));
        _.set(currentRow.value, SCHEDULED_PATH, newData);
        syncWizardData(currentRow);
        updateItemSelection([]);
    };

    const cancelItem = () => {
        updateScheduledRow(null);
        if(isReadOnly) {
            return false
        }
        const rowIdPath = _.get(scheduledRow.value, 'rowIdPath');
        const allData = _.get(currentRow.value, SCHEDULED_PATH, []);
        const currIndex = _.findIndex(allData, ((item) => item.rowIdPath === rowIdPath));
        if(_.get(scheduledRow, 'publicId.value')) {
            const initData = _.get(initScheduleData, 'current', []);
            // find the init data about this exposure, and rollback this expsoure when cancel the details
            const initIndex = _.findIndex(initData, ((item) => item.rowIdPath === rowIdPath))
            _.set(currentRow.value, `${SCHEDULED_PATH}[${currIndex}]`, initData[initIndex]);
            syncWizardData(currentRow);
        } else {
            // remove this exposure when cancel the details
            const newData = _.filter(allData, (item) => item.rowIdPath !== rowIdPath);
            _.set(currentRow, SCHEDULED_PATH, newData);
            syncWizardData(currentRow);
        }
    };

    const saveAndClose = () => {
        if(!_.get(scheduledRow, 'aspects.valid') || !_.get(scheduledRow, 'aspects.subtreeValid')) {
            handleValidation()
            return false;
        }
        updateScheduledRow(null);
    };

    const viewOrEdit = (item, index) => {
        const childrenVM = _.get(currentRow, `${SCHEDULED_PATH}.children`, []);
        const currentVM = childrenVM.find((itemVM) => _.get(itemVM, 'rowIdPath.value') === index);
        updateScheduledRow(currentVM);
    };

    const renderCell = (item, index, { path }) => {
        return WniTableRowUtil.renderCell(item.rowIdPath, item[path])
    };
    const overrideProps = {
        '@field': {
            readOnly: isReadOnly,
            showRequired: true,
            shouOptional: false
        },
        removeAction: {
            disabled: _.isEmpty(itemSelection),
            visible: !isReadOnly
        },
        addAction: {
            visible: !isReadOnly
        },
        scheduledItemsTable: {
            path: SCHEDULED_PATH,
            selectionType: isReadOnly ? 'none' : 'multi',
            onSelectionChange: (rows) => updateItemSelection(rows),
            selectedRows: itemSelection
        },
        scheduledItemsSelection: {
            visible: !!scheduledRow,
        },
        scheduledItemsDetails: {
            isReadOnly,
            currentRow: scheduledRow,
            syncCurrentRow,
            onValidate,
            showErrors
        },
        viewOrEditLink: {
            label: isReadOnly ? messages.viewLabel : messages.viewAndEditLabel
        },
        cancelButton: {
            content: isReadOnly ? translator(messages.close) : translator(messages.cancel)
        },
        saveButton: {
            visible: !isReadOnly
        }
    };

    const resolvers = {
        callbackMap: {
            addItem,
            removeItem,
            viewOrEdit,
            renderCell,
            cancelItem,
            saveAndClose
        },
        componentMap: {
            scheduleddetails: ScheduledDetails
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default OtherDetails;

import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { IMBuildersRiskService } from 'wni-capability-quoteandbind-im';
import { PortalConstants } from 'wni-portals-config-js';
import { useTranslator } from '@jutro/locale';
import {
    ErrorsAndWarningsUtil,
    // WizardUtil,
    // WindowUtil,
    QuoteUtil,
} from 'wni-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import IMCommonMainCoverage from '../../components/IMCommonMainCoverage/MainCoveragesCard';
import IMCommonCovPartSchedule from '../../components/IMComonCovPartSchedule/CommonCovPartSchedule';
import IMCommonAdditionalCovCard from '../../components/IMCommonAdditionalCovCard/IMCommonAdditionalCovCard';
import IMExclusionAccordionCard from '../../components/IMCommonExclusionCard/IMCommonExclusionCard';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext'
import IMSearchAndAddClausesPopup from '../../components/IMSearchAndAddClausesPopup/IMSearchAndAddClausesPopup';
import IMCoveragesConfig from '../../config/IMCoverages.config';
import WizardPage from '../../templates/IMWizardPage';
import metadata from './IMBuildersRiskPage.metadata.json5';
import messages from './IMBuildersRiskPage.messages';

const BUILDERSRISK_ACCORDION_CARD_COMPONENT_MAPS = ['buildersRiskMainCov', 'buildersRiskRatingFieldsCov', 'coveragePartSchedule']
function IMBuildersRiskPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        imBuildersRiskService,
        markFollowingWizardStepsUnvisited,
        readOnly
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            periodStatus
        }
    } = submissionVM.value

    const modalApi = useWniModal();
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('IMBuildersRiskPage');

    const [validationIssues, updateValidationIssues] = useState([]);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [coveragePartClauses, setCoveragePartClauses] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    const handleValidation = useCallback(() => {
        //
    }, [invalidFields]);

    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }

    const initCoverablePartClauses = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus === PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await imBuildersRiskService.getBuildersRiskClausesWithSync(jobID, sessionUUID, authHeader)
            : await imBuildersRiskService.getBuildersRiskClauses(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setCoveragePartClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID])

    useEffect(() => {
        initCoverablePartClauses()
    }, [])

    const showClausesModal = useCallback((clauseType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            clauseType,
            coverableService: imBuildersRiskService
        };
        return modalApi.showModal(
            <IMSearchAndAddClausesPopup {...componentProps} />
        );
    }, [authHeader, jobID, modalApi, sessionUUID]);

    const onClickAddSearchClauses = useCallback(async (clauseType) => {
        const data = await showClausesModal(clauseType)
        setCoveragePartClauses(data)
    }, [showClausesModal])

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        setWizardDataToDraft(newSubmissionVM)
        updateWizardData(newSubmissionVM)
    }

    const calculateLimit = useCallback(async (termCode, clauseCode) => {
        if (readOnly) {
            return;
        }
        setLoadingMask(true)
        const res = await imBuildersRiskService.calculateLimit(jobID, sessionUUID, clauseCode, termCode, authHeader)
        setCoveragePartClauses(res)
        setLoadingMask(false)
    }, [authHeader, imBuildersRiskService, jobID, readOnly, sessionUUID, setLoadingMask])

    const visibleOfSupplementalLabel = (term) => {
        const mainCoverageTerms = _.get(coveragePartClauses, 'mainCoverages[0].terms');
        const coverageType = _.find(mainCoverageTerms, (item) => {
            return item.code_Ext === 'IMBuildersRiskCoverageType';
        })
        const coverageTypeChosenTerm = _.get(coverageType, 'chosenTerm');

        if (coverageTypeChosenTerm === 'ScheduledJobsiteFormComprehensiveFormIM7050'
            && term.code_Ext === 'IMBuildersRiskExpeditingExpenses'
        ) {
            return true;
        }

        if (coverageTypeChosenTerm === 'ScheduledJobsiteFormIM7051'
            && term.code_Ext === 'IMBuildersRiskExpensetoReErectScaffoldingIM7051'
        ) {
            return true;
        }

        if (coverageTypeChosenTerm === 'BuildersRiskandInstallationFloaterFormIM7053'
            && term.code_Ext === 'IMBuildersRiskExpeditingExpenses'
        ) {
            return true;
        }

        if (coverageTypeChosenTerm === 'BuildersRiskRehabilitationandRenovationIM7054'
            && term.code_Ext === 'IMBuildersRiskPollutantCleanupandRemovalIM7051'
        ) {
            return true;
        }

        return false;
    }

    const onChangeScheduleItem = (terms, updatedTerm) => {
        const newTerms = _.cloneDeep(terms);
        if (_.get(updatedTerm, 'code_Ext') === 'IMBuildersRiskScheduledJobsitesIM70507051ApplyDelayInCompletionCoveragePartIM7061'
            && _.get(updatedTerm, 'chosenTerm') === 'Yes') {
            _.each(newTerms, (term) => {
                if (term.code_Ext === 'IMBuildersRiskScheduledJobsitesIM70507051ApplyDelayinCompletionCoveragePartIncludesRentalIncomeandIncomeCoverageIM7079') {
                    _.set(term, 'chosenTerm', 'No');
                }
            })
        }

        if (_.get(updatedTerm, 'code_Ext') === 'IMBuildersRiskScheduledJobsitesIM70507051ApplyDelayinCompletionCoveragePartIncludesRentalIncomeandIncomeCoverageIM7079'
            && _.get(updatedTerm, 'chosenTerm') === 'Yes') {
            _.each(newTerms, (term) => {
                if (term.code_Ext === 'IMBuildersRiskScheduledJobsitesIM70507051ApplyDelayInCompletionCoveragePartIM7061') {
                    _.set(term, 'chosenTerm', 'No');
                }
            })
        }

        if (_.get(updatedTerm, 'code_Ext') === 'IMBuildersRiskSCHEDULEDJOBSITEIM7054ApplyDelayInCompletionCoveragePartIM7061'
            && _.get(updatedTerm, 'chosenTerm') === 'Yes') {
            _.each(newTerms, (term) => {
                if (term.code_Ext === 'IMBuildersRiskSCHEDULEDJOBSITEIM7054ApplyDelayinCompletionCoveragePartIncludesRentalIncomeandIncomeCoverageIM7079') {
                    _.set(term, 'chosenTerm', 'No');
                }
            })
        }

        if (_.get(updatedTerm, 'code_Ext') === 'IMBuildersRiskSCHEDULEDJOBSITEIM7054ApplyDelayinCompletionCoveragePartIncludesRentalIncomeandIncomeCoverageIM7079'
            && _.get(updatedTerm, 'chosenTerm') === 'Yes') {
            _.each(newTerms, (term) => {
                if (term.code_Ext === 'IMBuildersRiskSCHEDULEDJOBSITEIM7054ApplyDelayInCompletionCoveragePartIM7061') {
                    _.set(term, 'chosenTerm', 'No');
                }
            })
        }

        return newTerms;
    }

    const accordionCommonProps = {
        coveragePartClauses,
        setCoveragePartClauses: (newCoveragePart) => {
            setCoveragePartClauses(newCoveragePart)
            updateWizardDataWhileSetPeriodStatus(submissionVM)
            markFollowingWizardStepsUnvisited()
        },
        isEditing,
        setIsEditing,
        isEditable: !readOnly,
        showErrors,
        onValidate: onValidate,
        loadingClauses: isServiceCallInProgress,
        setLoadingClauses: updateServiceCallInProgress,
        onClickAddSearchClauses,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        updateCoverageFormClausesService: imBuildersRiskService.updateBuildersRiskClauses,
        calculateLimit
    }

    const overrideProps = {
        '@field': {
            isEditable: true,
        },
        coveragesAccordionCard: {
            errorState: !_.isEmpty(_.intersection(invalidFields, BUILDERSRISK_ACCORDION_CARD_COMPONENT_MAPS))
        },
        buildersRiskMainCov: {
            ...accordionCommonProps,
            mainCoverage: _.get(coveragePartClauses, 'mainCoverages[0]'),
            coverageFormPath: 'mainCoverages[0]',
            visibleOfSupplementalLabel,
            supplementalLabel: translator(messages.supplementalLabel)
        },
        buildersRiskRatingFieldsCov: {
            ...accordionCommonProps,
            mainCoverage: _.get(coveragePartClauses, 'buildersRiskCoverages[0]'),
            coverageFormPath: 'buildersRiskCoverages[0]'
        },
        coveragePartSchedule: {
            ...accordionCommonProps,
            coveragePartSchedule: _.get(coveragePartClauses, 'coveragePartSchedule'),
            updateScheduleService: imBuildersRiskService.updateCoveragePartSchedule,
            schedulePath: 'coveragePartSchedule',
            scheduleLable: translator(messages.scheduleLable),
            scheduleFormHeader: translator(messages.scheduleFormHeader),
            onChangeScheduleItem
        },
        additionalAccordionCard: {
            ...accordionCommonProps,
            coveragePartName: 'buildersRisk'
        },
        exclusionAccordionCard: {
            ...accordionCommonProps,
            coveragePartName: 'buildersRisk'
        }
    };

    const onPageNext = useCallback(async () => {
        if (readOnly) {
            return submissionVM;
        }
        setLoadingMask(true)
        const errorsAndWarnings = await imBuildersRiskService.onBuildersRiskNext(jobID, sessionUUID, authHeader)
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return submissionVM;
    }, [authHeader, imBuildersRiskService, jobID, readOnly, sessionUUID, setLoadingMask, submissionVM, validationIssues]);

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            maincoverage: IMCommonMainCoverage,
            commoncovpartschedule: IMCommonCovPartSchedule,
            commonadditionalcovcard: IMCommonAdditionalCovCard,
            commonexclusioncovcard: IMExclusionAccordionCard
        }
    };

    const disableNavigator = isEditing || isLoadingMask || isServiceCallInProgress

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            {coveragePartClauses &&
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

IMBuildersRiskPage.propTypes = {
    ...wizardProps,
    imBuildersRiskService: PropTypes.object
};

IMBuildersRiskPage.defaultProps = {
    imBuildersRiskService: IMBuildersRiskService
};
export default IMBuildersRiskPage;
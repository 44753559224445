import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import React, { useState } from 'react';
import PreCode from '../../Code/PreCode';
import ModalTest from './ModalTest';
import metadata from './WniModalTab.metadata.json5';
import { baseModalCode } from './WniModalTab.util';

function WniModalTab(props) {
    const {
        baseModalConfig: { showBaseModal },
    } = useDependencies(['baseModalConfig']);

    const [modalResult, updateModalResult] = useState();

    const openModal = () => {
        const modalProps = {
            isPopup: true,
            accountNumber: 'A0000001',
        };

        showBaseModal({
            size: 'md',
            title: 'this is wni Base modal',
            component: <ModalTest {...modalProps} />,
        }).then((res) => {
            updateModalResult(res);
        });
    };

    const overrideProps = {
        modalResult: {
            content: modalResult
        },
        baseModalCodes: {
            data: baseModalCode
        }
    };
    const resolvers = {
        resolveComponentMap: {
            preCode: PreCode,
        },
        resolveCallbackMap: {
            openModal,
        },
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}

export default WniModalTab;

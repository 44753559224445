import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { GLQualificationService } from 'wni-capability-quoteandbind-gl';
import {
    QuoteUtil,
    ValidationIssueUtil,
    WindowUtil
} from 'wni-portals-util-js';
import WizardPage from '../../templates/GLWizardPage';

import metadata from './GLQualificationPage.metadata.json5';
import messages from './GLQualificationPage.messages';

const lobDataPath = 'lobData.generalLiability';
function GLQualificationPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        glQualificationService,
        turnOnWizardPage,
        turnOffWizardPage
    } = props;

    // const ViewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const isUnmannedAircraftAvailable = _.get(submissionVM.value, `${lobDataPath}.isUnmannedAircraftAvailable`);
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('GLQualificationPage');

    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);

    const {
        jobID,
        sessionUUID
    } = submissionVM.value;

    const questionsetAnswers = _.get(submissionVM, `${lobDataPath}.preQualQuestionSets.value[0].answers`);

    const displayQuestions = [
        'GL7DoOrHave',
        'GL7Operations',
        'GL7Products',
        'GL7Subcontractors'
    ];

    useEffect(() => {
        if(isUnmannedAircraftAvailable){
            turnOnWizardPage('GLUnmannedAircraftPage')
        } else {
            turnOffWizardPage('GLUnmannedAircraftPage')
        }
    }, [isUnmannedAircraftAvailable]);


    const IsValidField = (value) => {
        if (value === undefined || value === null || value === '') {
            return false;
        }
        return true;
    }

    const IsValidFields = useCallback(() => {
        const invalidFields = _.filter(displayQuestions, (code) => {
            return IsValidField(questionsetAnswers[code]) === false
        })
        if (invalidFields.length <= 0) {
            return true;
        }
        return false;
    }, []);

    useEffect(() => {
        registerComponentValidation(IsValidFields);
    }, [registerComponentValidation]);

    const handleValidation = () => {
        updateShowErrors(true);
        return false;
    };

    const filterQuestionSets = useCallback((metadataContent) => {
        return _.indexOf(displayQuestions, metadataContent.id) >= 0;
    }, []);

    const getQuestionMapper = useCallback(() => {
        return (metaDataContent) => {
            _.set(metaDataContent, 'componentProps.showRequired', true);
            _.set(metaDataContent, 'componentProps.required', true);
            if (!IsValidField(_.get(questionsetAnswers, metaDataContent.componentProps.path))) {
                _.set(metaDataContent, 'componentProps.validationMessages', [translator(messages.GLValidationMessages)]);
            } else {
                _.set(metaDataContent, 'componentProps.validationMessages', []);
            }
            return metaDataContent;
        }
    }, [questionsetAnswers]);

    const getValidationIssues = useCallback((issues) => {
        const GLUnfavorablyAnswerWarning = [{
            type: 'warning',
            reason: translator(messages.GLUnfavorablyAnswerWarning)
        }]
        if (!_.isEmpty(questionsetAnswers)) {
            if (
                questionsetAnswers.GL7Subcontractors === 'true' || questionsetAnswers.GL7Subcontractors === true
                || questionsetAnswers.GL7DoOrHave === 'true' || questionsetAnswers.GL7DoOrHave === true
                || questionsetAnswers.GL7Operations === 'true' || questionsetAnswers.GL7Operations === true
                || questionsetAnswers.GL7Products === 'true' || questionsetAnswers.GL7Products === true
            ) {
                return _.concat(issues, GLUnfavorablyAnswerWarning);
            }
        }
        return issues;
    }, []);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
            },
            questionSetsContainer: {
                contentFilter: filterQuestionSets,
                contentMapper: getQuestionMapper()
            }
        };
    }, []);

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        const requestData = {
            jobID,
            sessionUUID,
            dto: _.get(submissionVM, 'lobData.generalLiability.preQualQuestionSets.value')
        };
        const res = await glQualificationService.updateQualification(requestData, authHeader);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        if (!_.isEmpty(res)) {
            _.set(newSubmissionVM, 'lobData.generalLiability.preQualQuestionSets.value', res.preQualQuestionSets);
        }
        setLoadingMask(false);
        updateWizardData(newSubmissionVM);

        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        const resValidationIssues = getValidationIssues(newValidationIssues);
        updateValidationIssues(resValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(resValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(resValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return newSubmissionVM;
    }, [displayWarnings, getValidationIssues, glQualificationService, submissionVM, updateWizardData]);

    const writeValue = useCallback((value, path) => {
        const newSubmissionVM = _.cloneDeep(submissionVM);
        _.set(newSubmissionVM, path, value);
        updateWizardData(newSubmissionVM);
    }, [submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={writeValue}
                showErrors={showErrors}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

GLQualificationPage.propTypes = WizardPage.propTypes;
GLQualificationPage.defaultProps = WizardPage.defaultProps;

GLQualificationPage.propTypes = {
    ...wizardProps,
    glQualificationService: PropTypes.object
};

GLQualificationPage.defaultProps = {
    glQualificationService: GLQualificationService
};
export default GLQualificationPage;
export default {
    homePage: () => '/',
    accountsHomePage: () => '/',
    accountSummary: (accountNumber) => `/pe-redirect?redirectPath=policies&accountNumber=${accountNumber}`,
    policySummary: (accountNumber, policyNumber) => `/pe-redirect?redirectPath=policies&accountNumber=${accountNumber}&policyId=${policyNumber}`,
    accountBilling: (accountNumber) => `/pe-redirect?redirectPath=billing&accountNumber=${accountNumber}`,
    accountSearch: (accountName) => `/agents?clientName=${accountName}`,
    newQuote: () => '/new-quote',
    formPersonal: () => '/forms/personal',
    formCommercial: () => '/forms/commercial',
    profileSettings: () => '/profile',
    preferenceSettings: () => '/settings',
    FAQSettings: () => '/help',
    logout: () => '/login',
    claimDetails: (accountNumber, claimNumber) => `/clients/PC$${accountNumber}/claims/${claimNumber}`,
};

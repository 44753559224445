import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { InfoLabel, Icon, InlineNotification } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WniDateUtil, WniProductsUtil } from 'wni-portals-util-js';
import {  useProductsData } from 'wni-portals-util-react';
import { Link as LinkComponent } from 'gw-components-platform-react';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import styles from './FormattedHeaderComponent.module.scss';
import messages from './FormattedHeaderComponent.messages';
import gatewayMessages from '../../gateway.messages';

function FormattedHeaderComponent({ urlParams, message, displayName }) {
    const translator = useTranslator();
    const { interactionModel } = useDependencies('interactionModel');
    const url = interactionModel.getURLObj(null, ...urlParams);
    return (
        <FormattedMessage
            {...message}
            values={{
                link: (
                    <LinkComponent {...url} className={styles.gwHeaderLink}>
                        {translator(displayName)}
                    </LinkComponent>
                )
            }}
        />
    );
}
FormattedHeaderComponent.propTypes = {
    // redirectPath: PropTypes.string.isRequired,
    urlParams: PropTypes.arrayOf(PropTypes.string).isRequired,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }).isRequired,
    displayName: PropTypes.string.isRequired
};
function PageHeaderComponent(props) {
    const translator = useTranslator();
    const [isSideHeader, setSideHeader] = useState(false);
    const [renderContent, updateRenderContent] = useState({});
    const [isPolicyAssigned, setPolicyAssigned] = useState(true);
    const submissionVM = props;
    const { interactionModel } = useDependencies('interactionModel');
    const getDisplayType = useCallback(() => {
        const type = _.get(submissionVM.value, 'type');
        if (type === 'Submission') {
            return translator(messages.quote);
        }
        return translator(messages[_.camelCase(type)]) || type;
    }, [submissionVM.value, translator]);

    const getJobStatus = useCallback(() => {
        const type = _.get(submissionVM.value, 'type');
        if (type === 'Submission') {
            if (submissionVM.value.policy) {
                // if (submissionVM.value.policy.issued) {
                //     return translator(gatewayMessages.issued);
                // }
                if (
                    submissionVM.value.status
                    === translator({
                        id: 'typekey.PolicyPeriodStatus.NotTaken',
                        defaultMessage: 'NotTaken'
                    })
                ) {
                    return translator(gatewayMessages.notTaken);
                }
            }
        }
        const jobStatus = _.get(submissionVM.value, 'status') === 'Withdrawn' ? 'Deleted' : _.get(submissionVM.value, 'status');
        return jobStatus;
    }, [submissionVM.value, translator]);

    const getStatusText = useCallback(() => {
        const displayType = _.get(submissionVM.value, 'displayType');
        if (displayType === 'Submission') {
            return translator(messages.quote);
        }
        return _.get(submissionVM.value, 'status');
    }, [submissionVM.value, translator]);

    useEffect(() => {
        if (!_.isEmpty(submissionVM.value)) {
            if (submissionVM.value.displayType) {
                setSideHeader(true);
            }
            setPolicyAssigned(_.get(submissionVM.value, 'policyNumberAssigned_Ext', false));
            const accountNumber = _.get(submissionVM.value, 'policy.account.accountNumber');
            const policyNumber = _.get(submissionVM.value, 'policyNumber_Ext');
            // const accountType = _.get(submissionVM.value, 'policy.account.accountHolder.contactType_Ext')
            const productName = WniProductsUtil.getProductNameByLinePattern(_.get(submissionVM.value, 'product_Ext'));
            const selectedVersionPolicyType = _.get(submissionVM.value, 'selectedVersionPolicyType_Ext');
            const displaySelectedVersion = !(_.get(submissionVM.value, 'displayType') === 'Submission' && ![
                'finishquote',
                'submitquote'
            ].includes(_.get(submissionVM.value, 'quoteFlow_Ext'))) && _.get(submissionVM, 'value.productCode') !== 'DwellingProperty';
            updateRenderContent({
                policyNumber,
                accountNumber,
                accountName: _.get(submissionVM.value, 'policy.account.accountHolder.displayName'),
                // accountLinkObj: interactionModel.getURLObj(accountType, 'accountSummary', accountNumber),
                policyName: isPolicyAssigned
                    ? `${productName} (${policyNumber})`
                    : productName,
                // policyLinkObj: interactionModel.getURLObj(accountType, 'policySummary', accountNumber, policyNumber),
                typeWithJobNumber: `${getDisplayType()} (${_.get(
                    submissionVM.value,
                    'jobNumber'
                )})`,
                infoText: getJobStatus(),
                selectedVersionPolicyType: selectedVersionPolicyType,
                displaySelectedVersion,
                infoType: ['New', 'Draft', 'Quoted', 'Bound', 'LegacyConversion'].includes(
                    submissionVM.value.statusCode
                )
                    ? 'info'
                    : 'warning'
            });
        }
    }, [getDisplayType, getJobStatus, getStatusText, isPolicyAssigned, submissionVM, translator, interactionModel]);

    const titleContainerClass = cx({
        [styles.gwPageTitle]: !isSideHeader,
        [styles.gwSidePageTitle]: isSideHeader
    });

    const categoryContainerClass = cx({
        [styles.gwPageCategory]: !isSideHeader,
        [styles.gwSidePageCategory]: isSideHeader
    });

    const associatedPageClass = cx({
        [styles.gwPageAssociated]: !isSideHeader,
        [styles.gwSidePageAssociated]: isSideHeader
    });

    if (_.isEmpty(renderContent) || _.isEmpty(submissionVM.value)) {
        return null;
    }

    const genAccountLinks = () => {
        return (
            <LinkComponent className={associatedPageClass} to={`/accounts/${renderContent.accountNumber}/summary`}>
                {renderContent.accountName}
            </LinkComponent>
        );
    };

    const genPolicyLinks = () => {
        return (
            <LinkComponent className={associatedPageClass} to={`/policies/${renderContent.policyNumber}/summary`} {...renderContent.policyLinkObj}>
                {renderContent.policyName}
            </LinkComponent>
        );
    };

    return (
        <>
            <div className={titleContainerClass}>
                <div className={categoryContainerClass}>
                    {translator(messages.accountTitle)}
                    {genAccountLinks()}
                </div>
                {isSideHeader && (
                    <>
                        <div className={styles.gwSidePageTitleSeparator} />
                        {isPolicyAssigned && (
                            <div className={categoryContainerClass}>
                                {translator(messages.policyNavigation)}
                                {genPolicyLinks()}
                            </div>
                        )}
                        {!isPolicyAssigned && (
                            <div className={categoryContainerClass}>
                                <span>{renderContent.policyName}</span>
                            </div>
                        )}
                        {renderContent.displaySelectedVersion && (
                            <div className={categoryContainerClass}>
                                <span className={styles.policyType}>
                                    {renderContent.selectedVersionPolicyType}
                                </span>
                            </div>
                        )}
                    </>
                )}
            </div>
            <Flex justifyContent="left" gap="small" alignItems="center" className={styles.gwTypeWithLOB}>
                <Icon icon="gw-policy-transactions" className={styles.syncIcon} />
                <h1 className={styles.gwPageTitle}>{renderContent.typeWithJobNumber}</h1>
                <div>
                    <InfoLabel
                        id="infoStatus"
                        size="medium"
                        className={styles.gwInfoStatus}
                        type={renderContent.infoType}
                    >
                        {renderContent.infoText}
                    </InfoLabel>
                </div>
            </Flex>
        </>
    );
}
PageHeaderComponent.propTypes = {
    value: PropTypes.shape({}).isRequired
};
export { FormattedHeaderComponent, PageHeaderComponent };

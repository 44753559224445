import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import React from 'react';
import metadata from './BackLinkComponent.metadata.json5';

const BackLinkComponent = (props) => {
    const {
        accountNumber,
        accountType,
        history,
        showBackToAccountBillingAndPaymentLink,
        justifyContent,
    } = props;

    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {},
    };
    const overrideProps = {
        BackToContainer: {
            justifyContent: justifyContent,
        },
        backToAccountBillingAndPayment: {
            visible: showBackToAccountBillingAndPaymentLink,
            to: `/accounts/${accountNumber}/billingAndPayment`,
        },
        BackToAccountSummary: {
            to: `/accounts/${accountNumber}/summary`,
        },
        BackToAllQuotes: {
            to: {
                pathname: '/policies',
                state: {
                    selectedTile: 'quotes',
                    initStatus: 'Quotes',
                },
            },
        },
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
};

BackLinkComponent.propTypes = {
    accountNumber: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    showBackToAccountBillingAndPaymentLink: PropTypes.bool,
    justifyContent: PropTypes.string,
};

BackLinkComponent.defaultProps = {
    showBackToAccountBillingAndPaymentLink: false,
    justifyContent: 'left',
};

export default BackLinkComponent;

const custIcons = [
    "cust-businessownerspolicy",
    "cust-commercialauto",
    "cust-commercialpackage",
    "cust-commercialproperty",
    "cust-commercialumbrella",
    "cust-crime",
    "cust-dwelling",
    "cust-dwelling1",
    "cust-generalliability",
    "cust-homeowner",
    "cust-inlandmarine",
    "cust-motorcycle",
    "cust-personalauto",
    "cust-personalumbrella",
    "cust-recvehicle",
    "cust-roadtrail",
    "cust-watercraft",
    "cust-workerscompensation",

    "cust-arrow-upward",
    "cust-assignment-late",
    "cust-check-box",
    "cust-double-arrow-left",
    "cust-history",
    "cust-money",
    "cust-percent",
    "cust-pie-chart",
    "cust-publish",
    "cust-remove-circle-outline"
];

const ootbIcons = [
    "gw-access-time",
    "gw-accessible",
    "gw-account-balance",
    "gw-account-box",
    "gw-account-circle",
    "gw-account-tree",
    "gw-add-a-photo",
    "gw-add-circle-outline",
    "gw-add-circle",
    "gw-add",
    "gw-additional-interests",
    "gw-alarm",
    "gw-apps",
    "gw-arrow-down-circle",
    "gw-arrow-drop-down",
    "gw-arrow-drop-up",
    "gw-arrow-forward",
    "gw-assignment",
    "gw-attach-file",
    "gw-bathtub",
    "gw-block",
    "gw-bookmark-border",
    "gw-bookmark",
    "gw-boundary",
    "gw-brightness1",
    "gw-build",
    "gw-business-center",
    "gw-business",
    "gw-calendar-today",
    "gw-call-split",
    "gw-cancel",
    "gw-cc-amex",
    "gw-cc-discover",
    "gw-cc-mastercard",
    "gw-cc-visa",
    "gw-chat",
    "gw-check-box-outline-blank",
    "gw-check-circle-outline",
    "gw-check-circle",
    "gw-check",
    "gw-chevron-left",
    "gw-chevron-right",
    "gw-clear",
    "gw-close",
    "gw-cloud-done",
    "gw-cloud-download",
    "gw-cloud-queue",
    "gw-cloud-upload",
    "gw-code-branch",
    "gw-code",
    "gw-coverage",
    "gw-coverages",
    "gw-credit-card",
    "gw-customer-service",
    "gw-dashboard",
    "gw-database",
    "gw-delete-outline",
    "gw-delete",
    "gw-description",
    "gw-details",
    "gw-directions-boat",
    "gw-directions-car",
    "gw-done-all",
    "gw-drag-handle",
    "gw-drag-indicator",
    "gw-driver-license",
    "gw-edit",
    "gw-edition",
    "gw-email",
    "gw-error-outline",
    "gw-error",
    "gw-expand-less",
    "gw-expand-more",
    "gw-facebook",
    "gw-file-copy",
    "gw-file-download",
    "gw-file-upload",
    "gw-filter-list",
    "gw-find-in-page",
    "gw-first-page",
    "gw-flag",
    "gw-flight-takeoff",
    "gw-folder",
    "gw-format-align-center",
    "gw-format-align-left",
    "gw-format-align-right",
    "gw-format-list-numbered",
    "gw-fullscreen-exit",
    "gw-fullscreen",
    "gw-gavel",
    "gw-grid-off",
    "gw-grid-on",
    "gw-help-outline",
    "gw-help",
    "gw-home-work",
    "gw-home",
    "gw-info-outline",
    "gw-info",
    "gw-insert-drive-file",
    "gw-insert-link",
    "gw-instagram",
    "gw-keyboard-arrow-down",
    "gw-keyboard-arrow-left",
    "gw-keyboard-arrow-right",
    "gw-keyboard-arrow-up",
    "gw-keyboard-backspace",
    "gw-last-page",
    "gw-link-off",
    "gw-link",
    "gw-list",
    "gw-local-shipping",
    "gw-location",
    "gw-lock-open",
    "gw-lock",
    "gw-mail-outline",
    "gw-mail",
    "gw-menu",
    "gw-message",
    "gw-more-horizontal",
    "gw-more-vertical",
    "gw-movie",
    "gw-not-interested",
    "gw-note",
    "gw-notes",
    "gw-notifications-active",
    "gw-notifications-none",
    "gw-notifications",
    "gw-open-in-new",
    "gw-outlined-flag",
    "gw-pageview",
    "gw-people",
    "gw-perm-contact-calendar",
    "gw-person-add",
    "gw-person",
    "gw-phone",
    "gw-photo-camera",
    "gw-photo",
    "gw-place",
    "gw-policy-transactions",
    "gw-policy",
    "gw-print",
    "gw-quote",
    "gw-receipt",
    "gw-redo",
    "gw-refresh",
    "gw-relationship",
    "gw-remove",
    "gw-renewals",
    "gw-reorder",
    "gw-reverted",
    "gw-review",
    "gw-search",
    "gw-settings",
    "gw-share",
    "gw-sort",
    "gw-spinner",
    "gw-star-border",
    "gw-star",
    "gw-store",
    "gw-submission",
    "gw-subtopic",
    "gw-summary",
    "gw-sync-success",
    "gw-sync",
    "gw-timeline",
    "gw-tonality",
    "gw-topic",
    "gw-translate",
    "gw-tune",
    "gw-twitter",
    "gw-unarchive",
    "gw-undo",
    "gw-unfold-less",
    "gw-unfold-more",
    "gw-update",
    "gw-user-info",
    "gw-view-column",
    "gw-view-details",
    "gw-view-list",
    "gw-view-module",
    "gw-visibility-off",
    "gw-visibility",
    "gw-warning",
    "gw-youtube"
];

export {
    custIcons,
    ootbIcons
}
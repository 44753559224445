import { Tab } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import React, { useState } from 'react';
import PreCode from '../../Code/PreCode';
import metadata from './WniFieldsTab.metadata.json5';
import { 
    inputTextCode,
    dropdownCode,
    inputNumberCode,
    phoneNumberCode,
    dateCode,
    currencyCode,
    textAreaCode,
    toggleCode
 } from './WnifieldsTab.util';

function WniFieldsTab(props) {

    const [dropdownValue1, updateDropdownValue1] = useState();
    const [dropdownValue2, updateDropdownValue2] = useState();
    const [dropdownValue3, updateDropdownValue3] = useState([]);
    const [dropdownValue4, updateDropdownValue4] = useState([]);
    
    const overrideProps = {
        inputTextCodes: {
            data: inputTextCode
        },
        dropdownCodes: {
            data: dropdownCode
        },
        dropdowns_1: {
            value: dropdownValue1,
            onValueChange: (val) => updateDropdownValue1(val)
        },
        dropdowns_2: {
            value: dropdownValue2,
            onValueChange: (val) => updateDropdownValue2(val)
        },
        dropdowns_3: {
            value: dropdownValue3,
            onValueChange: (val) => updateDropdownValue3(val)
        },
        dropdowns_4: {
            value: dropdownValue4,
            onValueChange: (val) => updateDropdownValue4(val)
        },
        inputNumberCodes: {
            data: inputNumberCode
        },
        phoneNumberCodes: {
            data: phoneNumberCode
        },
        currencyCodes: {
            data: currencyCode
        },
        dateCodes: {
            data: dateCode
        },
        textAreaCodes: {
            data: textAreaCode
        },
        toggleCodes: {
            data: toggleCode
        }
        
    };
    const resolvers = {
        resolveComponentMap: {
            preCode: PreCode
        },
        resolveCallbackMap: {
         
        }
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}

export default WniFieldsTab;

import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { newLocation, DRIVER_PATH, DRIVER_MAP_PATH, PASSENGER_PATH, PASSENGER_MAP_PATH } from '../../config/vehicles.static';
import Contact from '../Contact/Contact';
import InjuryContact from '../InjuryContact/InjuryContact';
import metadata from './VehicleDetails.metadata.json5';
import messages from '../PolicyVehicle/PolicyVehicle.messages';

function VehicleDetails(props) {
    const {
        currentRow = {},
        // onValidate = _.noop,
        writeValue: onValueChange = _.noop,
        save = _.noop,
        cancel = _.noop,
        VehicleService,
        claimNumber,
        authHeader,
        availableDrivers,
        availablePassengers,
        handleContactSelectionChange,
        incidentType,
        updateCurrentRow,
        selectedDriverId
    } = props;

    const isPolicyVehicle = currentRow.value.vehicle?.isPolicyVehicle

    const {
        driverMap = [],
        passengerMap,
        locationMap,
        locationID,
        publicID,
        driver,
        passengers,
        vehicleParked
    } = currentRow.value;

    const {
        isComponentValid,
        onValidate,
        registerComponentValidation
    } = useValidation('vehicleIncidentDetails');

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phoneWide' || breakpoint === 'phone';

    const [driverSelection, updateDriverSelection] = useState([]);
    const [passengerSelection, updatePassengerSelection] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);
    const [anyoneInjured, updateAnyoneInjured] = useState(false);

    useEffect(() => {
        const initDriver = driver ? [driver.rowIdPath || driver.publicID] : [];
        const initPassengers = passengers ? passengers.map((item) => item.rowIdPath || item.publicID) : [];
        const hasInjuryPassenger = initPassengers.length > 0 && _.findIndex(passengers, ['isInjury', true]) !== -1
        updateDriverSelection(initDriver);
        updatePassengerSelection(initPassengers);
        updateAnyoneInjured(hasInjuryPassenger || !!driver?.isInjury);
        const currentDriverMap = _.get(currentRow.value, 'driverMap', []);
        const filteredDriverMap = _.filter(currentDriverMap, elt => elt.roles !== "Owner");
        _.set(currentRow.value, 'driverMap', filteredDriverMap);
    }, []);

    const getLocationOptions = () => {
        const generateOptions = locationMap.map((location) => {
            return {
                ...location,
                code: location.publicID,
                name: location.displayName,
            };
        });
        generateOptions.push(newLocation);
        return generateOptions;
    };

    const onSelectionChange = (ids, path) => {
        switch(path){
            case DRIVER_PATH:
                updateDriverSelection(ids);
                // const selectedDriver = driverOptions.find((item) => item.rowIdPath === ids[0]);
                // onValueChange(selectedDriver, DRIVER_PATH);
                break;
            case PASSENGER_PATH:
                updatePassengerSelection(ids);
                // const selectedPassengers = passengerOptions.filter((item) => ids.includes(item.rowIdPath));
                // onValueChange(selectedPassengers, PASSENGER_PATH)
                break;
            default:
                break;
        }        
    };

    const onAnyoneInjuredChange = useCallback((hasInjured) => {
        updateAnyoneInjured(hasInjured)
        if(!hasInjured){
            // When select no injured clear injury selection
            handleContactSelectionChange([], PASSENGER_PATH, currentRow, updatePassengerSelection)
        }
    },[currentRow, handleContactSelectionChange])

    const writeValue = async (value, path) => {
        await onValueChange(value, path);
        if (!value) {
            return false;
        }
        switch (path) {
            case 'locationID':
                let addressValue = {};
                if (value === 'new') {
                    addressValue = {
                        displayName: newLocation.name,
                        country: newLocation.country,
                    };
                } else {
                    addressValue = locationMap.find(
                        (option) => option.publicID === value
                    );
                }
                onValueChange(addressValue, 'location');
                break;
            default:
                break;
        }
    };

    const handleValidation = () => {
        updateShowErrors(true);
        return false;
    };

    const onSave = () => {
        
        const initCurrentRow = viewModelService.clone(currentRow);
        if(!_.isEmpty(driverSelection)) {
            let driverContacts = _.uniqBy(_.concat(driverMap, availableDrivers), 'rowIdPath');
            driverContacts = _.filter(driverContacts, (contact) => !_.isEmpty(contact));
            const selectedDriver = driverContacts.find((item) => item.rowIdPath === driverSelection[0]);
            _.set(initCurrentRow.value, DRIVER_PATH, selectedDriver);
        } else {
            //  no driver selected
            _.unset(initCurrentRow.value, DRIVER_PATH)
        }
        if(!_.isEmpty(passengerSelection)) {
            let passengerContacts = _.uniqBy(_.concat(passengerMap, availablePassengers), 'rowIdPath');
            passengerContacts = _.filter(passengerContacts, (contact) => !_.isEmpty(contact));
            const selectedPassengers = passengerContacts.filter((item) => passengerSelection.includes(item.rowIdPath));
            _.set(initCurrentRow.value, PASSENGER_PATH, selectedPassengers);
        } else {
            // no passenger selected
            _.unset(initCurrentRow.value, PASSENGER_PATH)
        }

        /**
         * POI-58031
         * Ignore phone number validation because PC data can have invalid number
         * Ignore primary address validation because PC data can have no city or state
         */
        if(!initCurrentRow.aspects.subtreeValid){
            const ignoreList = ['Number', 'city', 'state']
            const {invalidChildProperties} = initCurrentRow.aspects
            const filterInvalidPhoneNumber = _.filter(invalidChildProperties, (property) => {
                return ignoreList.findIndex((keyword) => property.includes(keyword)) === -1
            })
            if(filterInvalidPhoneNumber.length > 0){
                handleValidation();
                return false
            }
        }
        if(!isComponentValid || !initCurrentRow.aspects.valid) {
            handleValidation();
            return false;
        }
        save(initCurrentRow.value)
    };

    const getDriverTableData = useCallback(() => {
        const filteredDriverMap = _.filter(driverMap, elt => elt.roles !== "Owner");
        let driverTableData = _.uniqBy(_.concat(filteredDriverMap, availableDrivers, [driver]), 'rowIdPath');
        driverTableData = _.filter(driverTableData, (contact) => !_.isEmpty(contact) && !_.includes(passengerSelection, _.get(contact, 'rowIdPath')));
        return driverTableData
    }, [availableDrivers, driver, driverMap, passengerSelection]);

    const getPassengerTableData = useCallback(() => {
        let passengerTableData = _.uniqBy(_.concat(passengerMap, availablePassengers, passengers), 'rowIdPath');
        passengerTableData = _.filter(passengerTableData, (contact) => !_.isEmpty(contact) && !_.includes(driverSelection, _.get(contact, 'rowIdPath')));
        return passengerTableData
    }, [availablePassengers, driverSelection, passengerMap, passengers]);
    
    const overrides = {
        '@field': {
            labelPosition: 'left',
            showRequired: true
        },
        '@element': {
            model: currentRow,
            claimNumber,
            authHeader,
            onSelectionChange,
            writeValue: onValueChange,
            VehicleService,
            updateCurrentRow
        },
        vinNumberField: {
            readOnly: isPolicyVehicle
        },
        makeField: {
            readOnly: isPolicyVehicle,
        },
        modelField: {
            readOnly: isPolicyVehicle,
        },
        yearField: {
            readOnly: isPolicyVehicle,
        },
        driverContainer: {
            basePath: DRIVER_PATH,
            contactRole: 'driver',
            contactTitle: 'driver',
            dataPath: DRIVER_MAP_PATH,
            tableData: getDriverTableData(),
            selection: driverSelection,
            visible: vehicleParked === false,
            showPerson: true,
            incidentType,
            onSelectionChange: (ids) => handleContactSelectionChange(ids, DRIVER_PATH, currentRow, updateDriverSelection)
        },
        injuredQuestionToggle: {
            value: anyoneInjured,
            onValueChange: onAnyoneInjuredChange
        },
        passengerContainer: {
            visible: anyoneInjured,
            basePath: PASSENGER_PATH,
            contactRole: 'passenger',
            dataPath: PASSENGER_MAP_PATH,
            tableData: getPassengerTableData(),
            selection: passengerSelection,
            showPerson: true,
            incidentType,
            onSelectionChange: (ids) => handleContactSelectionChange(ids, PASSENGER_PATH, currentRow, updatePassengerSelection),
            selectedDriverId
        },
        vehicleLocationContainer: {
            visible: false
        },
        vehicleLocation: {
            availableValues: getLocationOptions(),
            value: locationID,
            defaultValue: locationMap[0].publicID
        },
        addressFields: {
            model: currentRow,
            basePath: 'location',
            onValueChange: writeValue,
            onValidate,
            disabled: locationID && locationID !== 'new',
            showErrors,
            labelPosition: isPhone ? 'top' : 'left'
        }
    };
    const resolvers = {
        resolveComponentMap: {
            contact: Contact,
            InjuryContact
        },
        resolveCallbackMap: {
            cancel,
            save: onSave
        },
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            currentRow,
            id,
            path,
            overrides
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrides}
            onValueChange={writeValue}
            resolveValue={readValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default VehicleDetails;

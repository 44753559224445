import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Loader
} from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './IMBuildingDetailModal.metadata.json5';

function IMBuildingDetailModal(props) {
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        buildingVM,
        viewModelService,
        useDependenciesData,
        IMBuildingsAndLocationsService,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader
        },
        isReadOnly
    } = props;

    const [getBuildingVM, updateBuildingVM] = useState(buildingVM);
    const {
        onValidate, 
        isComponentValid,
        registerComponentValidation
    } = useValidation('IMBuildingDetailModal');
    const {
        loadingMask: { setLoadingMask },
    } = useDependenciesData;
    const [isLoading, setIsLoading] = useState(false);

    const {
        isConstructionTypeRequired_Ext: isConstructionTypeRequired,
        constructionType_Ext: constructionType
    } = getBuildingVM.value;

    const prefillFields = async () => {
        if(!isReadOnly) {
            setIsLoading(true);
            const res = await IMBuildingsAndLocationsService.getPrefillBuildingFields(jobID, sessionUUID, buildingVM.value, authHeader)
            if(!_.isNil(res)) {
                const newBuildingVM = viewModelService.clone(getBuildingVM);
                _.set(newBuildingVM, 'value', res);
                updateBuildingVM(newBuildingVM);
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await prefillFields();
        })()
    }, []);

    const handleSave = async () => {
        setLoadingMask(true);
        const res = await IMBuildingsAndLocationsService.updateBuilding(jobID, sessionUUID, getBuildingVM.value, authHeader);
        setLoadingMask(false);
        onResolve(res);
    }

    const isPopupValid = useCallback(() => {
        if(isConstructionTypeRequired){
            return !!constructionType
        }
        return true
    }, [isConstructionTypeRequired, constructionType]);

    useEffect(() => {
        registerComponentValidation(isPopupValid);
    }, [registerComponentValidation, isPopupValid]);

    const onValueChange = (value, path) => {
        const newBuildingVM = viewModelService.clone(getBuildingVM);
        _.set(newBuildingVM, path, value);
        updateBuildingVM(newBuildingVM);
    }

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
            readOnly: isReadOnly
        },
        location: {
            readOnly: true
        },
        buildingNumber: {
            readOnly: true
        },
        constructionType:{
            required: isConstructionTypeRequired
        }

    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="imBuildingDetailModal">
                { isLoading ? <Loader/> : <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={getBuildingVM}
                    onValueChange={onValueChange}
                    onValidationChange={onValidate}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />}
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} variant="secondary" label={cancelBtnLabel} />
                {!isReadOnly && <Button onClick={handleSave} disabled={!isComponentValid} label={actionBtnLabel} />}
            </ModalFooter>
        </ModalNext>
    );
}

IMBuildingDetailModal.propTypes = {
   
};
IMBuildingDetailModal.defaultProps = {
    size: 'md'
};
export default IMBuildingDetailModal;



const checkboxGroupCode = {
    jsx: `
    import { 
        WniCheckboxGroup
    } from 'wni-common-base-components';

    const [checkboxValue1, updateCheckboxValue1] = useState([]);

    <WniCheckboxGroup
        value={checkboxValue1}
        availableValues={[
            {
                "name": "option 1",
                "code": "option1",
                "isDisabled": true
            },
            {
                "name": "option 2",
                "code": "option2"
            },
            {
                "name": "option 3",
                "code": "option3"
            }
        ]}
        onValueChange={updateCheckboxValue1}
        onValidate={_.noop}
    />
    `,
    metadata: `
    {
        "id": "wniCheckboxGroup",
        "type": "field",
        "component": "WniCheckboxGroup",
        "componentProps": {
            "value": "{checkboxValue1}",
            "availableValues": [
                {
                    "name": "option 1",
                    "code": "option1",
                    "isDisabled": true
                },
                {
                    "name": "option 2",
                    "code": "option2"
                },
                {
                    "name": "option 3",
                    "code": "option3"
                }
            ],
            "onValueChange: "{updateCheckboxValue1}",
            "onValidate: "_.noop",
        }
    }
    `
}

const imageCheckboxFieldCode = {
    jsx: `
    import { WniImageCheckboxField } from 'wni-common-base-components';

    const [checkboxValue2, updateCheckboxValue2] = useState(false);

    <WniImageCheckboxField
        value={checkboxValue2}
        onValueChange={updateCheckboxValue2}
        onValidate={_.noop}
        icon="cust-personalauto",
        code="PersonalAuto",
        name="Personal Auto",
        tooltip="this is tooltip for Personal Auto"
    />

    <WniImageCheckboxField
        icon="cust-personalauto",
        code="PersonalAuto",
        name="Personal Auto",
        tooltip="this is tooltip for Personal Auto"
        disabled={true}
    />
    `,
    metadata: `
    {
        "id": "imageCheckboxField",
        "type": "element",
        "component": "WniImageCheckboxField",
        "componentProps": {
            "value": "{checkboxValue2}",
            "onValueChange": "{updateCheckboxValue2}",
            "icon": "cust-personalauto",
            "code": "PersonalAuto",
            "name": "Personal Auto",
            "tooltip": "this is tooltip for Personal Auto"
        }
    },

    {
        "id": "imageCheckboxField1",
        "type": "element",
        "component": "WniImageCheckboxField",
        "componentProps": {
            "icon": "cust-personalauto",
            "code": "PersonalAuto",
            "name": "Personal Auto",
            "tooltip": "this is tooltip for Personal Auto",
            "disabled": true
        }
    }
    `
}

const imageCheckboxGroupCode = {
    jsx: `
    import { WniImageCheckboxGroup } from 'wni-common-base-components';

    const [checkboxValue3, updateCheckboxValue3] = useState([]);

    <WniImageCheckboxGroup
        value={checkboxValue3}
        onValueChange={(val) => updateCheckboxValue3(val)}
        onValidate={_.noop}
        availableValues= [
            {
                "code": "CA7CommAuto",
                "name": "Commercial Auto",
                "icon": "cust-commercialauto",
                "tooltip": "Select here to quote Commercial Auto"
            },
            {
                "code": "WCMWorkersComp",
                "name": "Workers Compensation",
                "icon": "cust-workerscompensation",
                "tooltip": "Select here to quote Workers Compensation",
                "disabled": true
            },
            {
                "code": "GL7GeneralLiability",
                "name": "General Liability",
                "icon": "cust-generalliability",
                "tooltip": "Select here to quote General Liability"
            },
            {
                "code": "CP7CommercialProperty",
                "name": "Commercial Property",
                "icon": "cust-commercialproperty",
                "tooltip": "Select here to quote Commercial Property"
            },
            {
                "code": "InlandMarine",
                "name": "Inland Marine",
                "icon": "cust-inlandmarine",
                "tooltip": "Select here to quote Inland Marine"
            },
            {
                "code": "CommercialPackage",
                "name": "Commercial Package",
                "icon": "cust-commercialpackage",
                "tooltip": "Select here to quote Commercial Package"
            },
            {
                "code": "CR7Crime",
                "name": "Crime",
                "icon": "cust-crime",
                "tooltip": "Select here to quote Crime"
            },
            {
                "code": "CEL",
                "name": "Commercial Umbrella",
                "icon": "cust-commercialumbrella",
                "tooltip": "Select here to quote Commercial Umbrella"
            }
        ]
    />
    
    `,
    metadata: `
    {
        "id": "imageCheckboxGroup",
        "type": "element",
        "component": "WniImageCheckboxGroup",
        "componentProps": {
            "value": "checkboxGroupValue",
            "onValueChange": "updateCheckboxGroupValue",
            "availableValues": [
                {
                    "code": "CA7CommAuto",
                    "name": "Commercial Auto",
                    "icon": "cust-commercialauto",
                    "tooltip": "Select here to quote Commercial Auto"
                },
                {
                    "code": "WCMWorkersComp",
                    "name": "Workers Compensation",
                    "icon": "cust-workerscompensation",
                    "tooltip": "Select here to quote Workers Compensation"
                },
                {
                    "code": "GL7GeneralLiability",
                    "name": "General Liability",
                    "icon": "cust-generalliability",
                    "tooltip": "Select here to quote General Liability"
                },
                {
                    "code": "CP7CommercialProperty",
                    "name": "Commercial Property",
                    "icon": "cust-commercialproperty",
                    "tooltip": "Select here to quote Commercial Property"
                },
                {
                    "code": "InlandMarine",
                    "name": "Inland Marine",
                    "icon": "cust-inlandmarine",
                    "tooltip": "Select here to quote Inland Marine"
                },
                {
                    "code": "CommercialPackage",
                    "name": "Commercial Package",
                    "icon": "cust-commercialpackage",
                    "tooltip": "Select here to quote Commercial Package"
                },
                {
                    "code": "CR7Crime",
                    "name": "Crime",
                    "icon": "cust-crime",
                    "tooltip": "Select here to quote Crime"
                },
                {
                    "code": "CEL",
                    "name": "Commercial Umbrella",
                    "icon": "cust-commercialumbrella",
                    "tooltip": "Select here to quote Commercial Umbrella"
                }
            ]
        }
    },
    `
};


export {
    checkboxGroupCode,
    imageCheckboxFieldCode,
    imageCheckboxGroupCode
}
import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
// import { BreakpointTrackerContext } from '@jutro/layout';
// import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';
import { WCPolicyDetailsService } from 'wni-capability-quoteandbind-wc';
import {
    ValidationIssueUtil,
    // ErrorsAndWarningsUtil,
    // WizardUtil,
    WindowUtil,
    QuoteUtil,
    WniDateUtil,
} from 'wni-portals-util-js';
import {
    AgencyAndProducerInfoComponent,
    PrimaryNamedInsuredInputPopup,
    PolicyDBAComponent,
    ANIComponent
} from 'wni-capability-gateway-react';
// import { ErrorsAndWarningsDisplayComponent, ValidationIssuesComponent } from 'wni-components-platform-react';
// import { PortalConstants, WizardConstants } from 'wni-portals-config-js';

// import appConfig from 'app-config';
import moment from 'moment';
import WizardPage from '../../templates/WCWizardPage';

import styles from './WCPolicyDetailsPage.module.scss';
import metadata from './WCPolicyDetailsPage.metadata.json5';
// import messages from './WCPolicyDetailsPage.messages';

function WCPolicyDetailsPage(props) {

    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        // updateWizardSnapshot,
        // updateWizardReadOnly,
        // wizardSnapshot,
        // //
        // wizardStepToFieldMapping,
        // markFollowingWizardStepsUnvisited,
        savePolicyDetailsData,
        // jumpTo: wizardJumpTo,
        // steps: wizardSteps,
        //
        // wizardPageData,
        // updateWizardPageData,
        // readOnlyFields: {
        //     // effectiveDate = false,
        //     agencyOfRecord = false,
        // },
    } = props;

    const {
        jobID: quoteID,
        sessionUUID,
        policyInfoSource_Ext: policyInfoSource,
        baseData,
        lobData: {
            workersComp: {
                policyDetails: {
                    organizationTypeOptions,
                }
            }
        },
        // bindData,
        // quoteData,
    } = submissionVM.value;

    const {
        // baseState_Ext: baseState,
        primaryNamedInsured_Ext: primaryNamedInsured,
        quoteFlow_Ext: quoteFlow,
        effectiveDate_Ext: transactionEffectiveDate,
        termType
        // productCode
    } = baseData;

    const pnidbalist = _.get(submissionVM, 'baseData.pnidbalist_Ext');
    const fein = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext.feinOfficialId_Ext');

    // const history = useHistory();
    const translator = useTranslator();
    // const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    // const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;

    const policyDetailPath = 'lobData.workersComp.policyDetails';
    const {
        businessData_Ext: {
            systemDate
        }
    } = authUserData
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
        registerComponentValidation
    } = useValidation('WCPolicyDetailsPage');

    const [showErrors, updateShowErrors] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [showPNIInfoMessage, updateShowPNIInfoMessage] = useState(false);
    const [isFEINDisable, updateIsFEINDisable] = useState(false);
    const [isSSNDisable, updateIsSSNDisable] = useState(false);
    const policyDetailsData = _.get(submissionVM, `value.${policyDetailPath}`) || {};
    const {
        ssn,
        organizationType: organizationTypes
    } = policyDetailsData

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            // When coverages Form is changed, clear residence type value
            // if (path === 'lobData.homeowners.householdInfoDTO_Ext.coveragesForm') {
            //     _.set(newSubmissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.residenceType', '')
            // }
            if (path === 'baseData.effectiveDate_Ext') {
                _.set(newSubmissionVM.value, 'baseData.periodStartDate', value)
            }
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );

    const getSSNVisible = useCallback(() => {
        return _.includes(organizationTypes, 'individual_ext')
            || _.includes(organizationTypes, 'soleproprietorship_ext');

    }, [organizationTypes]);

    const removeSSN = () => {
        const newSubmissionVM = _.clone(submissionVM);
        delete newSubmissionVM.value.lobData.workersComp.policyDetails.ssn
        updateSubmissionVM(newSubmissionVM);
    }

    useEffect(() => {
        if (getSSNVisible()) {
            if (!_.isNil(fein.value)) {
                updateIsSSNDisable(true);
            } else {
                updateIsSSNDisable(false);
            }
            if (!_.isNil(ssn)) {
                updateIsFEINDisable(true);
            } else {
                updateIsFEINDisable(false);
            }
        } else {
            updateIsSSNDisable(false);
            updateIsFEINDisable(false);
            removeSSN();
        }
    }, [organizationTypes, fein.value, ssn]);

    const getMinDate = useCallback(() => {
        // for transactions once rated, enable 5 days behind the current date
        // if (quoteFlow !== 'draft' && policyInfoSource !== 'Converted') {
        //     return moment(systemDate).add(-5, 'd').startOf('day').toDate();
        // }
        return systemDate
    }, [systemDate]);

    const handleAgencyChange = useCallback((value, path) => {
        if (path === 'producerCode_Ext' && _.isObject(value)) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
            _.set(submissionVM.baseData, 'producerCodePublicID_Ext', value.publicID);
            _.set(submissionVM.baseData, path, value.code);
        } else {
            if (_.get(submissionVM.value.baseData, path) === value) {
                // If value is not updated, then no need to call updateWizardData()
                return;
            }
            _.set(submissionVM.baseData, path, value);
        }
        updateSubmissionVM(submissionVM);
        // if (isUpdateSnapshot) {
        //     updateWizardSnapshot(submissionVM);
        // }
    }, [submissionVM, updateSubmissionVM]);

    const showPrimaryNamedInsuredModal = useCallback(
        (primaryNamedInsuredVM, policyNamedInsuredCandidatesVM) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                primaryNamedInsuredVM,
                policyNamedInsuredCandidatesVM,
                viewModelService,
                showEmployeeFlagToggle: false,
                authHeader,
                sessionUUID,
                jobID: quoteID,
                policyDetailsService: WCPolicyDetailsService,
                policyDetailPath,
                DBAsVM: pnidbalist,
                // isReadOnly,
                accountContacts: _.get(submissionVM, 'value.baseData.accountContacts_Ext'),
                onValueChange: writeValue,
                size: 'lg'
            };
            return modalApi.showModal(
                <PrimaryNamedInsuredInputPopup {...componentProps} />
            );
        }, [viewModelService, authHeader, pnidbalist]
    );

    const openEditPrimaryNamedInsuredPopup = useCallback(() => {
        const primaryNamedInsuredVM = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext');
        _.set(primaryNamedInsuredVM.value, 'isNewAccount_Ext', true)
        // _.set(primaryNamedInsuredVM.value, 'accountOrgType_Ext', 'commonownership')
        const policyNamedInsuredCandidatesVM = _.get(
            submissionVM,
            'baseData.policyNamedInsuredCandidates_Ext'
        );
        showPrimaryNamedInsuredModal(primaryNamedInsuredVM, policyNamedInsuredCandidatesVM)
            .then((updatedVM) => {
                _.set(submissionVM.value, 'baseData.primaryNamedInsured_Ext', updatedVM.value);

                const newAddress = _.get(updatedVM.value, 'primaryAddress');
                _.set(submissionVM.value, 'baseData.policyAddress', newAddress);

                // Find updated account contact
                const accountContactsVM = _.get(
                    submissionVM,
                    'baseData.accountContacts_Ext'
                );
                const accountHolder = _.get(submissionVM.value, 'baseData.accountHolder');
                const newNamedInuseredVMIndex = _.get(accountContactsVM, 'value', []).findIndex(
                    (accountContactVM) => accountContactVM.publicID === _.get(updatedVM, 'value.publicID')
                );
                _.set(submissionVM.value, `baseData.accountContacts_Ext[${newNamedInuseredVMIndex}]`, updatedVM.value);
                if (accountHolder.publicID === _.get(updatedVM, 'value.publicID')) {
                    _.set(submissionVM.value, 'baseData.accountHolder', updatedVM.value);
                }
                const newSubmissionVM = _.clone(submissionVM);
                updateSubmissionVM(newSubmissionVM);
            }).catch(() => _.noop());
    }, [showPrimaryNamedInsuredModal, submissionVM, updateSubmissionVM]);

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.contactName');
        // const firstName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.firstName');
        // const lastName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.lastName');
        return `${displayName}`;
    }, [submissionVM]);

    const getPrimaryNamedInsuredAndDisplay = useCallback(() => {
        if (_.isNil(primaryNamedInsured)) {
            const displayName = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].displayName');
            let primaryAddress = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode')
            if (_.isNil(primaryAddress)) {
                primaryAddress = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.displayName')
            }
            // primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode') 
            return (
                <div>
                    <div>{displayName}</div>
                    <div>{primaryAddress}</div>
                </div>
            );
        }
        // Not use Display Name since it is readOnly in PC
        // const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.displayName');
        let primaryAddress = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.recommendCode')
        if (_.isNil(primaryAddress)) {
            primaryAddress = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{primaryAddress}</div>
            </div>
        );
    }, [getPrimaryNamedInsuredDisplayName, primaryNamedInsured, submissionVM.value]);

    const getOrganizationTypeOptions = organizationTypeOptions.map((type) => {
        const temp = 'typekey.OrganizationType.'.concat(type)
        return {
            code: type,
            name: translator({ id: temp })
        }
    });
    const isEffectiveDateValid = useCallback(() => {
        const baseEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = getMinDate();
        const minDif = moment(baseEffectiveDate).valueOf() - moment(minDate).valueOf();

        return (minDif >= 0)
    }, [submissionVM.value, getMinDate]);

    const getTermTypeOptions = () => {
        const termTypeOptions = _.get(submissionVM, 'baseData.termType.aspects.availableValues').filter((item) => item.code !== 'HalfYear');
        return termTypeOptions ? termTypeOptions.map((option) => {
            return {
                code: option.code,
                name: {
                    id: option.name,
                },
            };
        }) : [];
    };

    const handleTermTypeChange = useCallback((newTermType, path) => {
        const effectiveDate = _.get(submissionVM, 'baseData.effectiveDate_Ext.value');
        if (newTermType !== 'Other') {
            const newPeriodEndDate = WniDateUtil.getPolicyEndDate(effectiveDate, newTermType);
            _.set(submissionVM.baseData, 'periodEndDate', newPeriodEndDate);
        } else {
            _.set(submissionVM.baseData, 'periodEndDate', '');
        }
        _.set(submissionVM.baseData, 'termType', newTermType);
        updateSubmissionVM(submissionVM);
    }, [submissionVM, updateSubmissionVM]);

    const WCPolicyDetailsValidation = useCallback(() => {
        return isEffectiveDateValid()
        // && WniPNIUtil.isPNIValid(primaryNamedInsured);
        // && isAgencyAndAgentInfoValid()
    }, [isEffectiveDateValid]);

    useEffect(() => {
        registerComponentValidation(WCPolicyDetailsValidation);
    }, [registerComponentValidation, transactionEffectiveDate]);


    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        }, [updateShowErrors, invalidFields]
    );

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        const wcPolicyDetailsData = _.get(submissionVM.value, 'lobData.workersComp.policyDetails')
        // const questionSetData = _.get(submissionVM.value, 'lobData.workersComp.preQualQuestionSets')
        const quoteBaseData = _.get(submissionVM.value, 'baseData')

        const res = await savePolicyDetailsData(
            quoteID,
            sessionUUID,
            wcPolicyDetailsData,
            // questionSetData,
            quoteBaseData,
            authHeader);
        setLoadingMask(false);
        submissionVM.value = res;
        updateSubmissionVM(submissionVM);

        // Next step: move this and the service call into HOWizardPage as a common feature
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if (hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }

        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            return false;
        }
        return submissionVM;
    }, [setLoadingMask, submissionVM, savePolicyDetailsData, quoteID, sessionUUID, authHeader, updateSubmissionVM, displayWarnings]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                showRequired: true
            },
            effectiveDate: {
                minDate: getMinDate(),
                showErrors
            },
            termType: {
                availableValues: getTermTypeOptions(),
                onValueChange: handleTermTypeChange
            },
            expirationDate: {
                readOnly: termType === 'Annual',
                minDate: LocalDateUtil.today(),
                value: _.get(submissionVM.value, 'baseData.periodEndDate'),
            },
            agencyAndProducerInfo: {
                model: submissionVM.baseData,
                onAgencyChange: handleAgencyChange,
                displayFields: {
                    agencyOfRecord: false,
                    licensedAgent: true,
                    servicingAgent: true
                },
                readOnlyFields: {
                    agencyOfRecordReadOnly: true
                },
                producerCodePath: 'producerCode_Ext',
                // shouldSetExternalAgencyVal: true,
                // shouldUpdateAgentOptions: !isSkipping,
                onValidate
            },
            primaryNamedInsured: {
                value: getPrimaryNamedInsuredAndDisplay(),
            },
            primaryNamedInsuredEditIcon: {
                onClick: !showPNIInfoMessage ? () => updateShowPNIInfoMessage(true) : openEditPrimaryNamedInsuredPopup,
            },
            organizationTypeMultiSelect: {
                availableValues: getOrganizationTypeOptions,
            },
            primaryNamedInsuredNotificationInfo: {
                visible: showPNIInfoMessage,
                // messageProps: { "info": "Updating primary insured information will sync to the account and other quotes "}
            },
            additionalInsuredSection: {
                model: _.get(submissionVM, `${policyDetailPath}.additionalInsureds`) || {},
                onValueChange: writeValue,
                viewModelService,
                industryCode: {
                    code: _.get(primaryNamedInsured, 'industryCode_Ext'),
                    classification: _.get(primaryNamedInsured, 'industryDescription_Ext')
                },
                policyDetailsService: WCPolicyDetailsService,
                authHeader,
                sessionUUID,
                jobID: quoteID,
                organizationTypesOptions: getOrganizationTypeOptions,
                organizationTypes,
                policyDetailPath,
                accountContacts: _.get(submissionVM, 'value.baseData.accountContacts_Ext'),
                policyAddress: _.get(submissionVM, 'value.baseData.policyAddress'),
                existingAddlNamedInsureds: _.get(submissionVM, `value.${policyDetailPath}.unassignedAdditionalNamedInsureds`),
                FEIN: _.get(submissionVM, 'baseData.primaryNamedInsured_Ext.feinOfficialId_Ext'),
                // isReadOnly
            },
            FEIN: {
                disabled: isFEINDisable
            },
            SSN: {
                disabled: isSSNDisable,
                visible: getSSNVisible()
            },
            policyDBA: {
                dbas: _.get(submissionVM, `value.${policyDetailPath}.policyDBAList`),
                // isReadOnly,
                authHeader,
                sessionUUID,
                jobID: quoteID,
                onValueChange: writeValue,
                policyDetailPath
            }
        }
    }, [getMinDate, getOrganizationTypeOptions, getPrimaryNamedInsuredAndDisplay, handleAgencyChange, onValidate, openEditPrimaryNamedInsuredPopup, showErrors, showPNIInfoMessage, submissionVM.baseData]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            agencyproducerinfo: AgencyAndProducerInfoComponent,
            anicomponent: ANIComponent,
            policydbacomponent: PolicyDBAComponent
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

WCPolicyDetailsPage.propTypes = {
    ...WizardPage.propTypes,
    savePolicyDetailsData: PropTypes.func,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: true,
        // uwqReadonly: false
    }
};

WCPolicyDetailsPage.defaultProps = {
    ...WizardPage.defaultProps,
    savePolicyDetailsData: WCPolicyDetailsService.saveWCPolicyDetailsData,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: true,
        // uwqReadonly: false
    }
}
export default WCPolicyDetailsPage;
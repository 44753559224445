import WniConfiguration from '../Components/WniConfiguration/WniConfiguration';
import WniFieldsTab from "../Components/WniFieldsTab/WniFieldsTab";
import WniFieldActionTab from "../Components/WniFieldActionTab/WniFieldActionTab";
import WniIconTab from '../Components/WniIconTab/WniIconTab';
import WniImageCheckboxTab from '../Components/WniImageCheckboxTab/WniImageCheckboxTab';
import WniModalTab from '../Components/WniModalTab/WniModalTab';
import WniDataTableTab from '../Components/WniDataTable/WniDataTableTab';
import WniProductDataTab from '../Components/WniProductDataTab/WniProductDataTab';

const tabMaps = [
    {
        id: "wniConfiguration",
        title: {
            id: 'wni.ui platform.tab.Portal Configuration',
            defaultMessage: 'Portal Configuration'
        },
        component: WniConfiguration
    },
    {
        id: "wniProductDataTab",
        title: {
            id: 'wni.ui platform.tab.Portal Products Data',
            defaultMessage: 'Portal Products Data'
        },
        component: WniProductDataTab
    },
    {
        id: "wniFieldTooltipTab",
        title: {
            id: 'wni.ui platform.tab.Field With Tooltip',
            defaultMessage: 'Field With Tooltip'
        },
        component: WniFieldsTab
    },
    {
        id: "wniFieldActionTab",
        title: {
            id: 'wni.ui platform.tab.Field With Action',
            defaultMessage: 'Field With Action'
        },
        component: WniFieldActionTab
    },
    {
        id: "wniDataTableTab",
        title: {
            id: 'wni.ui platform.tab.DataTable',
            defaultMessage: 'DataTable'
        },
        component: WniDataTableTab
    },
    {
        id: "wniIconTab",
        title: {
            id: 'wni.ui platform.tab.Icons',
            defaultMessage: 'Icons'
        },
        component: WniIconTab
    },
    {
        id: "wniImageCheckboxTab",
        title: {
            id: 'wni.ui platform.tab.ImageCheckbox',
            defaultMessage: 'ImageCheckbox'
        },
        component: WniImageCheckboxTab
    },
    {
        id: "wniModalTab",
        title: {
            id: 'wni.ui platform.tab.Modal',
            defaultMessage: 'Modal'
        },
        component: WniModalTab
    }
];

export default {
    tabMaps
}
import { Button } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { useWniModal } from 'wni-components-platform-react';
import _ from 'lodash';
import React, { useState } from 'react';
import SecondModalTest from './SecondModalTest';

function ModalTest(props){
    const modalApi = useWniModal();
    const {
        isPopup = false,
        accountNumber,
        onResolve = _.noop,
        onReject = _.noop,
    } = props;

    const [modalResult, updateModalResult] = useState();

    const handleConfirm = () => {
        onResolve(`${accountNumber}: this is the result return to parent`);
    };

    const openSecondModal= () => {
        const componentProps = {
            size: 'lg',
            title: "this is Second Modal",
            model: {}
        };
        modalApi.showModal(<SecondModalTest {...componentProps} />).then((res) => {
            updateModalResult(res);
        }).catch(_.noop);
    }

    return <>
        <div className='wni__ModalBody__modalBody'>
            <h4 className='ph-20' >this is Wni Base Modal</h4>
            <Button onClick={openSecondModal} label="Open Second Modal" />
            <div>{modalResult}</div>
        </div>

        <Flex className="wni__ModalFooter__modalFooter" justifyContent="right" >
            <Button onClick={onReject} variant="secondary" label="Cancel" />
            <Button onClick={handleConfirm} label="Confirm" />
        </Flex>
    </>
};

export default ModalTest;
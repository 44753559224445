import { Tab } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import React, { useEffect, useState } from 'react';
import staticData from './Config/config';
import metadata from './UIPlatform.metadata.json5';
import uiPlatformConfig from './Config/uiPlatform.config';

const {
    tabMaps
} = uiPlatformConfig;

const renderTabInfo = (data = []) => {
   
    return data.map((item) => {
        const { id, component: FieldComponent, title } = item;
        return (
            <Tab id={id} key={id} heading={title}>
                <FieldComponent />
            </Tab>
        );
    });
};

function UIPlatform(props) {
    const [activeTab, updateActiveTab] = useState('wniConfiguration');

    const [checkboxValue, updateCheckboxValue] = useState(false);
    const [checkboxGroupValue, updateCheckboxGroupValue] = useState([]);


    const onTabChange = (tab) => {
        updateActiveTab(tab);
    }

    const overrideProps = {
        tabSet: {
            activeTab,
            content: renderTabInfo(tabMaps),
            onTabChange,
        },
        tabSetDetails: {
            data: tabMaps
        },
        imageCheckboxField: {
            value: checkboxValue,
            onValueChange: (val) => updateCheckboxValue(val),
        },

        imageCheckboxGroup: {
            availableValues: staticData.ImageCheckboxGroupOptions,
            value: checkboxGroupValue,
            onValueChange: updateCheckboxGroupValue,
        },
        inputWithAction_1: {
            actionFn: () => {
                
            },
        },
        inputWithAction_2: {
            actionFn: () => {
                
            },
        },
    };
    const resolvers = {
        resolveComponentMap: {
        },
        resolveCallbackMap: {
         
        }
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}

export default UIPlatform;

import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Claim } from 'gw-capability-fnol-common-react';
import { ClaimUtil } from 'gw-capability-fnol-common-react-ext';
import { ProductUtil } from 'wni-portals-util-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import { FNOLService } from 'wni-capability-fnol';

import metadata from './FNOLGLLossTypePage.metadata.json5';
import messages from '../../FNOLGeneral.message';

const {
    CR_LOB_NAME,
    IM_LOB_NAME
} = ProductUtil;

function FNOLGLLossTypePage(props) {
    const modalApi = useModal();
    const {
        wizardData: claimVM,
        updateWizardData,
        history: {
            location: { state = {} }
        },
        authHeader
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    // const { FNOLService } = useDependencies('FNOLService');
    const [pageData] = useState(state);

    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLGLLossTypePage');

    const LOB_NAME = ClaimUtil.getLobByPolicyType(_.get(claimVM, 'value.policy.lobCode_Ext'));

    const {
        claimNumber
    } = claimVM.value

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = pageData;
        return !_.isEmpty(claimStatus);
    }, [pageData]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    const handleLossCause = useCallback((value) => {
        _.set(claimVM, 'lossCause', value);
        updateWizardData(claimVM);
    }, [claimVM, updateWizardData]);

    const overrides = {
        '@field': {
            labelPosition: 'left',
            showRequired: true
            // showOptional: true
        },
        lossCause: {
            onValueChange: handleLossCause
        },
        lossType: {
            visible: LOB_NAME !== CR_LOB_NAME && LOB_NAME !== IM_LOB_NAME
        }
    };

    const onNext = useCallback(() => {
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        if (!claimNumber) {
            setIsLoading(true);
            return FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
                .then((response) => {
                    claimVM.value = new Claim(response);
                    return claimVM;
                })
                .catch(() => {
                    modalApi.showAlert({
                        title: messages.createDraftErrorTitle,
                        message: messages.createDraftErrorMessage,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                    return false;
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        return claimVM;
    }, []);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM]
    );

    return (
        <WizardPage
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={onNext}
            template={WizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={readValue}
                overrideProps={overrides}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

FNOLGLLossTypePage.propTypes = wizardProps;
FNOLGLLossTypePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(withAuthenticationContext(FNOLGLLossTypePage));

import React, { useCallback, useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PaymentUtil, WniDateUtil, DocumentsUtil, WniProductsUtil, WniAccountsUtil } from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { BaseWizardPage as WizardPage } from 'wni-portals-wizard-react';
import { WniCLBillingSubmissionService, WniGatewayBillingSubmissionService } from 'wni-capability-gateway-billing';
import { WniAccountService } from 'wni-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniLoadSaveService } from 'wni-capability-quoteandbind';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { FieldLinkComponent } from 'gw-components-platform-react';
import { useWniModal } from 'wni-components-platform-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import {
    useHistory
} from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import AQQuotetableComponent from '../Payment/components/QuoteTableComponent/AQQuoteTableComponent';
import DocumentSectionComponent from '../Payment/components/DocumentSectionComponent/DocumentSectionComponent';
import EditBillingPopup from './components/EditBillingPopup/EditBillingPopup';
import AQWizardPageTempalte from '../../template/AQWizardPageTemplate';
import AQWizardPage from '../../template/AQWizardPage';
import styles from './AQBillingPage.module.scss';
import metadata from './AQBillingPage.metadata.json5';
import messages from './AQBillingPage.messages';
import AQPaymentTableUtil from '../Payment/utils/AQPaymentTableUtil';
import AQPaymentPageUtil from '../Payment/utils/AQPaymentPageUtil';

const {
    getProductNameByLinePattern,
    getProductName
} = WniProductsUtil
function AQBillingPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData
    } = props;
    const {
        accountNumber: ACCOUNT_NUMBER
    } = wizardData.value;
    const history = useHistory();
    const [accountNumber, updateAccountNumber] = useState(ACCOUNT_NUMBER);
    const [acccountHolderDisplayName, updateAcccountHolderDisplayName] = useState(null);
    const [submission, updateSubmission] = useState({});
    const [policyTableData, updatePolicyTableData] = useState([]);
    const [accountDocuments, updateAccountDocuments] = useState([]);
    const [initDataRetrieved, updateInitDataRetrieved] = useState(false);
    const [documentsRetrieved, updateDocumentsRetrieved] = useState(false);
    const translator = useTranslator();
    const [initialised, updateInitialised] = useState(false);
    const { authHeader } = useAuthentication();
    const {
        interactionModel,
        loadingMask: { setLoadingMask },
    } = useDependencies(['interactionModel', 'loadingMask']);
    const breakpoint = useContext(BreakpointTrackerContext);
    const [isCL, updateIsCL] = useState(false);

    const fetchAccountInfoWithJobNumber = useCallback(async () => {
        const rs = await WniLoadSaveService.fetchAccountInfoWithAccountNumber(accountNumber, authHeader);
        updateAccountNumber(_.get(rs, 'accountNumber'));
        updateAcccountHolderDisplayName(_.get(rs, 'acccountHolderDisplayName'));
        // update the accountHolder and PNI info for email default
        const tempSubmission = {};
        _.set(tempSubmission, 'policy.account.accountHolder', _.get(rs, 'accountHolder'));
        _.set(tempSubmission, 'value.baseData.primaryNamedInsured_Ext', _.get(rs, 'primaryNamedInsuredContact'));
        updateSubmission(tempSubmission);
        const isCLProduct = WniAccountsUtil.isCLAccount(_.get(rs, 'accountHolder.contactType_Ext'));
        return isCLProduct;
    }, [accountNumber, authHeader]);

    const retrieveInitData = useCallback(async (isCLProduct = false) => {
        try {
            setLoadingMask(true);
            let data;
            if (isCLProduct) {
                data = await WniCLBillingSubmissionService.getBillingPoliciesByAccountNumber([accountNumber], authHeader)
            } else {
                data = await WniGatewayBillingSubmissionService.getBillingPoliciesByAccountNumber([accountNumber], authHeader)
            }
            const policyTableDataGroupByInvoiceStreamCode = AQPaymentPageUtil
                .groupDataByInvoiceStreamCode(data);
            updatePolicyTableData(policyTableDataGroupByInvoiceStreamCode);
            updateInitDataRetrieved(true);
        } catch (e) {
            _.noop();
        } finally {
            updateInitDataRetrieved(true);
        }
    }, [accountNumber, authHeader, setLoadingMask]);

    const retrieveDocuments = useCallback(async (toUpdate) => {
        try {
            const docs = await WniAccountService
                .getPortalBillingDocumentsForAccount(
                    accountNumber, authHeader
                );
            const newDocumentWithProductDTOs = [];
            let retainInAgencyDocs = DocumentsUtil.getDocumentData(docs, DocumentsUtil.RETAIN_IN_AGENCY);
            const newDocumentData = {
                productName: 'AutoPay Forms',
                jobNumber: '',
                retainInAgencyDocs: retainInAgencyDocs
            };
            // add autopay enrollment documents
            const autopayEnrollments = await WniGatewayBillingSubmissionService
                .getPortalDocumentsForAccountGroupByType([accountNumber, toUpdate], authHeader);
            const autopayEnrollmentDocs = DocumentsUtil.getDocumentData(autopayEnrollments, DocumentsUtil.RETAIN_IN_AGENCY);
            retainInAgencyDocs = retainInAgencyDocs.concat(autopayEnrollmentDocs);
            _.set(newDocumentData, 'retainInAgencyDocs', retainInAgencyDocs);
            if (!_.isEmpty(retainInAgencyDocs)) {
                newDocumentData.documents = [
                    { data: newDocumentData.retainInAgencyDocs },
                    { data: newDocumentData.submitToWesternNationalDocs }
                ];
                newDocumentWithProductDTOs.push(newDocumentData);
                updateAccountDocuments(newDocumentWithProductDTOs);
            }
           
        } catch (e) {
            _.noop();
        } finally {
            updateDocumentsRetrieved(true);
        }
    }, [accountNumber, authHeader]);

    useEffect(() => {
        async function onInit() {
            if (!initialised) {
                const isCLProductt = await fetchAccountInfoWithJobNumber(accountNumber);;
                // load the data from backend service
                await retrieveInitData(isCLProductt);
                await retrieveDocuments(null);
                updateInitialised(true);
                updateIsCL(isCLProductt);
            }
          }
        onInit();
        // if (!initialised) {
        //     // load the data from backend service
        //     retrieveInitData();
        //     retrieveDocuments(null);
        //     fetchAccountInfoWithJobNumber(accountNumber);
        //     updateInitialised(true);
        // }
    }, [accountNumber, initialised]);

    useEffect(() => {
        if (initDataRetrieved && documentsRetrieved) {
            setLoadingMask(false);
        }
    }, [initDataRetrieved, documentsRetrieved]);

    const getAccountInfo = useCallback(() => {
        const url = interactionModel.getURLObj(null, 'accountSummary', accountNumber);
        return (
            <div>
                <div>
                    <FieldLinkComponent
                        id="accountNameLink"
                        className={styles.removeLinkStyle}
                        value={acccountHolderDisplayName}
                        {...url}
                    />
                </div>
                <div>
                    <FieldLinkComponent
                        id="accountNumberLink"
                        className={styles.removeLinkStyle}
                        value={accountNumber}
                        {...url}
                    />
                </div>
            </div>
        );
    }, [acccountHolderDisplayName, accountNumber, interactionModel]);

    const initPolicyChange = useCallback(async (policyNumber, productCode) => {
        const { lobEndorsementURL, endorsementProducts } = appConfig;
        if (endorsementProducts.includes(productCode)) {
            history.push(lobEndorsementURL[productCode],
                { policyNumber });
        }
    }, [history]);

    const saveAndUpdateBillingData = useCallback(async (res) => {
        setLoadingMask(true);
        const toUpdate = {
            invoiceStreamCode: _.get(res, 'invoiceStreamCode'),
            invoiceStreamDay: _.get(res, 'invoiceStreamDay'),
            applyToOtherQuotes: _.get(res, 'applyToOtherQuotes'),
            payUsing: _.get(res, 'payUsing'),
            primaryPayer: _.get(res, 'primaryPayer'),
            selectedPaymentPlan: _.get(res, 'selectedPaymentPlan'),
            policyNumber: _.get(res, 'policyNumber'),
            accountNumber: _.get(res, 'accountNumber'),
            periodPublicID: _.get(res, 'periodPublicID'),
            policyPCPublicID: _.get(res, 'policyPCPublicID'),
            bankAccountData: _.get(res, 'bankAccountData')
        };
        let rs;
        if (isCL) {
            rs = await WniCLBillingSubmissionService.saveBillingParameterToPolicies([toUpdate], authHeader);
        } else {
            rs = await WniGatewayBillingSubmissionService.saveBillingParameterToPolicies([toUpdate], authHeader);
        }
        if (!rs) {
            modalApi.showAlert({
                title: commonMessages.genericError,
                message: commonMessages.genericErrorMessage,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(() => {
                _.noop();
            });
        }
        await retrieveInitData(isCL);
        await retrieveDocuments(toUpdate);
        setLoadingMask(false);
    }, [authHeader, isCL, modalApi, retrieveDocuments, retrieveInitData, setLoadingMask]);

    const rePopupEditQuoteRow = useCallback((modalProps) => {
        return modalApi.showModal(<EditBillingPopup {...modalProps} />)
            .then(async (res) => {
                // update in BC
                await saveAndUpdateBillingData(res, []);
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            });
    }, [modalApi, saveAndUpdateBillingData, setLoadingMask]);

    const editQuoteRow = useCallback(async (e, item) => {
        const mortgageBill = _.get(item, 'mortgageBill');
        if (mortgageBill) {
            await initPolicyChange(_.get(item, 'policyNumber'), _.get(item, 'productCode'));
            return false;
        }
        // show edit popup
        const policyNumber = _.get(item, 'policyNumber');
        const productName = getProductNameByLinePattern(_.get(item, 'product'));
        _.set(item, 'accountNumber', accountNumber);
        const effectiveDate = _.get(item, 'effectiveDate');
        const date = new Date(effectiveDate.year, effectiveDate.month, effectiveDate.day);
        const formatedEffectiveDate = WniDateUtil.formatDateWithPattern(date);
        const groupData = _.find(policyTableData, (data) => 
            _.get(data, 'invoiceStreamCode') === _.get(item, 'invoiceStreamCode')
        );
        const policies = _.get(groupData, 'data');
        const initApplyToOtherQuotes = _.filter(policies, (policy) =>
            _.get(policy, 'quoteNumber') !== _.get(item, 'quoteNumber'));

        const selectedItem = {
            title: translator(messages.editPaymentDetails,
                { productName: productName, policyNumber: policyNumber, effectiveDate: formatedEffectiveDate }),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            actionBtnLabel: messages.dialogOk,
            cancelBtnLabel: messages.dialogCancel,
            authHeader,
            rowData: item,
            warningMessage: translator(messages.editPaymentDetailsWarning),
            initApplyToOtherQuotes: initApplyToOtherQuotes,
            rePopupEditQuoteRow: rePopupEditQuoteRow,
            getProductName,
            productName,
            breakpoint,
            isCL
        };
        modalApi.showModal(<EditBillingPopup {...selectedItem} />)
            .then(async (res) => {
                // update in BC
                await saveAndUpdateBillingData(res, []);
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            });
    }, [accountNumber, authHeader, breakpoint, initPolicyChange,
        isCL, modalApi, policyTableData, rePopupEditQuoteRow, saveAndUpdateBillingData, setLoadingMask, translator]);

    const renderQuoteTableAction = useCallback((item, index, property) => {
        const isPayAsYouGo = _.get(item, 'data[0].isPayAsYouGo', false);
        const isAgencyBill = _.get(item, 'data[0].billingMethod', null) === 'Agency Bill';
        // CL line and Pay As You Go or Agency Bill
        if (isCL && (isPayAsYouGo || isAgencyBill)) {
            return '';
        }
        return AQPaymentTableUtil.renderEditIconCell(item, index, property);
    }, [isCL]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
            },
            pageTitle: {
                content: translator(messages.pageTitle)
            },
            accountLabelTitle: {
                content: translator(messages.account)
            },
            accountNumberTitle: {
                content: getAccountInfo()
            },
            policyDataTable: {
                data: policyTableData
            },
            policyTableActionColumn: {
                editQuoteRowFn: editQuoteRow,
                renderCell: renderQuoteTableAction
            },
            documentcheckedList: {
                visible: !_.isEmpty(accountDocuments),
                history: history,
                submission: submission,
                renderData: accountDocuments,
                showSendViaEmail: true,
                useJobNumber: true,
                showQuoteDocTitleAction: false,
                showCheckboxIcon: false
            },
            billingFooterButtonSection: {
                className: `mt-20 ${styles.alignRight}`
            },
            returnToBillingSummaryButton: {
                onClick: () => {
                    return history.push(`/accounts/${accountNumber}/billingAndPayment`)
                }
            }
        };
    }, [accountDocuments, accountNumber, editQuoteRow, getAccountInfo, getProductName, history, interactionModel, isCL, policyTableData, renderQuoteTableAction, submission, translator]);

    const renderPage = useCallback(() => {
        const overrideProps = generateOverrides();
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                renderLocalDateCell: AQPaymentTableUtil.renderLocalDateCell,
                renderPolicyCell: AQPaymentTableUtil.renderPolicyCell,
                renderBillAccountCell: AQPaymentTableUtil.renderBillAccountCell,
                renderFieldCell: AQPaymentTableUtil.renderFieldCell,
                renderPaymentMethodCell: AQPaymentTableUtil.renderPaymentMethodCell,
                renderPaymentPlanCell: AQPaymentTableUtil.renderPaymentPlanCell,
                renderQuoteTableAction: renderQuoteTableAction
            },
            resolveComponentMap: {
                aqquotetablecomponent: AQQuotetableComponent,
                documentsectioncomponent: DocumentSectionComponent
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{}}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    }, [generateOverrides, renderQuoteTableAction]);

    //---------------------
    return (
        <AQWizardPage
            showNext={false}
            showPrevious={false}
            // onPrevious={onPrevious}
            showCancel={false}
            template={AQWizardPageTempalte}
        >
            {renderPage}
        </AQWizardPage> 
    );
}

AQBillingPage.propTypes = WizardPage.propTypes;
export default AQBillingPage;

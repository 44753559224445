import { defineMessages } from 'react-intl';

export default defineMessages({
    deleteRiskTitle: {
        id : 'wni.quoteandbind.cp-wizard.cp-risks.deleteRiskTitle',
        defaultMessage: 'Are You Certain you would like to Delete this risk?'
    },
    deleteRiskInfo: {
        id : 'wni.quoteandbind.cp-wizard.cp-risks.deleteRiskInfo',
        defaultMessage: 'Doing so will also remove all risks grouped under it.'
    },
    modalOk: {
        id : 'wni.quoteandbind.cp-wizard.cp-risks.deleteRisk',
        defaultMessage: 'Delete Risk'
    },
    modalCancel: {
        id : 'wni.quoteandbind.cp-wizard.cp-risks.cancel',
        defaultMessage: 'Cancel'
    },
    exportTitle:{
        id: 'wni.quoteandbind.cp-wizard.cp-risks.Export',
        defaultMessage: 'Export',
    },
    exportToSpreadsheet: {
        id: 'wni.quoteandbind.cp-wizard.cp-risks.Export To Spreadsheet',
        defaultMessage: 'Export To Spreadsheet',
    },
    buildingDescriptionTitle: {
        id: 'wni.quoteandbind.cp.building.building description',
        defaultMessage: 'Building Description',
    },
    buildingDescriptionTooltip: {
        id: 'wni.quoteandbind.cp.building.Please provide a description of the building to distinguish it from others at the location',
        defaultMessage: 'Please provide a description of the building to distinguish it from others at the location',
    },
    classCodeSearch: {
        id: 'wni.quoteandbind.cp-wizard.cp-risks.Class Code Search',
        defaultMessage: 'Class Code Search',
    },
    classDescriptionSearch: {
        id: 'wni.quoteandbind.cp-wizard.cp-risks.Class Description Search',
        defaultMessage: 'Class Description Search',
    }
});
import { Button, Tab, TabSet } from '@jutro/components';
import { Flex } from '@jutro/layout';
import _ from 'lodash';
import markdownit from 'markdown-it';
import React, { useState } from 'react';

function PreCode(props) {
    const { id, data = {}, activeTab: defaultActiveTab } = props;
    const [activeTab, updateActiveTab] = useState( defaultActiveTab || 'jsx');
    const [showCode, updateShowCode] = useState(false);

    const dataArr = Object.keys(data).map((key) => {
        return {
            key: key,
            value: data[key],
        };
    });

    const md = markdownit({
        html: true,
        breaks: true,
        linkify: true,
        typographer: true,
    });

    const unescapeAllCode = (str) => {
        return md.utils.unescapeAll(str);
    };

    const toggleFn = () => {
        updateShowCode(!showCode);
    };

    if(_.isEmpty(data)) {
        return null;
    }

    return (
        <div id={id}>
            <Flex justifyContent="right" className="ph-5">
                <Button variant="tertiary" onClick={toggleFn} className="ph-5" label={showCode ? 'Hide Code' : 'Show Code'} />
            </Flex>
            {showCode && (
                <TabSet
                    className="wniPreCodeTabs"
                    id="codeTabs"
                    onTabChange={(val) => updateActiveTab(val)}
                    activeTab={activeTab}
                >
                    {dataArr.map((item) => {
                        return (
                            <Tab
                                id={item.key}
                                heading={item.key}
                                key={item.key}
                            >
                                <pre>
                                    <code>{unescapeAllCode(item.value)}</code>
                                </pre>
                            </Tab>
                        );
                    })}
                </TabSet>
            )}
        </div>
    );
}

export default PreCode;

import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

function AccountCommonRoutes(props) {
    const history = useHistory();
    
    const {
        accountNumber,
        accountTile
    } = useParams();

    useEffect(() => {
        const targetURL = `/accounts/${accountNumber}/${accountTile}`;
        const stateObj = {
            
        };
        history.push(targetURL, stateObj);
    }, []);

    return null;
}

export default AccountCommonRoutes;
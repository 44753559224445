import { Tab, Icon } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import React, { useState } from 'react';
import markdownit from 'markdown-it';
import { useTranslator } from '@jutro/locale';
import { Flex } from '@jutro/layout';
import { Link } from '@jutro/components/internal';
import metadata from './WniIconTab.metadata.json5';
import { custIcons, ootbIcons } from './WniIconTab.util';
import messages from '../../UIPlatform.messages';

const IconComponent = (props) => {
    const { icon } = props;
   
    const md = markdownit({
        html: true,
        breaks: true,
        linkify: true,
        typographer: true,
    });

    const unescapeAllCode = (str) => {
        return md.utils.unescapeAll(str);
    };

    return (
        <Flex justifyContent="center" gap="10px" direction="column" >
            <Icon className="font-24" icon={icon} />
            <pre>
                <code>{unescapeAllCode(icon)}</code>
            </pre>
        </Flex>
    )
};

function WniIconTab(props) {
    const translator = useTranslator();
    const renderContent = (dataList = []) => {
        return dataList.map((icon, index) => {
            return <IconComponent icon={icon} key={`icon${index}`} />
        })
    };
    
    const overrideProps = {
        iconList: {
            content: renderContent(custIcons)
        },
        ootbIconList: {
            content: renderContent(ootbIcons)
        },
        ootbIconCard: {
            subTitle: translator(messages.listOfIcons, { 
                listIcons: <Link href="https://docs.guidewire.com/jutro/storybook/10.7/?path=/story/icon--all-icons&globals=theme:Enterprise">List of icons</Link> 
            })
        }
        
    };
    const resolvers = {
        resolveComponentMap: {
            iconComponent: IconComponent
        },
        resolveCallbackMap: {
         
        }
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}

export default WniIconTab;

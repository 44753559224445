

const inputTextCode1 = {
    jsx: `
    import { 
        WniInputText,
        WniInputNumber,
        WniPhoneNumber,
        WniDropdownSelect,
        WniCurrency,
        WniDate,
        WniTextArea,
        WniMultiSelect,
        WniMultiSelectNext,
        WniToggle
    } from 'wni-common-base-components';

    <WniInputText
        label={{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
        tooltip={{ text : "this is a input text tooltip"}}
    />

    <WniInputNumber
        label={{
            "id": "wni.ui platform.example.field action.InputNumber",
            "defaultMessage": "InputNumber Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniDropdownSelect
        label={{
            "id": "wni.ui platform.example.field action.InputNumber",
            "defaultMessage": "DropdownSelect Example"
        }}
        availableValues=[
            {
                code: "01",
                name: "test 1"
            },
            {
                code: "02",
                name: "test 2"
            },
        ]
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />
    `,
    metadata: `
    {
        "id": "InputText",
        "type": "field",
        "component": "WniInputText",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Text",
                "defaultMessage": "Text Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop",
            "tooltip": {
                "text": "this is a input text tooltip"
            }
        }
    },

    {
        "id": "InputNumber",
        "type": "field",
        "component": "WniInputNumber",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Text",
                "defaultMessage": "Text Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop"
        }
    },

    {
        "id": "DropdownSelect",
        "type": "field",
        "component": "WniDropdownSelect",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Text",
                "defaultMessage": "Text Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop"
        }
    }
    `
}

const inputTextCode = {
    jsx: `
    import { WniInputText } from 'wni-common-base-components';

    <WniInputText
        label={{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />
    <WniInputText
        label={{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
        tooltip={{ text : "this is a input text tooltip"}}
    />
    
    `,
    metadata: `
    {
        "id": "InputText",
        "type": "field",
        "component": "WniInputText",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Text",
                "defaultMessage": "Text Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop"
        }
    },
    {
        "id": "InputText",
        "type": "field",
        "component": "WniInputText",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Text",
                "defaultMessage": "Text Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop",
            "tooltip": {
                "text": "this is a input text tooltip"
            }
        }
    },
    `
}

const dropdownCode = {
    jsx: `
    import { WniDropdownSelect, WniMultiSelect, WniMultiSelectNext } from 'wni-common-base-components';

    const [dropdownValue1, updateDropdownValue1] = useState();
    const [dropdownValue2, updateDropdownValue2] = useState();
    const [dropdownValue3, updateDropdownValue3] = useState([]);
    const [dropdownValue4, updateDropdownValue4] = useState([]);

    <WniDropdownSelect
        label={{
            "id": "wni.ui platform.example.field action.Dropdown",
            "defaultMessage": "Dropdown Example"
        }}
        path={path}
        value={dropdownValue1}
        onValueChange={(val) => updateDropdownValue1(val)}
        onValidate={_.noop}
        availableValues={[
            {
                "name": "option 1",
                "code": "option1"
            },
            {
                "name": "option 2",
                "code": "option2"
            },
            {
                "name": "option 3",
                "code": "option3"
            }
        ]}
    />
    <WniDropdownSelect
        label={{
            "id": "wni.ui platform.example.field action.Dropdown",
            "defaultMessage": "Dropdown Example"
        }}
        path={path}
        value={dropdownValue2}
        onValueChange={(val) => updateDropdownValue2(val)}
        onValidate={_.noop}
        tooltip={{ text : "this is a tooltip"}}
        availableValues={[
            {
                "name": "option 1",
                "code": "option1"
            },
            {
                "name": "option 2",
                "code": "option2"
            },
            {
                "name": "option 3",
                "code": "option3"
            }
        ]}
    />
    <WniMultiSelect
        label={{
            "id": "wni.ui platform.example.field action.Multi Dropdown",
            "defaultMessage": "Multi Dropdown Example"
        }}
        path={path}
        value={dropdownValue3}
        onValueChange={(val) => updateDropdownValue3(val)}
        onValidate={_.noop}
        tooltip={{ text : "this is a tooltip"}}
        availableValues={[
            {
                "name": "option 1",
                "code": "option1"
            },
            {
                "name": "option 2",
                "code": "option2"
            },
            {
                "name": "option 3",
                "code": "option3"
            }
        ]}
    />
    <WniMultiSelectNext
        label={{
            "id": "wni.ui platform.example.field action.Multi Dropdown",
            "defaultMessage": "Multi Dropdown Example"
        }}
        path={path}
        value={dropdownValue4}
        onValueChange={(val) => updateDropdownValue4(val)}
        onValidate={_.noop}
        tooltip={{ text : "this is a tooltip"}}
        availableValues={[
            {
                "name": "option 1",
                "code": "option1",
                "isDisabled": true
            },
            {
                "name": "option 2",
                "code": "option2"
            },
            {
                "name": "option 3",
                "code": "option3"
            }
        ]}
    />
    
    `,
    metadata: `
    {
        "id": "dropdown",
        "type": "field",
        "component": "WniDropdownSelect",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Dropdown Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop",
            "availableValues": [
                {
                    "name": "option 1",
                    "code": "option1"
                },
                {
                    "name": "option 2",
                    "code": "option2"
                },
                {
                    "name": "option 3",
                    "code": "option3"
                }
            ]
        }
    },
    {
        "id": "dropdown",
        "type": "field",
        "component": "WniDropdownSelect",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Dropdown Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop",
            "tooltip": {
                "text": "this is a input Dropdown tooltip"
            },
            "availableValues": [
                {
                    "name": "option 1",
                    "code": "option1"
                },
                {
                    "name": "option 2",
                    "code": "option2"
                },
                {
                    "name": "option 3",
                    "code": "option3"
                }
            ]
        }
    },
    {
        "id": "dropdowns_3",
        "type": "field",
        "component": "WniMultiSelect",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Multi Dropdown",
                "defaultMessage": "Multi Dropdown Example"
            },
            "tooltip": {
                "text": "this is a tooltip"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop",
            "availableValues": [
                {
                    "name": "option 1",
                    "code": "option1",
                    "isDisabled": true
                },
                {
                    "name": "option 2",
                    "code": "option2"
                },
                {
                    "name": "option 3",
                    "code": "option3"
                }
            ]
        }
    },
    {
        "id": "dropdowns_4",
        "type": "field",
        "component": "WniMultiSelectNext",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Multi Dropdown Disabled",
                "defaultMessage": "Multi Dropdown Disabled Example"
            },
            "tooltip": {
                "text": "this is a tooltip"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop",
            "availableValues": [
                {
                    "name": "option 1",
                    "code": "option1",
                    "isDisabled": true
                },
                {
                    "name": "option 2",
                    "code": "option2"
                },
                {
                    "name": "option 3",
                    "code": "option3"
                }
            ]
        }
    },
    `
};

const inputNumberCode = {
    jsx: `
    import { WniInputNumber } from 'wni-common-base-components';

    <WniInputNumber
        label={{
            "id": "wni.ui platform.example.field action.Input Number",
            "defaultMessage": "Input Number Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />
    <WniInputNumber
        label={{
            "id": "wni.ui platform.example.field action.Input Number",
            "defaultMessage": "Input Number Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
        tooltip={{ text : "this is a Input Number tooltip"}}
    />
    
    `,
    metadata: `
    {
        "id": "InputNumber",
        "type": "field",
        "component": "WniInputNumber",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Input Number",
                "defaultMessage": "Input Number Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop"
        }
    },
    {
        "id": "InputNumber",
        "type": "field",
        "component": "WniInputNumber",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Input Number",
                "defaultMessage": "Input Number Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop",
            "tooltip": {
                "text": "this is a Input Number tooltip"
            }
        }
    },
    `
};

const phoneNumberCode = {
    jsx: `
    import { WniPhoneNumber } from 'wni-common-base-components';

    <WniPhoneNumber
        label={{
            "id": "wni.ui platform.example.field action.Phone Number",
            "defaultMessage": "Phone Number Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />
    <WniPhoneNumber
        label={{
            "id": "wni.ui platform.example.field action.Phone Number",
            "defaultMessage": "Phone Number Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
        tooltip={{ text : "this is a Phone Number tooltip"}}
    />
    
    `,
    metadata: `
    {
        "id": "PhoneNumber",
        "type": "field",
        "component": "WniPhoneNumber",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Phone Number",
                "defaultMessage": "Phone Number Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop"
        }
    },
    {
        "id": "PhoneNumber",
        "type": "field",
        "component": "WniPhoneNumber",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Phone Number",
                "defaultMessage": "Phone Number Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop",
            "tooltip": {
                "text": "this is a Phone Number tooltip"
            }
        }
    },
    `
};

const currencyCode = {
    jsx: `
    import { WniCurrency } from 'wni-common-base-components';

    <WniCurrency
        label={{
            "id": "wni.ui platform.example.field action.Currency",
            "defaultMessage": "Currency Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />
    <WniCurrency
        label={{
            "id": "wni.ui platform.example.field action.Currency",
            "defaultMessage": "Currency Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
        tooltip={{ text : "this is a currency tooltip"}}
    />
    
    `,
    metadata: `
    {
        "id": "currency",
        "type": "field",
        "component": "WniCurrency",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Currency",
                "defaultMessage": "Currency Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop"
        }
    },
    {
        "id": "currency",
        "type": "field",
        "component": "WniCurrency",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Currency",
                "defaultMessage": "Currency Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop",
            "tooltip": {
                "text": "this is a currency tooltip"
            }
        }
    },
    `
};

const dateCode = {
    jsx: `
    import { WniDate } from 'wni-common-base-components';

    <WniDate
        label={{
            "id": "wni.ui platform.example.field action.Date",
            "defaultMessage": "Date Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />
    <WniDate
        label={{
            "id": "wni.ui platform.example.field action.Date",
            "defaultMessage": "Date Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
        tooltip={{ text : "this is a Date tooltip"}}
    />
    
    `,
    metadata: `
    {
        "id": "Date",
        "type": "field",
        "component": "WniDate",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Date",
                "defaultMessage": "Date Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop"
        }
    },
    {
        "id": "Date",
        "type": "field",
        "component": "WniDate",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Date",
                "defaultMessage": "Date Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop",
            "tooltip": {
                "text": "this is a Date tooltip"
            }
        }
    },
    `
};

const textAreaCode = {
    jsx: `
    import { WniTextArea } from 'wni-common-base-components';

    <WniTextArea
        label={{
            "id": "wni.ui platform.example.field action.TextArea",
            "defaultMessage": "TextArea Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />
    <WniTextArea
        label={{
            "id": "wni.ui platform.example.field action.TextArea",
            "defaultMessage": "TextArea Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
        tooltip={{ text : "this is a TextArea tooltip"}}
    />
    
    `,
    metadata: `
    {
        "id": "textArea",
        "type": "field",
        "component": "WniTextArea",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.TextArea",
                "defaultMessage": "TextArea Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop"
        }
    },
    {
        "id": "textArea",
        "type": "field",
        "component": "WniTextArea",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.TextArea",
                "defaultMessage": "TextArea Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop",
            "tooltip": {
                "text": "this is a TextArea tooltip"
            }
        }
    },
    `
};

const toggleCode = {
    jsx: `
    import { WniToggle } from 'wni-common-base-components';

    <WniToggle
        label={{
            "id": "wni.ui platform.example.field action.Toggle",
            "defaultMessage": "Toggle Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />
    <WniToggle
        label={{
            "id": "wni.ui platform.example.field action.Toggle",
            "defaultMessage": "Toggle Example"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
        tooltip={{ text : "this is a Toggle tooltip"}}
    />
    
    `,
    metadata: `
    {
        "id": "toggle",
        "type": "field",
        "component": "WniToggle",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Toggle",
                "defaultMessage": "Toggle Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop"
        }
    },
    {
        "id": "toggle",
        "type": "field",
        "component": "WniToggle",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Toggle",
                "defaultMessage": "Toggle Example"
            },
            "path": "path",
            "onValueChange": "_.noop",
            "onValidate": "_.noop",
            "tooltip": {
                "text": "this is a Toggle tooltip"
            }
        }
    },
    `
}

export {
    inputTextCode,
    dropdownCode,
    inputNumberCode,
    phoneNumberCode,
    dateCode,
    currencyCode,
    textAreaCode,
    toggleCode
}
import { Link } from '@jutro/components/internal';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import React from 'react';
import PreCode from '../../Code/PreCode';
import metadata from './WniConfiguration.metadata.json5';
import styles from './WniConfiguration.module.scss';

const renderPortalManagementInfoContent = () => {
    return (
        <ul className="font-PrimaryDark-light-16">
            <div className="font-PrimaryDark-bold-16">
                Open{' '}
                <Link href="http://localhost:8180/pc/PolicyCenter.do">
                    Policy Center
                </Link>{' '}
                (Administration → Utilities → Protal Management) control product
                for portal
            </div>
            <li className="ph-5">
                <b>creation Date:</b> product icon can be show in Portal Date,
                if before this Date, product will can not be created. for the
                exsiting submission, this date will control the submission
                buttons to be show or not
            </li>
            <li className="ph-5">
                <b>Enable Change or Cancel:</b> product can Change or Cancel
                Date, if before this Date, product can not be changed or
                canceled
            </li>
            <li className="ph-5">
                <b>Available:</b> product is available or not (if unAvailable,
                will can not create in Portal)
            </li>
        </ul>
    );
};

function WniConfiguration(props) {
    const overrideProps = {
        portalManagementInfo: {
            content: renderPortalManagementInfoContent(),
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            preCode: PreCode,
        },
        resolveCallbackMap: {},
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}

export default WniConfiguration;

import { defineMessages } from 'react-intl';

export default defineMessages({

    defautlTitle :{
        id: 'quoteandbind.ca.directives.templates.cp-edit-location.Import',
        defaultMessage: 'Import',
    },

    importTimeout: {
        id: 'quoteandbind.ca.gw-portals-policytransaction-common-angular.components.Uploading is in progress, please do not reload the screen',
        defaultMessage:
            'Uploading is in progress, please do not reload the screen',
    },

    locationRead: {
        id: 'quoteandbind.ca.directives.templates.cp-edit-location.location read',
        defaultMessage: 'Number of Location Entities Read:',
    },
    locationEdited: {
        id: 'quoteandbind.ca.directives.templates.cp-edit-location.location edited',
        defaultMessage: 'Number of Location Entities Edited:',
    },
    locationAdded: {
        id: 'quoteandbind.ca.directives.templates.cp-edit-location.location added',
        defaultMessage: 'Number of Location Entities Added:',
    },
    locationRemoved: {
        id: 'quoteandbind.ca.directives.templates.cp-edit-location.location Removed',
        defaultMessage: 'Number of Location Entities Removed:',
    },
    specialClassBusIncMessage: {
        id: 'quoteandbind.ca.directives.templates.cp-edit-location.specialClassBusIncMessage',
        defaultMessage: 'Special Class Business Income'
    }
});

import React, {
    useState,
    useEffect
} from 'react'
import { WniInputNumber } from 'wni-common-base-components';

export const DetailsPercentInput = (props) => {

    const {
        sublinePublicID,
        displayable,
        detailsIndex,
        onValidate,
        disabled,
        showErrors,
        isEditable,
        changeDetailsAndSync,
    } = props

    const {
        propertyName,
        label,
        required,
        editable: isDisplayableEditable,
        decimalValue,
    } = displayable

    const [editingValue, setEditingValue] = useState(decimalValue)

    useEffect(() => {
        setEditingValue(decimalValue)
    }, [decimalValue])

    const onDecimalValueChange = (newValue) => {
        setEditingValue(newValue)
    }

    const onBlur = () => {
        const newDisplayable = {
            ...displayable,
            updated: true,
            decimalValue: editingValue
        }
        if(editingValue<=100 && editingValue>=0.01 || editingValue === '' ){
            changeDetailsAndSync(newDisplayable, detailsIndex)
        }
    }

    return <WniInputNumber
        id={`${sublinePublicID}_displayable_decimalinput_${propertyName}`}
        labelPosition='left'
        label={label}
        value={editingValue}
        onValueChange={onDecimalValueChange}
        onBlur={onBlur}
        placeholder=""
        size="medium"
        disabled={disabled}
        className='flex-1'
        required={required}
        showRequired
        onValidationChange={onValidate}
        showErrors={showErrors}
        readOnly={!isDisplayableEditable || !isEditable}
        allowNegative={false}
        decimalPlaces={2}
        minValue={0.01}
        maxValue={100}
    />
}
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import _ from 'lodash';
import { AppFloorPlan } from '@jutro/legacy/floorplan';
import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import { Loader, useModal, ModalNextEmitter } from '@jutro/components';
import appConfig from 'app-config';
import './App.scss';
import { ViewModelServiceFactory } from '@xengage/gw-portals-viewmodel-js';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { policyJobComponentMap, policyJobComponents } from 'gw-capability-policyjob-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { AccurateBreakpointPropagation } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { routeConfirmationModal } from '@jutro/router';
import vmTranslator, { messages as commonMessages } from '@xengage/gw-platform-translations';
import { platformComponents, platformComponentMap, ImageComponent } from 'gw-components-platform-react';
// import { brandingData, setBranding } from 'gw-portals-branding-js';
import {
    MenuBarComponent,
    PageHeaderComponent,
    ContactRoles
} from 'gw-capability-gateway-react';
import { useBusinessData } from 'wni-portals-util-react';
import { useDebugData, updateEnableProducerEngageWithAgentsOnline } from 'wni-debug-platform-react';
import { SelectProducerContextProvider } from 'gw-gateway-common-react';
import { ServiceErrorUtil, ConfigUtil } from 'wni-portals-util-js';
import {
    BrowserRouter as Router,
    Redirect
} from 'react-router-dom';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import {
    useAuthentication,
    withAuthenticationProvider
} from '@xengage/gw-digital-auth-react';
import { GatewayAvailabilityService } from 'gw-capability-gateway-policycommon';
import { GatewayRenewalService } from 'gw-capability-gateway-policyrenewal';
import { GatewayEndorsementService } from 'gw-capability-gateway-policychange';
import { LoadSaveService, CustomQuoteService } from 'gw-capability-gateway-quoteandbind';
import { UserService, SubmissionService, JobService } from 'gw-capability-gateway';
import { BopCoverablesService } from 'gw-capability-gateway-policyjob-bop';
import { GatewayClaimService } from 'gw-capability-gateway-claim';
import { GatewayBillingService } from 'gw-capability-gateway-billing';
import { GatewayFNOLService } from 'gw-capability-gateway-fnol';
import { AccountService } from 'gw-capability-gateway-policy';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { GatewaySpreadsheetService } from 'gw-capability-gateway-spreadsheet';
import { ProducerInfoService } from 'gw-capability-profileinfo';
import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { PropertyCodeLookupService } from 'gw-capability-quoteandbind-cp';
import { AddressLookupComponentMap, AddressLookupComponents } from 'gw-capability-address-react';
import { VehicleInfoLookupComponentMap, VehicleInfoLookupComponents } from 'gw-capability-vehicleinfo-react';
import { PageFooterComponent } from 'wni-capability-gateway-react';
import { GatewayClaimDocumentService } from 'gw-capability-gateway-claimdocument';
import { filterCapabilityRoutes } from '@xengage/gw-portals-config-js';
import { WniBaseModal } from 'wni-common-base-components';
import 'wni-portals-wizard-components-ui';
import messages from './App.messages';
import appRoutes from './App.routes.metadata.json5';
// make sure the IFRAME is imported as is
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import '!!raw-loader!../../public/redirect-login.html';
import componentMap from './App.routes';

const modalEmitter = new ModalNextEmitter();

const { capabilitiesConfig } = appConfig;

setComponentMapOverrides({
    ...platformComponentMap,
    ...AddressLookupComponentMap,
    ...VehicleInfoLookupComponentMap,
    ...policyJobComponentMap,
    ContactRoles: {component: 'ContactRoles'},
    WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
    // replace the native IMG component with a proxied version
    img: { component: 'img' },
    
},
{
    ...platformComponents,
    ...AddressLookupComponents,
    ...VehicleInfoLookupComponents,
    ...policyJobComponents,
    ContactRoles,
    WizardPageTemplateWithTitle,
    img: ImageComponent,
});
const defaultRoutes = filterCapabilityRoutes(capabilitiesConfig, appRoutes.routes);

const getUserAuthData = (authData) => {
    return UserService.getGatewayCurrentUser(authData.authHeader).catch(() => {
        if(authData) { authData.logout(); } 
    });
};

// const prefix = _.get(env, 'DEPLOYMENT_PATH', '').replace(/\/$/, '');
// const logoSrc = `${prefix}/branding/logo-version-agents.svg`;

function App(props) {
    const modalApi = useModal();
    const { enableCommercialLine, getFeatureCodeValue } = useBusinessData();

    const translator = useTranslator();
    const [viewModelService, updateViewModelService] = useState(undefined);
    const [routes, updateRoutes] = useState(defaultRoutes);
    const [loadingMask, updateLoadingMask] = useState(false);
    const [appCacheData, updateAppCacheData] = useState({});
    const [showBaseModal, updateShowBaseModal] = useState(false);
    const [baseModalConfig, updateBaseModalConfig] = useState({});

    const {
        isDebugEnabled,
        //
        domainCompany,
        interactionModel,
        isMenuBarEnabled,
        workflowType,
        productionWorkflow,
        commonUIType,
        //
        renderDebugPanel,
    } = useDebugData();

    useEffect(
        () => {
            const translatorFn = vmTranslator(translator);
            import(
                /* webpackChunkName: "product-metadata" */
                // eslint-disable-next-line import/no-unresolved
                'product-metadata'
            ).then((productMetadata) => {
                const { default: result } = productMetadata;
                updateViewModelService(
                    ViewModelServiceFactory.getViewModelService(result, translatorFn)
                );
            });
        },
        [translator]
    );

    useEffect(() => {
        const isEnable = getFeatureCodeValue('EnableProducerEngageWithAgentsOnline');
        updateEnableProducerEngageWithAgentsOnline(isEnable);
    }, []);

    useEffect(() => {
        _.set(window, '__giwni.modalApi', { modalApi});
    }, [modalApi]);   

    // if (brandingData.BRANDING) {
    //     setBranding();
    // }
    const updateAppCacheDataFn = useCallback((toUpdate) => {
        const updateProperty = {};
        Object.keys(toUpdate).forEach((k) => {
            const existingValue = appCacheData[k] || {};
            const toUpdateValue = Object.assign(existingValue, toUpdate[k]);
            updateProperty[k] = toUpdateValue;
        });
        const newCacheData = Object.assign(appCacheData, updateProperty);
        updateAppCacheData(newCacheData);
    }, [appCacheData]);

    const handleError = useCallback((error = {}) => {
        const state = {
            pathname: '/',
            state: error
        };
        updateLoadingMask(false);
        const errorMsg = ServiceErrorUtil.getErrorMessage(error, messages.errorMessage);
        return modalApi.showAlert({
            title: messages.error || 'Error',
            message: errorMsg,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(
            () => {
                updateLoadingMask(false);
                return <Redirect to={state} />;
            },
            _.noop
        );
    }, []);

    const setLoadingMask = (newMask) => {
        // this method is added for the convenience of debugging
        updateLoadingMask(newMask);
    };

    const loadingRender = () => {
        const loadingDom = (
            <div className="loadingPageMask">
                <div className="loadingMask" />
                <Loader loaded={false} />
            </div>
        );
        return loadingMask ? loadingDom : null;
    };

    const getDebugComponent = () => {
        if (!isDebugEnabled) {
            return null;
        }
        return (
            <>
                {renderDebugPanel()}
            </>
        );
    };
    const getMenuBarComponent = () => {
        if(!enableCommercialLine) {
            return null
        };
        return <MenuBarComponent />
    };
    const floorPlansConfig = useMemo(() => {
        return [{
            scrollContent: false,
            showFooter: false,
            routes: routes,
            showSubHeader: false,
            showHeader: false,
            renderHeader: PageHeaderComponent
            
        }];
    }, [routes]);

    const resolveModalResult = useRef();

    const handleModal = async (modalProps) => {
        updateShowBaseModal(true);
        updateBaseModalConfig(modalProps);
        
        const { promise, resolve, reject} = Promise.withResolvers();
        resolveModalResult.current = resolve
        return promise;
    };

    const updateModalResult = (res) => {
        updateShowBaseModal(false);
        resolveModalResult.current(res)
    }

    const renderBaseModal = () => {
        return (
            showBaseModal && (
                <WniBaseModal
                    {...baseModalConfig}
                    updateShowBaseModal={updateShowBaseModal}
                    updateModalResult={updateModalResult}
                />
            )
        );
    }

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider
                value={{
                    BopCoverablesService: BopCoverablesService,
                    LoadSaveService: LoadSaveService,
                    CustomQuoteService: CustomQuoteService,
                    ClaimService: GatewayClaimService,
                    ClaimDownloadService: GatewayClaimDocumentService,
                    FNOLService: GatewayFNOLService,
                    AvailabilityService: GatewayAvailabilityService,
                    RenewalService: GatewayRenewalService,
                    DocumentService: GatewayDocumentService,
                    SpreadsheetService: GatewaySpreadsheetService,
                    EndorsementService: GatewayEndorsementService,
                    AccountService: AccountService,
                    BillingService: GatewayBillingService,
                    UserService: UserService,
                    PropertyCodeLookupService: PropertyCodeLookupService,
                    ContactService: ProducerInfoService,
                    quoteUnderWritingService: {
                        referToUnderwriter: SubmissionService.referToUnderwriter,
                        withdrawJobByJobNumber: JobService.withdrawJobByJobNumber,
                        approveUnderwritingIssue: LoadSaveService.approveUnderwritingIssue
                    },
                    domainCompany: domainCompany,
                    interactionModel: interactionModel,
                    loadingMask: { setLoadingMask, isLoadingMask: loadingMask },
                    workflowType,
                    productionWorkflow,
                    commonUIType,
                    baseModalConfig: { showBaseModal: handleModal },
                    applicationCache: { updateAppCacheDataFn, appCacheData: appCacheData }
                }}
            >
                <ViewModelServiceContext.Provider value={viewModelService}>
                    <ErrorBoundary onError={handleError}>
                        <SelectProducerContextProvider>
                            {loadingRender()}
                            <Router
                                basename={ConfigUtil.getAppBaseRouterURL()}
                                getUserConfirmation={(message,callback) => routeConfirmationModal(message,callback,modalEmitter)}>
                                <PageHeaderComponent />
                                {getMenuBarComponent()}
                                {getDebugComponent()}
                                <AppFloorPlan
                                    componentMap={componentMap}
                                    floorPlans={floorPlansConfig}
                                />
                                {renderBaseModal()}
                            </Router>
                            <PageFooterComponent />
                        </SelectProducerContextProvider>
                    </ErrorBoundary>

                    {/* baff router*/ }
                    {/* <Router
                        basename={ConfigUtil.getAppBaseRouterURL()}
                        getUserConfirmation={routeConfirmationModal}
                    >
                        <RoutingTracking />
                        <ModalNextProvider />
                        <ErrorBoundary onError={handleError}>
                            <Switch>
                                <Route exact path="/login-page" component={EntryPage} />
                                <DynamicRoute path="/">
                                    <SelectProducerContextProvider>
                                        {loadingRender()}
                                        <PageHeaderComponent />
                                        {getDebugComponent()}
                                        <Main className="producerEngageContainer">
                                            <PageRouting />
                                        </Main>
                                        <PageFooterComponent />
                                    </SelectProducerContextProvider>
                                </DynamicRoute>
                            </Switch>
                        </ErrorBoundary>
                    </Router> */}
                </ViewModelServiceContext.Provider>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}
export default withAuthenticationProvider({ onAuthDataCreation: getUserAuthData })(App);

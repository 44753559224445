import _ from 'lodash';
import {
    WniClausesUtil,
} from 'wni-portals-util-js';

function getPathToTerm(changedPath) {
    // onBlur event returns an object instead of path as a String
    const path = _.isString(changedPath) ? changedPath : changedPath.model;
    const matched = /(.*.terms(.children)?\[\d+\])(.*$)/.exec(path);
    if (!matched) {
        return changedPath;
    }
    const [, termPath] = matched;
    return termPath;
}

// This is the last part of path, such as dateValue stringValue
function getScheduleItemDataRelativePath(scheduleValuePath) {
    const regex = /([^.]\w+)$/g
    // There should be only one path in array
    const matchedPaths =  scheduleValuePath.match(regex)
    if (_.get(matchedPaths, 'length') === 1) {
        return matchedPaths[0]
    }
    return null
}

function getScheduleItemDataBasePath(scheduleValuePath) {
    const regex = /(\.\w+)$/g
    return scheduleValuePath.replace(regex, '')
}

function getValuePathByVMPath(VMPath) {
    return VMPath.replaceAll('.children', '')
}

function setValue(coverages, pathToCoverages, value) {
    // Currency field
    if (_.get(value, 'currency')) {
        _.set(coverages, pathToCoverages, _.get(value, 'amount'));
    } else {
        _.set(coverages, pathToCoverages, value);
    }
    return coverages
}

function setValueToCoverages(coverages, value, path) {
    const pathToCoverages = getValuePathByVMPath(path)
    const pathToChangedCoverage = pathToCoverages.substring(0, 3);
    coverages = setValue(coverages, pathToCoverages, value);
    if (path.includes('.terms')) {
        const pathToTerm = getPathToTerm(pathToCoverages);
        _.set(coverages, `${pathToTerm}.updated`, true);
    } else if (path.includes('.schedule')) {
        _.set(coverages, `${pathToChangedCoverage}.schedule.updated_Ext`, true);
    } else {
        _.set(coverages, `${pathToChangedCoverage}.updated`, true);
    }
    return coverages.concat([]);
}

function setValueToCoverage(coverage, value, path) {
    const pathToCoverage = getValuePathByVMPath(path)
    coverage = setValue(coverage, pathToCoverage, value);
    if (path.startsWith('terms')) {
        const pathToTerm = pathToCoverage.substring(0, 8);
        _.set(coverage, `${pathToTerm}.updated`, true);
    } else if (path.startsWith('schedule')) {
        _.set(coverage, `schedule.updated_Ext`, true);
    } else {
        _.set(coverage, `updated`, true);
    }
    return coverage;
}

function setClauseValue(coverageContainer, pathToClausesFromContainer, value, path) {

    let changedValuePathToCoverages = path.replace(pathToClausesFromContainer, '');
    if (!_.isArray(coverageContainer)) {
        changedValuePathToCoverages = changedValuePathToCoverages.substring(1)
        const coverage = _.get(coverageContainer, pathToClausesFromContainer)
        const newCoverage = setValueToCoverage(coverage, value, changedValuePathToCoverages)
        return newCoverage
    }
    return setValueToCoverages(coverageContainer, value, changedValuePathToCoverages);
}

function selectCoveragesBySelectedCoverageCodes(coverages, selectedCoverageCodes) {
    const res = selectedCoverageCodes.reduce((updatedCoverages, selectedCoverageCode) => {
        return updatedCoverages.map((updatedCoverage) => {
            if (_.get(updatedCoverage, 'code_Ext') === selectedCoverageCode) {
                _.set(updatedCoverage, 'selected', true)
                _.set(updatedCoverage, 'updated', true)
            }
            return updatedCoverage
        })
    }, coverages)
    return res
}

const riskDetailsPaths = ['standardCoverage', 'additionalCoverage', 'exclusion', 'condition']

function generateUpdatedRiskDetailsDTO(lineClauses) {

    const emptyDetailsFieldObj = riskDetailsPaths.reduce((p, c) => {
        const res = p
        res[c] = undefined
        return p
    }, {})
    const updatedlineClausesDTO = {
        ...lineClauses,
        ...emptyDetailsFieldObj
    }
    riskDetailsPaths.forEach((clausePath) => {
        const unfilteredClauses = _.get(lineClauses, clausePath, [])
        const clausesToUpdate = WniClausesUtil.filterUnchangedClauses(unfilteredClauses)
        if (!_.isEmpty(clausesToUpdate)) {
            _.set(updatedlineClausesDTO, clausePath, clausesToUpdate)
        }
    })
    return updatedlineClausesDTO
}

function isClauseToUpdateEmpty(clausesToUpdate, lobName) {
    const lobCoverages = _.get(clausesToUpdate, lobName);
    return _.isEmpty(lobCoverages)
}

const isOptionTerm = (term) => {
    return _.isNil(_.get(term, 'valueType'))
}

const isTermHasOnlyOneOption = (term) => {
    const options = _.get(term, 'options', [])
    return isOptionTerm(term) && options.length === 1
}

const getOneOptionTermsInCoverage = (coverage) => {
    const terms = _.get(coverage, 'terms', []);
    const oneOptionTerms = terms.filter((term) => isTermHasOnlyOneOption(term));
    return oneOptionTerms;
}

const isChosenTermNull = (term) => {
    const chosenTerm = _.get(term, 'chosenTerm')
    if (_.isNil(chosenTerm)) {
        return true
    }
    const options = _.get(term, 'options', [])
    const optionCodes = options.map((option) => _.get(option, 'code'))
    if (optionCodes.includes(chosenTerm)) {
        return false
    }
    return true
}

const isCoverageHasOneOptionAndNullTerm = (coverage) => {
    const oneOptionTerms = getOneOptionTermsInCoverage(coverage);
    const oneOptionAndNullTerms = oneOptionTerms.filter((term) => isChosenTermNull(term))
    return _.get(oneOptionAndNullTerms, 'length') > 0;
}

const hasOneOptionAndNullTermInCoverages = (coverages) => {
    const coverageHasOneOptionAndNullTerm = coverages.filter((coverage) => {
        return isCoverageHasOneOptionAndNullTerm(coverage)
    })
    return _.get(coverageHasOneOptionAndNullTerm, 'length') > 0
}

const setValueToOneOptionAndNullTermToCoverages = (coverages) => {
    const updatedCoverages = coverages.map((coverage) => {
        if (!isCoverageHasOneOptionAndNullTerm(coverage)) {
            return coverage;
        }
        let updatedCoverage = _.clone(coverage);
        const terms = _.get(coverage, 'terms', []);
        const oneOptionAndNullTermCodes = terms
            .filter((term) => {
                const termHasOnlyOneOption = isTermHasOnlyOneOption(term);
                const isTermNull = isChosenTermNull(term)
                return termHasOnlyOneOption && isTermNull
            })
            .map((term) => {
                return _.get(term, 'code_Ext')
            });
        oneOptionAndNullTermCodes.forEach((termCode) => {
            const termIndex = terms.findIndex((term) => _.get(term, 'code_Ext') === termCode);
            const termToUpdate = terms[termIndex];
            const chosenTermValue = _.get(termToUpdate, 'options[0].code')
            updatedCoverage = setValueToCoverage(updatedCoverage, chosenTermValue, `terms[${termIndex}].chosenTerm`)

        })
        return updatedCoverage
    })
    return updatedCoverages
}

function isTermInvalid(term) {
    // Use chosenTerm instead of chosenTermValue.
    // chosenTermValue only update after vm is sent to PC
    // value field of date is directDateValue
    if (_.get(term, 'readOnly_Ext')) {
        return false
    }

    if (!_.isNil(term.directValue)) {
        const {
            directValueMax,
            directValueMin
        } = term
        if (!_.isNil(directValueMax) && term.directValue > directValueMax) {
            return true
        }
        if (!_.isNil(directValueMin) && term.directValue < directValueMin) {
            return true
        }
    }
    const {
        valueType,
        required,
        name: termName
    } = term

    if (['longtext', 'shorttext'].includes(valueType)) {
        if(termName === 'Year' && required && _.isNil(term.directStringValue)) {
            return true
        }
        if(termName !== 'Year' && required && _.isEmpty(term.directStringValue)) {
            return true
        }
    }

    return term.required
        && !term.chosenTerm
        && !term.directDateValue
        && _.isNil(term.directValue)
        && _.isNil(term.directStringValue)
        && _.isNil(term.directBooleanValue);
}

function isScheduledItemDataInvalid(itemData, propertyInfo) {
    if (!_.get(propertyInfo, 'required')) {
        return false
    }
    if (!_.get(itemData, 'visible_Ext')) {
        return false
    }
    if (!_.get(itemData, 'editable_Ext')) {
        return false
    }
    if (!_.isNil(_.get(itemData, 'readOnly_Ext')) && !_.get(itemData, 'readOnly_Ext')) {
        return false
    }
    const {
        dateValue,
        typeCodeValue,
        stringValue,
        booleanValue,
        bigDecimal,
        integerValue,
        localDate_Ext: localDateObj,
    } = itemData
    if (_.isNil(dateValue)
        && _.isNil(typeCodeValue)
        && _.isEmpty(stringValue)
        && _.isNil(booleanValue)
        && _.isNil(bigDecimal)
        && _.isNil(integerValue)
        && _.isNil(localDateObj)
    ) {
        return true
    }
    // if type is option, check if chosenTerm is still in options
    if (_.get(propertyInfo, 'valueType') === 'TYPEKEY') {
        const options = _.get(itemData, 'options_Ext', [])
        const optionCodes = options.map((option) => _.get(option, 'code'))
        if (!optionCodes.includes(typeCodeValue)) {
            return true
        }
    }
    return false
}

function isScheduleItemInvalid(scheduleItem, propertyInfos = []) {
    if (_.isNil(scheduleItem)) {
        return false
    }
    const itemData = _.get(scheduleItem, 'itemData');
    const invalidItem = Object.keys(itemData).find((key) => {
        const scheduleItemData = _.get(itemData, key)
        const propertyInfo = propertyInfos.find((item) => _.get(item, 'id') === key)
        return isScheduledItemDataInvalid(scheduleItemData, propertyInfo)
    })
    if (_.isNil(invalidItem)) {
        return false
    }
    return true
}

function isScheduleItemFormInvalid(scheduleItem, propertyInfos = []) {
    if (_.isNil(scheduleItem)) {
        return false
    }
    const terms = _.get(scheduleItem, 'scheduleItemTerms', [])
    const invalidScheduleItem = isScheduleItemInvalid(scheduleItem, propertyInfos) || terms.some(term => isTermInvalid(term));
    if (invalidScheduleItem) {
        return true
    }
    return false
}

function isScheduleInvalid(schedule) {
    const propertyInfos = _.get(schedule, 'propertyInfos');
    const scheduleItems = _.get(schedule, 'scheduleItems');
    const invalidScheduleItem = _.find(scheduleItems, (scheduleItem) => {
        const terms = _.get(scheduleItem, 'scheduleItemTerms', [])
        return isScheduleItemInvalid(scheduleItem, propertyInfos) || terms.some(term => isTermInvalid(term))
    })
    if (!_.isNil(invalidScheduleItem)) {
        return true
    }
    return false
}

function isCoverageInvalid(coverage) {
    if (coverage.required && !coverage.selected) {
        return true;
    }
    if (coverage.hasTerms) {
        const invalidTerm = _.find(coverage.terms, (term) => {
            return isTermInvalid(term)
        });
        if (!_.isEmpty(invalidTerm)) {
            return true;
        }
    }

    if (!_.isNil(coverage.schedule)) {
        if (isScheduleInvalid(coverage.schedule)) {
            return true
        }
    }
    return false;
}

function isOpenedScheduleOrStructureInValid(openedItemNumber, coverage) {
    if(_.isNil(openedItemNumber)) {
        return true
    }
    const opendedSchedule = _.get(coverage, 'schedule.scheduleItems', [])
        .find((item) => _.get(item, 'itemNumber') === openedItemNumber);
    
    const propertyInfos = _.get(coverage, 'schedule.propertyInfos');
    return isScheduleItemInvalid(opendedSchedule, propertyInfos)
}

function hasInvalidCoverage(coverages) {
    return _.find(coverages, (cov) => {
        return isCoverageInvalid(cov)
    });
}


export default {
    setValueToCoverages,
    setValueToCoverage,
    setClauseValue,
    // setCoverageFormClauseValue,
    generateUpdatedRiskDetailsDTO,

    isClauseToUpdateEmpty,
    selectCoveragesBySelectedCoverageCodes,
    getValuePathByVMPath,
    // shouldImmediatelySyncAfterValueChange,
    hasOneOptionAndNullTermInCoverages,
    setValueToOneOptionAndNullTermToCoverages,
    isCoverageInvalid,
    hasInvalidCoverage,

    getScheduleItemDataBasePath,
    getScheduleItemDataRelativePath,
    isOpenedScheduleOrStructureInValid,
    isTermInvalid,
    isScheduleInvalid,
    isScheduledItemDataInvalid,
    isScheduleItemFormInvalid
};
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WniPolicyService {
    static getPortalDocumentsForPolicy(policyNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPortalDocumentsForPolicy',
            [policyNumber], additionalHeaders);
    }

    static getPortalDocumentsForPolicyGroupByType(policyNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPortalDocumentsForPolicyGroupByType',
            [policyNumber], additionalHeaders);
    }

    static createNewPolicyCancellationTransaction(
        policyNumber,
        cancellation,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'createPolicyCancellationTransaction',
            [policyNumber, cancellation],
            additionalHeaders
        );
    }

    static quotePolicyCancellationTransaction(
        policyNumber,
        cancellation,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'quotePolicyCancellationTransaction',
            [policyNumber, cancellation],
            additionalHeaders
        );
    }

    static editPolicyCancellationTransaction(
        policyNumber,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'editPolicyCancellationTransaction',
            [policyNumber],
            additionalHeaders
        );
    }

    static canStartCancellation(
        policyNumber,
        effectiveDate,
        tempCancellation,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'canStartCancellation',
            [policyNumber, effectiveDate, tempCancellation],
            additionalHeaders
        );
    }

    static hasLossHistory(policyNumber, effectiveDate, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'hasLossHistory', [policyNumber, effectiveDate], additionalHeaders);
    }

    static getPortalDisplayedPolicyTransactions(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPortalDisplayedPolicyTransactions', [policyNumber], additionalHeaders);
    }

    static referredToUnderWriter(params, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'referredToUnderWriter', params, additionalHeaders);
    }

    static copySubmission(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'copySubmission', data, additionalHeaders);
    }

    static getJobSummariesFromJobNum(jobNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getJobSummariesFromJobNum', [jobNumber], additionalHeaders);
    }

    static getRecentlyLimitedIssuedPolicies(numberOfDays, pageSize, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getRecentlyLimitedIssuedPolicies', [numberOfDays, pageSize], additionalHeaders);
    }

    static getPolicySummaryFromPolicyNumber(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPolicySummaryFromPolicyNumber', [policyNumber.trim()], additionalHeaders);
    }

    static getJobSummaryFromJobNum(jobNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getJobSummaryFromJobNum', [jobNumber.trim()], additionalHeaders);
    }

    static getPolicySummariesFromAccountName(accountName, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPolicySummariesFromAccountName', [accountName], additionalHeaders);
    }

    static getJobSummariesFromAccountName(accountName, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getJobSummariesFromAccountName', [accountName], additionalHeaders);
    }

    static getRecentlyViewedIssuedPolicies(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getRecentlyViewedIssuedPolicies', [], additionalHeaders);
    }

    static addRecentlyViewedPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'addRecentlyViewedPolicy', [policyNumber], additionalHeaders);
    }

    static getAttachmentsForPolicy(policyNumber, isCL = false, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getAttachmentsForPolicy', [policyNumber, isCL], additionalHeaders);
    }
    
    /**
     * get CL documents for Policy
     * 
     * @param {String} policyNumber 
     * @param {Object} additionalHeaders 
     * @returns 
     */
    static getCLPortalDocumentsForPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getCLPortalDocumentsForPolicy', [policyNumber], additionalHeaders);
    }
    
    static getPolicyClaims(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPolicyClaims', [policyNumber], additionalHeaders);
    }

    /**
     * get documents and attachments for given policyNumber
     * 
     * @param {String} policyNumber 
     * @param {Boolean} isCL
     * @param {Object} additionalHeaders 
     * @returns 
     */
    static getPortalDocAndAttachmentsForPolicy(policyNumber, isCL, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPortalDocAndAttachmentsForPolicy', [policyNumber, isCL], additionalHeaders);
    }

    /**
     * retrieve Policy Billing Summary Info from BC
     * 
     * @param {String} policyNumber 
     * @param {Object} additionalHeaders 
     * @returns 
     */
    static retrieveBCPaymentScheduleData(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniPolicy'),
            'retrieveBCPaymentScheduleData', [policyNumber], additionalHeaders);
    }

    /**
     * get policy audit details 
     * 
     * @param {String} policyNumber 
     * @param {Object} additionalHeaders 
     * @returns 
     */
    static getAuditDetailsForPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getAuditDetailsForPolicy', [policyNumber], additionalHeaders);
    }

    static getPortalCLDocumentsForPolicyGroupByType(policyNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPortalCLDocumentsForPolicyGroupByType',
            [policyNumber], additionalHeaders);
    }
}

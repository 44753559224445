import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imbuildersrisk'), method, data, additionalHeaders);
}

export default class IMBuildersRiskService {

    /**
     * Invokes IMBuildersRiskHandler.getBuildersRiskClausesWithSync()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getBuildersRiskClausesWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getBuildersRiskClausesWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMBuildersRiskHandler.getBuildersRiskClauses()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getBuildersRiskClauses(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getBuildersRiskClauses', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMBuildersRiskHandler.updateBuildersRiskClauses()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} buildersRiskToUpdate
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateBuildersRiskClauses(jobNumber, sessionUUID, buildersRiskToUpdate, authHeader = {}) {
        return processSubmission('updateBuildersRiskClauses', [jobNumber, sessionUUID, buildersRiskToUpdate], authHeader);
    }

    /**
     * Invokes IMBuildersRiskHandler.updateCoveragePartSchedule()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} scheduleDto
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateCoveragePartSchedule(jobNumber, sessionUUID, scheduleDto, authHeader = {}) {
        return processSubmission('updateCoveragePartSchedule', [jobNumber, sessionUUID, scheduleDto], authHeader);
    }

    /**
     * Invokes IMBuildersRiskHandler.onBuildersRiskNext()
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static onBuildersRiskNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onBuildersRiskNext', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMBuildersRiskHandler.searchClauses()
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {string} keyword
     * @param {string} searchType
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static searchClauses(jobID, sessionUUID, keyword, searchType, authHeader = {}) {
        return processSubmission('searchClauses', [jobID, sessionUUID, keyword, searchType], authHeader);
    }

    /**
     * Invokes IMBuildersRiskHandler.addSearchedClauses()
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {string} clausesToAddData
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static addSearchedClauses(jobID, sessionUUID, clausesToAddData, authHeader = {}) {
        return processSubmission('addSearchedClauses', [jobID, sessionUUID, clausesToAddData], authHeader);
    }

    /**
     * Invokes IMBuildersRiskHandler.calculateLimit()
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {string} clauseCode
     * @param {string} termCode
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static calculateLimit(jobID, sessionUUID, clauseCode, termCode, authHeader = {}) {
        return processSubmission('calculateLimit', [jobID, sessionUUID, clauseCode, termCode], authHeader);
    }
}

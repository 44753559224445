/**
*Extracted from gw-capability-gateway-react-ext/Components/DocumentsComponent/DocumentsComponent.jsx
 */
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { error } from '@jutro/logger';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { Icon } from '@jutro/components';
import { useWniModal } from 'wni-components-platform-react';
import { UserService } from 'gw-capability-gateway';
import { useTranslator } from '@jutro/locale';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WniDateUtil } from 'wni-portals-util-js';
import {
    BreakpointTrackerContext
} from '@jutro/layout';
import messages from './DocumentsComponent.messages';
import metadata from './DocumentsComponent.metadata.json5';
import styles from './DocumentsComponent.module.scss';
import UploadAttachmentModal from './modal/UploadAttachmentModal';


import { IconButton, CheckboxField } from '@jutro/legacy/components';


import { Link } from '@jutro/router';


const DocumentsComponent = (props) => {
    const modalApi = useWniModal();
    const {
        // authHeader,
        initialDocumentsData,
        uploadDocument,
        deleteDocument,
        downloadDocument,
        noDataMessage,
        showPagination,
        showHeader,
        productCode,
        onReject,
        submissionVM: DocumentsVM,
        repopupDocumentsPage,
        showFilterBar,
        documentNameColumnHeader,
        showActionColumn,
        getRelatedFieldFn,
        getDateModifiedFn,
        showTitle,
        showRelatedColumn,
        showAuthorColumn,
        getDocumentTypeVMFn,
        onDemandDocumentDownloadFn,
        printAllActionContent,
        handlePrintAllFn,
        handleSendViaEmailFn,
        overrideTitleStyle,
        pageSizeConfig,
        pageSizeOptions,
        documentNameColumnPath,
        isInPopup
    } = props;

    let {
        viewModelService,
        authHeader,
        breakpoint
    } = props;

    if (_.isNil(viewModelService)) {
        viewModelService = useContext(ViewModelServiceContext);
    }
    if (_.isNil(authHeader)) {
        authHeader = _.get(useAuthentication(), 'authHeader');
    }
    if (_.isNil(breakpoint)) {
        breakpoint = useContext(BreakpointTrackerContext);
    }

    // const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    // const { authHeader } = useAuthentication();

    const [showDocumentUploadButton, updateShowDocumentUploadButton] = useState(
        false
    );
    const [submissionVM, updateSubmissionVM] = useState(DocumentsVM);
    // for popup
    const [popupUploadAttachmentVM, updatepopupUploadAttachmentVM] = useState(
        null
    );
    const [uiProps, updateUIProps] = useState({});
    const [filters, updateFilters] = useState({});
    const [documentTypes, updateDocumentTypes] = useState([]);
    const [tableData, updateTableData] = useState([]);
    const [tableLoaded, updateTableLoaded] = useState(false);
    const [nameSearchValue, updateNameSearchValue] = useState('');
    const [typeSearchValue, updateTypeSearchValue] = useState('All');
    const [selectedDocs, updateSelectedDocs] = useState([]);

    let initConfig = { page: 0 }
    if (pageSizeConfig) {
        initConfig = { page: 0, ...pageSizeConfig}
    }
    const [config, setConfig] = useState(initConfig);

    const createVM = useCallback(
        (model) => {
            try {
                return viewModelService.create(
                    model,
                    'pc',
                    'edge.capabilities.gateway.document.dto.DocumentDTO'
                );
            } catch (error) {
                _.noop()
            }
        },
        [viewModelService]
    );

    const shouldShowDocumentUploadButton = useCallback(async () => {
        const permissionDTO = {
            permission: 'doccreate',
        };
        UserService.hasUserSystemPermission(permissionDTO, authHeader).then(
            (isUserPermission) => {
                updateShowDocumentUploadButton(isUserPermission);
            }
        );
    }, [authHeader]);

    const initDocumentTypes = useCallback((nextSubmissionVM) => {
        const documentTypeAvailableValues = nextSubmissionVM.documentType.aspects.availableValues;
        let documentTypeOptions = documentTypeAvailableValues.map((item) => {
            const availableValuesList = {
                code: item.code,
                name: translator({ id: item.name })
            };
            return availableValuesList;
        });
        documentTypeOptions = _.concat([{code: 'All', name: translator(messages.all)}], documentTypeOptions);
        updateDocumentTypes(documentTypeOptions);
    }, [translator]);

    const generateUIProps = useCallback(async (nextSubmissionVM) => {
        const documentTypeAvailableValues = nextSubmissionVM.documentType.aspects.availableValues;
        const documentTypeOptions = documentTypeAvailableValues.map((item) => {
            const availableValuesList = {
                code: item.code,
                name: translator({ id: item.name })
            };
            return availableValuesList;
        });
        updateDocumentTypes(documentTypeOptions);
        const newUI = {
            id: 'filters',
            type: 'container',
            component: 'fragment',
            content: [
                {
                    component: 'GridLayout',
                    componentProps: {
                        columns: [
                            1,
                            1
                        ]
                    },
                    content: [
                        {
                            id: 'documentTypeFilter',
                            type: 'field',
                            datatype: 'typelist',
                            component: 'DropdownSelect',
                            componentProps: {
                                searchable: true,
                                path: 'documentType',
                                label: 'Document Type',
                                availableValues: documentTypeOptions
                            }
                        }
                    ]
                }
            ]
        };
        updateUIProps(newUI);
    }, [translator]);

    useEffect(() => {
        const displayDocuments = (documentsData) => {
            if (!_.isEmpty(documentsData)) {
                const documents = documentsData.map((documentsInfo) => {
                    const docInfo = _.cloneDeep(documentsInfo);
                    _.set(docInfo, 'dateModified', _.get(documentsInfo, 'dateModified') || _.get(documentsInfo, 'dateCreated_Ext'));
                    return docInfo;
                });
                return documents;
            }
            return documentsData;
        };

        if (showHeader) {
            shouldShowDocumentUploadButton();
        }
        const model = {};
        const nextSubmissionVM = createVM(model);
        updateSubmissionVM(nextSubmissionVM);
        updatepopupUploadAttachmentVM(nextSubmissionVM);
        generateUIProps(nextSubmissionVM);
        updateTableData(displayDocuments(initialDocumentsData));
        updateTableLoaded(true);
        const docTypeVM = getDocumentTypeVMFn === _.noop ? nextSubmissionVM : getDocumentTypeVMFn(model);
        initDocumentTypes(docTypeVM);
    }, [createVM, generateUIProps, initialDocumentsData, initDocumentTypes, shouldShowDocumentUploadButton, showHeader, updateSubmissionVM, getDocumentTypeVMFn]);

    const writeValue = useCallback(
        (value, path) => {
            const nextSubmissionVM = viewModelService.clone(submissionVM);
            _.set(nextSubmissionVM, path, value);
            updateSubmissionVM(nextSubmissionVM);
        },
        [submissionVM, viewModelService]
    );

    const showModal = useCallback(
        (getPopupUploadAttachmentVM) => {
            const componentProps = {
                title: translator(messages.UploadAttachments),
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                size: 'md',
                actionBtnLabel: translator(messages.uploadModalOk),
                cancelBtnLabel: translator(messages.uploadModalCancel),
                uploadAttachmentVM: getPopupUploadAttachmentVM,
                viewModelService: viewModelService,
                breakpoint,
                productCode
            };
            return modalApi.showModal(
                <UploadAttachmentModal {...componentProps} />
            )
        },
        [translator, viewModelService]
    );

    const onUploadDocument = useCallback(
        async(file, vm) => {
            if (file) {
                await uploadDocument(file, vm);
                repopupDocumentsPage()
            }
        },
        [uploadDocument]
    );

    const handleUploadAttachmentModal = useCallback(async () => {
        if (onReject) {
            showModal(popupUploadAttachmentVM).then(async(res) => {
                await onUploadDocument(res.file, res.vm);
            }).catch(() => _.noop).finally(() => {
                repopupDocumentsPage()
            });
        } else {
            showModal(popupUploadAttachmentVM).then(async(res) => {
                await onUploadDocument(res, submissionVM);
            }).catch(() => _.noop).finally(() => {
                repopupDocumentsPage()
            });
        }
    }, [onReject, showModal, popupUploadAttachmentVM,
        onUploadDocument, submissionVM]);

    const handleDocumentDownload = (event, item) => {
        event.preventDefault();
        if (item.docUID) {
            const { publicID, sessionID } = item;
            downloadDocument(publicID, sessionID, item);
        } else if (onDemandDocumentDownloadFn !== _.noop) {
            onDemandDocumentDownloadFn(item);
        } else {
            // do nothing
            error('no docUID, can not download')
            _.noop();
        }
    };

    const onDeleteDocumentIcon = useCallback(
        (e, item) => {
            e.preventDefault();
            const { publicID } = item;
            modalApi.showAlert({
                title: messages.removeDocument,
                message: messages.confirmRemoveDocument,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.uploadModalOk,
                cancelButtonText: messages.uploadModalCancel
            }).then(async (res) => {
                if (res === 'confirm') {
                    await deleteDocument(publicID);
                }
            }).catch(() => {
                _.noop()
            })
        },
        [deleteDocument, onReject]
    );

    const getNameLink = (item, index, property) => {
        const { path } = property;
        if (item.docUID || onDemandDocumentDownloadFn !== _.noop) {
            return (
                <Link
                    href={item[path]}
                    className={styles.documentName}
                    onClick={(e) => handleDocumentDownload(e, item)}
                >
                    <Icon icon="gw-insert-drive-file" className={styles.fileIcon} />
                    {item[path]}
                </Link>
            );
        }
        return (
            <div className={styles.documentName}>
                <Icon icon="gw-insert-drive-file" className={styles.fileIcon} />
                {item[path]}
            </div>
        );
    };

    const getDocumentType = (item, index, property) => {
        const { path } = property;
        const foundDocumentType = documentTypes.find((obj) => obj.code === item[path]);
        const val = _.get(foundDocumentType, 'name');
        return (
            <div>{`${item[path] ? val : ''}`}</div>
        );
    };

    const getDateModified = (item, index, property) => {
        const { path } = property;
        const date = new Date(item[path]);
        const dateVal = WniDateUtil.formatDateWithPattern(date);
        let timeVal = WniDateUtil.formatDateWithPattern(date, 'hh:mm');
        if (date.getHours > 12) {
            timeVal += ' PM';
        } else {
            timeVal += ' AM';
        }
        return (
            <>
                <div>{`${item[path] ? dateVal : ''}`}</div>
                <div>{`${item[path] ? timeVal : ''}`}</div>
            </>
        );
    };

    const getRelatedDataFn = (item) => {
        return `#${item.jobNumber}`
    };

    const getDeleteFn = (item, index) => {
        if (item.canDelete) {
            return (
                <>
                    <IconButton
                        id={`delete${index}`}
                        icon="gw-delete"
                        iconColor="dark"
                        size="medium"
                        onClick={(e) => onDeleteDocumentIcon(e, item)}
                    />
                    <IconButton
                        id={`download${index}`}
                        icon="gw-cloud-download"
                        iconColor="dark"
                        size="medium"
                        onClick={(e) => handleDocumentDownload(e, item)}
                    />
                </>
            );
        }
        return (
            <IconButton
                id={`download${index}`}
                icon="gw-cloud-download"
                iconColor="dark"
                size="medium"
                onClick={(e) => handleDocumentDownload(e, item)}
            />
        );
    };

    const filterTable = (val) => {
        return (row) => row.name.indexOf(val) !== -1;
    };

    const researchData = useCallback((val) => {
        updateFilters(val);
        let data = _.cloneDeep(initialDocumentsData);
        if (val.search) {
            const searchVal = val.search.toLowerCase();
            data = data.filter((item) => {
                let flag = false;
                _.forEach(item, (value) => {
                    const v = value.toString().toLowerCase();
                    if (v.includes(searchVal)) {
                        flag = true;
                    }
                });
                return flag;
            });
        } else {
            _.forEach(val, (value, key) => {
                data = data.filter((item) => {
                    return item[key] === value;
                });
            });
        }
        updateTableData(data);
    }, [initialDocumentsData]);

    const researchAttachmentData = useCallback((val, initData = []) => {
        let data = _.cloneDeep(initData);
        _.forEach(val, (value, key) => {
            data = data.filter((item) => {
                if (key !== 'name') {
                    return item[key] === value;
                }
                const searchVal = val.name.toLowerCase();
                const v = item[key].toString().toLowerCase()
                return v.includes(searchVal);
            });
        });
        updateTableData(data);
        // reset page number to 0 to make sure the data will show
        setConfig({ ...config, page: 0 });
    }, [config]);

    const filterByName = useCallback(_.debounce(async (value) => {
        const searchObj = { name: value};
        if (typeSearchValue && typeSearchValue !== 'All') {
            searchObj.documentType = typeSearchValue
        }
        researchAttachmentData(searchObj, initialDocumentsData);
    }, 800), [initialDocumentsData, researchAttachmentData, typeSearchValue]);

    const onSearchByName = useCallback(async (value) => {
        updateNameSearchValue(value);
        await filterByName(value);
    }, [filterByName]);

    const onSearchByType = useCallback(async (value) => {
        updateTypeSearchValue(value);
        const searchObj = value === 'All' ? {} : { documentType: value};
        if (nameSearchValue) {
            searchObj.name = nameSearchValue
        }
        researchAttachmentData(searchObj, initialDocumentsData);
    }, [researchAttachmentData, initialDocumentsData, nameSearchValue]);

    const documentTablePaginationConfig = pageSizeConfig && pageSizeOptions ? {
        defaultConfig: pageSizeConfig,
        pageSizeOptions : pageSizeOptions
    } : {};

    const overrideProps = {
        documentTableContainer: {
            visible: !noDataMessage || !_.isEmpty(tableData)
        },
        documentTableGrid: {
            data: tableData,
            showPagination: showPagination,
            rowIdPath: "publicID",
            selectionType: "multi",
            onSelectionChange: (rows) => {
                const chosenDocs = _.filter(tableData, (doc) => {
                    return _.includes(rows, _.get(doc, 'publicID'));
                });
                updateSelectedDocs(chosenDocs);
            },
            config: config,
            onConfigChange:setConfig,
            ...documentTablePaginationConfig
        },
        uploadDocumentsId: {
            visible: showDocumentUploadButton,
        },
        noDocumentsDetails: {
            visible: !!noDataMessage && _.isEmpty(tableData),
        },
        noDocumentsText: {
            message: noDataMessage,
        },
        DocumentName: {
            header: documentNameColumnHeader || translator(messages.documentsName),
            filter: filterTable,
            path: documentNameColumnPath
        },
        commonSearchContainer: {
            visible: !showFilterBar
        },
        nameFilter: {
            onValueChange: onSearchByName,
            value: nameSearchValue
        },
        typeFilter: {
            onValueChange: onSearchByType,
            value: typeSearchValue,
            label: translator(messages.filterBy),
            availableValues: documentTypes
        },
        printAllAction: {
            visible: !showFilterBar && !isInPopup,
            content: printAllActionContent || translator(messages.printAll),
            onClick: () => handlePrintAllFn(selectedDocs)
        },
        sendViaEmailAction: {
            visible: !showFilterBar && !isInPopup,
            content: translator(messages.sendViaEmail),
            onClick: () => handleSendViaEmailFn(tableData)
        },
        filterBar: {
            initialFilters: filters,
            onFiltersChange: researchData,
            uiProps: uiProps,
            visible: showFilterBar
        },
        documentTableLoader: {
            loaded: tableLoaded
        },
        documentActions: {
            visible: showActionColumn
        },
        documentsTitleId: {
            visible: showTitle
        },
        relatedData: {
            visible: showRelatedColumn
        },
        author: {
            visible: showAuthorColumn
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getNameLink: getNameLink,
            onDeleteDocumentIcon: onDeleteDocumentIcon,
            onUploadDocument: onUploadDocument,
            handleUploadAttachmentModal: handleUploadAttachmentModal,
            getRelatedDataFn: getRelatedFieldFn === _.noop ? getRelatedDataFn : getRelatedFieldFn,
            getDeleteFn: getDeleteFn,
            getDocumentType,
            getDateModified: getDateModifiedFn === _.noop ? getDateModified : getDateModifiedFn,
            sortString: DatatableUtil.sortString,
            sortDate: DatatableUtil.sortDate
        },
        resolveComponentMap: {},
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
            onValueChange={writeValue}
        />
    );
};

DocumentsComponent.propTypes = {
    // authHeader: PropTypes.shape({
    //     Authorization: PropTypes.string,
    // }).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    initialDocumentsData: PropTypes.array.isRequired,
    uploadDocument: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    downloadDocument: PropTypes.func.isRequired,
    noDataMessage: PropTypes.string,
    showPagination: PropTypes.bool,
    showHeader: PropTypes.bool,
    onReject: PropTypes.func,
    submissionVM: PropTypes.shape({}),
    repopupDocumentsPage: PropTypes.func,
    showFilterBar: PropTypes.bool,
    documentNameColumnHeader: PropTypes.shape({}),
    showActionColumn: PropTypes.bool,
    getRelatedFieldFn: PropTypes.func,
    getDateModifiedFn: PropTypes.func,
    showTitle: PropTypes.bool,
    showRelatedColumn: PropTypes.bool,
    showAuthorColumn: PropTypes.bool,
    getDocumentTypeVMFn: PropTypes.func,
    printAllActionContent: PropTypes.string,
    handlePrintAllFn: PropTypes.func,
    handleSendViaEmailFn: PropTypes.func,
    overrideTitleStyle: PropTypes.bool,
    pageSizeConfig: PropTypes.shape({}),
    pageSizeOptions: PropTypes.array,
    documentNameColumnPath: PropTypes.string,
    isInPopup: PropTypes.bool
};

DocumentsComponent.defaultProps = {
    showPagination: true,
    noDataMessage: '',
    showHeader: true,
    onReject: _.noop,
    submissionVM: {},
    repopupDocumentsPage: _.noop,
    showFilterBar: true,
    documentNameColumnHeader: '',
    showActionColumn: true,
    getRelatedFieldFn: _.noop,
    getDateModifiedFn: _.noop,
    showTitle: true,
    showRelatedColumn: true,
    showAuthorColumn: false,
    getDocumentTypeVMFn: _.noop,
    onDemandDocumentDownloadFn: _.noop,
    printAllActionContent: '',
    handlePrintAllFn: _.noop,
    handleSendViaEmailFn: _.noop,
    overrideTitleStyle: false,
    pageSizeConfig: null,
    pageSizeOptions: [],
    documentNameColumnPath: 'name',
    isInPopup: false
};

export default DocumentsComponent;

const tableData = `
    const tableData = [
        {
            vehicleType: 'PrivatePassenger',
            effectiveDate: {
                year: 2024,
                month: 1,
                day: 20
            },
            premium: {
                amount: 23598,
                currency: 'USD'
            },
            phoneNumber: '2011234123',
            number: 123.234,
            integer: 23.43
        },
        {
            vehicleType: 'Truck',
            effectiveDate: {
                year: 2025,
                month: 1,
                day: 1
            },
            premium: {
                amount: 2256598,
                currency: 'USD'
            },
            phoneNumber: '2011234145',
            number: 34123.234,
            integer: 234.43,
            unselectable: true
        },
        {
            vehicleType: 'SpecialType',
            effectiveDate: {
                year: 2025,
                month: 1,
                day: 1
            },
            premium: {
                amount: 12359482,
                currency: 'USD'
            },
            phoneNumber: '2011234123',
            number: 123.234,
            integer: 23.43
        }
    ]

    const dataType = {
        "typelist":  'should be used together with "typeKey" ',
        "date",
        "currency",
        "tel"
    };
    const fieldDatatype = {
        "boolean": "ToggleField",
        "checkbox": "CheckboxField",
        "currency": "CurrencyField",
        "number": "InputNumberField",
        "integer": "InputNumberField",
        "radio": "RadioButtonField",
        "select": "DropdownSelectField",
        "string": "InputField",
        "tel": "PhoneNumberField",
        "text": "InputField",
        "textarea": "TextAreaField",
        "typelist": "DropdownSelectField",
        "date": "DateField",
        "year": "YearField",
        "yesno": "ToggleField"
    }
`

const baseTableCode = {
    jsx: `

    import { WniDataTable } from 'wni-common-base-components';

    <WniDataTable
        showPagination={false}
        showSearch={false}
        data={tableData}
        noDataText={{
            "id": "quoteandbind.views.ca.vehicle details.No Placeholder",
            "defaultMessage": "There is no data now. Please add one."
        }}
    />

    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Product"
        typeKey="typekey.CA7VehicleType"
        dataType="typelist"
        path="vehicleType"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Effective Date"
        path="effectiveDate"
        dataType="date"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Toatl Premium"
        path="premium"
        dataType="currency"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Phone Number"
        path="phoneNumber"
        dataType="tel"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Number"
        path="number"
        fieldDatatype="number"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Integer"
        path="integer"
        fieldDatatype="integer"
    />
    `,
    metadata: `
    {
        "id": "baseTable",
        "type": "container",
        "component": "WniDataTable",
        "componentProps": {
            "showPagination": false,
            "showSearch": false,
            "noDataText": {
                "id": "quoteandbind.views.ca.vehicle details.No Placeholder",
                "defaultMessage": "There is no data now. Please add one."
            }
        },
        "content": [
            {
                "id": "typelist",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Product",
                    "typeKey": "typekey.CA7VehicleType",
                    "dataType": "typelist",
                    "path": "vehicleType"
                }
            },
            {
                "id": "date",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Effective Date",
                    "path": "effectiveDate",
                    "dataType": "date"
                }
            },
            {
                "id": "premium",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Toatl Premium",
                    "path": "premium",
                    "dataType": "currency"
                }
            },
            {
                "id": "tel",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Phone Number",
                    "path": "phoneNumber",
                    "dataType": "tel"
                }
            },
            {
                "id": "number",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Number",
                    "path": "number",
                    "fieldDatatype": "number"
                }
            },
            {
                "id": "integer",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Integer",
                    "path": "integer",
                    "fieldDatatype": "integer"
                }
            }
        ]
    },
    `,
    tableData: tableData
};

const multiTableCode = {
    jsx: `

    import { WniDataTable } from 'wni-common-base-components';

    <WniDataTable
        showPagination={false}
        showSearch={false}
        data={tableData}
        selectionType="multi",
        noDataText={{
            "id": "quoteandbind.views.ca.vehicle details.No Placeholder",
            "defaultMessage": "There is no data now. Please add one."
        }}
    />

    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Product"
        typeKey="typekey.CA7VehicleType"
        dataType="typelist"
        path="vehicleType"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Effective Date"
        path="effectiveDate"
        dataType="date"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Toatl Premium"
        path="premium"
        dataType="currency"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Phone Number"
        path="phoneNumber"
        dataType="tel"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Number"
        path="number"
        fieldDatatype="number"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Integer"
        path="integer"
        fieldDatatype="integer"
    />
    `,
    metadata: `
    {
        "id": "multiTable",
        "type": "container",
        "component": "WniDataTable",
        "componentProps": {
            "showPagination": false,
            "showSearch": false,
            "selectionType": "multi",
            "noDataText": {
                "id": "quoteandbind.views.ca.vehicle details.No Placeholder",
                "defaultMessage": "There is no data now. Please add one."
            }
        },
        "content": [
            {
                "id": "typelist",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Product",
                    "typeKey": "typekey.CA7VehicleType",
                    "dataType": "typelist",
                    "path": "vehicleType"
                }
            },
            {
                "id": "date",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Effective Date",
                    "path": "effectiveDate",
                    "dataType": "date"
                }
            },
            {
                "id": "premium",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Toatl Premium",
                    "path": "premium",
                    "dataType": "currency"
                }
            },
            {
                "id": "tel",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Phone Number",
                    "path": "phoneNumber",
                    "dataType": "tel"
                }
            },
            {
                "id": "number",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Number",
                    "path": "number",
                    "fieldDatatype": "number"
                }
            },
            {
                "id": "integer",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Integer",
                    "path": "integer",
                    "fieldDatatype": "integer"
                }
            }
        ]
    },
    `,
    tableData: tableData
};

const selectedAllTableCode = {
    jsx: `

    import { WniDataTable } from 'wni-common-base-components';

    <WniDataTable
        showPagination={false}
        showSearch={false}
        selectionType="multi"
        data={tableData}
        showSelectedAll={false}
        noDataText={{
            "id": "quoteandbind.views.ca.vehicle details.No Placeholder",
            "defaultMessage": "There is no data now. Please add one."
        }}
    />

    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Product"
        typeKey="typekey.CA7VehicleType"
        dataType="typelist"
        path="vehicleType"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Effective Date"
        path="effectiveDate"
        dataType="date"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Toatl Premium"
        path="premium"
        dataType="currency"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Phone Number"
        path="phoneNumber"
        dataType="tel"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Number"
        path="number"
        fieldDatatype="number"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Integer"
        path="integer"
        fieldDatatype="integer"
    />
    `,
    metadata: `
    {
        "id": "selectedAllTable",
        "type": "container",
        "component": "WniDataTable",
        "componentProps": {
            "showPagination": false,
            "showSearch": false,
            "selectionType": "multi",
            "showSelectedAll": false,
            "noDataText": {
                "id": "quoteandbind.views.ca.vehicle details.No Placeholder",
                "defaultMessage": "There is no data now. Please add one."
            }
        },
        "content": [
            {
                "id": "typelist",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Product",
                    "typeKey": "typekey.CA7VehicleType",
                    "dataType": "typelist",
                    "path": "vehicleType"
                }
            },
            {
                "id": "date",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Effective Date",
                    "path": "effectiveDate",
                    "dataType": "date"
                }
            },
            {
                "id": "premium",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Toatl Premium",
                    "path": "premium",
                    "dataType": "currency"
                }
            },
            {
                "id": "tel",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Phone Number",
                    "path": "phoneNumber",
                    "dataType": "tel"
                }
            },
            {
                "id": "number",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Number",
                    "path": "number",
                    "fieldDatatype": "number"
                }
            },
            {
                "id": "integer",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Integer",
                    "path": "integer",
                    "fieldDatatype": "integer"
                }
            }
        ]
    },
    `,
    tableData: tableData
};
const singleTableCode = {
    jsx: `

    import { WniDataTable } from 'wni-common-base-components';

    <WniDataTable
        showPagination={false}
        showSearch={false}
        data={tableData}
        selectionType="single"
        noDataText={{
            "id": "quoteandbind.views.ca.vehicle details.No Placeholder",
            "defaultMessage": "There is no data now. Please add one."
        }}
    />

    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Product"
        typeKey="typekey.CA7VehicleType"
        dataType="typelist"
        path="vehicleType"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Effective Date"
        path="effectiveDate"
        dataType="date"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Toatl Premium"
        path="premium"
        dataType="currency"
        showOptional={true}
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Phone Number"
        path="phoneNumber"
        dataType="tel"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Number"
        path="number"
        fieldDatatype="number"
    />
    <DisplayColumn
        columnProportion=1
        textAlign="left"
        header="Integer"
        path="integer"
        fieldDatatype="integer"
    />
    `,
    metadata: `
    {
        "id": "singleTable",
        "type": "container",
        "component": "WniDataTable",
        "componentProps": {
            "showPagination": false,
            "showSearch": false,
            "selectionType": "single",
            "noDataText": {
                "id": "quoteandbind.views.ca.vehicle details.No Placeholder",
                "defaultMessage": "There is no data now. Please add one."
            }
        },
        "content": [
            {
                "id": "typelist",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Product",
                    "typeKey": "typekey.CA7VehicleType",
                    "dataType": "typelist",
                    "path": "vehicleType"
                }
            },
            {
                "id": "date",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Effective Date",
                    "path": "effectiveDate",
                    "dataType": "date"
                }
            },
            {
                "id": "premium",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Toatl Premium",
                    "path": "premium",
                    "dataType": "currency",
                    "showOptional": true
                }
            },
            {
                "id": "tel",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Phone Number",
                    "path": "phoneNumber",
                    "dataType": "tel"
                }
            },
            {
                "id": "number",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Number",
                    "path": "number",
                    "fieldDatatype": "number"
                }
            },
            {
                "id": "integer",
                "type": "element",
                "component": "displaycolumn",
                "componentProps": {
                    "columnProportion": 1,
                    "textAlign": "left",
                    "header": "Integer",
                    "path": "integer",
                    "fieldDatatype": "integer"
                }
            }
        ]
    },
    `,
    tableData: tableData
};

export {
    baseTableCode,
    multiTableCode,
    selectedAllTableCode,
    singleTableCode
};



const baseModalCode = {
    jsx: `
    /***  open First Modal   ***/
    const {
        baseModalConfig: { showBaseModal },
    } = useDependencies(['baseModalConfig']);

    const [modalResult, updateModalResult] = useState();

    const openModal = () => {
        const modalProps = {
            isPopup: true,
            accountNumber: 'A0000001',
        };

        showBaseModal({
            size: 'md',
            title: 'this is wni Base modal',
            component: <ModalTest {...modalProps} />,
        }).then((res) => {
            updateModalResult(res);
        });
    };

    <div>{modalResult}</div>


    /***  ModalTest.jsx   ***/

    import { Button } from '@jutro/components';
    import { Flex } from '@jutro/layout';
    import { useWniModal } from 'wni-components-platform-react';
    import _ from 'lodash';
    import React, { useState } from 'react';
    import SecondModalTest from './SecondModalTest';

    function ModalTest(props){
        const modalApi = useWniModal();
        const {
            isPopup = false,
            accountNumber,
            onResolve = _.noop,
            onReject = _.noop,
        } = props;

        const [modalResult, updateModalResult] = useState();

        const handleConfirm = () => {
            // TODO: this is your function
            onResolve('this is the result return to parent');
        };

        const openSecondModal= () => {
            const componentProps = {
                size: 'lg',
                title: "this is Second Modal",
                model: {}
            };
            modalApi.showModal(<SecondModalTest {...componentProps} />).then((res) => {
                updateModalResult(res);
            }).catch(_.noop);
        }

        return <>
            <div className='wni__ModalBody__modalBody'>
                <h4 className='ph-20' >this is Wni Base Modal</h4>
                <Button onClick={openSecondModal} label="Open Second Modal" />
                <div>{modalResult}</div>
            </div>

            <Flex className="wni__ModalFooter__modalFooter" justifyContent="right" >
                <Button onClick={onReject} variant="secondary" label="Cancel" />
                <Button onClick={handleConfirm} label="Confirm" />
            </Flex>
        </>
    };

    export default ModalTest;


    /***  SecondModalTest.jsx   ***/

    import {
        ModalBody,
        ModalFooter,
        ModalHeader,
        ModalNext,
        Button
    } from '@jutro/components';
    import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
    import React from 'react';
    import metadata from './SecondModalTest.metadata.json5';

    function SecondModalTest(props) {
        const {
            size,
            actionBtnLabel,
            cancelBtnLabel,
            isOpen,
            onResolve,
            onReject,
            title,
            useAuthenticationData,
            useDependenciesData,
            useBusinessData,
            viewModelService,
            breakpoint,
            translator,
        } = props;

        const { interactionModel, loadingMask, workflowType, applicationCache } = useDependenciesData;

        const handleSave = () => {
            // TODO do your function
            onResolve('return the second modal result');
        };

        const overrideProps = {
            '@field': {
                showOptional: false,
                labelPosition: 'left',
                showRequired: true,
            },
        };
        const resolvers = {
            resolveCallbackMap: {},
            resolveComponentMap: {},
        };

        return (
            <ModalNext isOpen={isOpen} className={size}>
                <ModalHeader title={title} onClose={onReject} />
                <ModalBody id="modalTest">
                    <ViewModelForm
                        uiProps={metadata.componentContent}
                        overrideProps={overrideProps}
                        model={{}}
                        callbackMap={resolvers.resolveCallbackMap}
                        componentMap={resolvers.resolveComponentMap}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onReject} variant="secondary" label='Cancel'/>
                    <Button onClick={handleSave} label="Confirm" />
                </ModalFooter>
            </ModalNext>
        );
    }

    SecondModalTest.propTypes = {};
    SecondModalTest.defaultProps = {
        size: 'md',
    };
    export default SecondModalTest;

    `
}

export {
    baseModalCode
}
/* eslint-disable no-console */

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniAccountService, WniPolicyService } from 'wni-capability-gateway';
import { SelectProducerCode } from 'gw-gateway-common-react';
import { useProductsData } from 'wni-portals-util-react';
import { PolicyPageAdvanceFilter } from 'wni-capability-gateway-react';
import { BannerMessageComponent } from 'wni-capability-common-react';
import metadata from './Policies.metadata.json5';

const PolicyPage = (props) => {
    const [selectedProducerCode, updateSelectedProducerCode] = useState();
    const [isInitLoading, setInitLoading] = useState(true);
    const [recentlyViewedPolicies, updateRecentlyViewedPolicies] = useState([]);
    const [accountData, updateAccountData] = useState({});
    const [policyData, updatePolicyData] = useState({});

    const {
        authHeader
     } = useAuthentication();
    const {
        interactionModel,
        loadingMask: { setLoadingMask },
    } = useDependencies(['interactionModel', 'loadingMask']);

    const { isChangeCancelEnable } = useProductsData();

    const filterData = (data = []) => {
        return data.filter((item) => isChangeCancelEnable(_.get(item, 'product.productCode'), item.policyState_Ext))
    };

    const filterAccountData = (jobResponseData = []) => {
        const responseData = jobResponseData[0] || {};
        const {
            openPolicyChanges = [],
            openCancellations = [],
            boundRewrites_Ext: boundRewrites = [],
            openRenewals = [],
        } = responseData;
        return [{
            ...responseData,
            openPolicyChanges: filterData(openPolicyChanges),
            openCancellations: filterData(openCancellations),
            boundRewrites_Ext: filterData(boundRewrites),
            openRenewals: filterData(openRenewals)
        }]

    }

    const getResponseRecentlyIssuedPolicies = async () => {
        setLoadingMask(true);
        const recentlyViewedResponse = await WniPolicyService.getRecentlyViewedIssuedPolicies(authHeader); 
        
        updateRecentlyViewedPolicies(recentlyViewedResponse);
        const currentJobsResponse = await WniAccountService.getRecentlyViewedJobsForCurrentUser(authHeader); 
        const newCurrentJobsData = filterAccountData(currentJobsResponse);
        updateAccountData(newCurrentJobsData);

        updatePolicyData({
            recentlyViewed: recentlyViewedResponse,
        });
        setLoadingMask(false);
        setInitLoading(false);
    };

    useEffect(() => {
        getResponseRecentlyIssuedPolicies()
    }, []);

    const handleSelectProducerChange = (producerCodeParam) => {
        updateSelectedProducerCode(producerCodeParam);
    };
    //-------------------------------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            showOptional: false,
            autoComplete: false
        },
        advanceFilterSection: {
            policyData: recentlyViewedPolicies,
            accountData: accountData,
            selectedProducerCode,
            isInitLoading,
            ...props
        }
        
    };

    const resolvers = {
        resolveCallbackMap: {
            handleValueChange: handleSelectProducerChange,
        },
        resolveComponentMap: {
          selectproducercode: SelectProducerCode,
          policyadvancefilter: PolicyPageAdvanceFilter,      
          bannermessagecomponent: BannerMessageComponent
        }
    };
    return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrideProps} {...resolvers} />
};

export default withRouter(PolicyPage);

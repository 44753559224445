import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { Icon } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { ConfigUtil } from 'wni-portals-util-js';
import metadata from './GenLiabDetails.metadata.json5';
import {  generateFieldsVisible, isFieldVisible, VALIDATION_ICON_MAP } from '../../../CUUnderlyingPage.util';
import GLExposureDetails from './GLExposureDetails/GLExposureDetails';
import messages from '../../../CUUnderlyingPage.messages';

const exposurePath = 'glExposures';

function GenLiabDetails(props) {
    const {
        currentRow,
        writeValue: onValueChange,
        syncWizardData,
        findItemVMValid,
        handleValidation,
        onValidate,
        showErrors,
        isReadOnly,
    } = props;

    const {
        glExpTypes = []
    } = currentRow.value;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);

    const [initExposureData, updateInitExposureData] = useState([]);
    const [exposureVM, updateExposureVM] = useState(null);
    const [selection, updateSelection] = useState([]);
    const currentRowIdPath = _.get(currentRow, 'rowIdPath.value');
    const highlightRowFn = (vm) => {
        WniTableRowUtil.setTablePublicIDSelected(_.get(vm, 'rowIdPath.value'), 'exposureTable');
    };

    useEffect(() => {
        const newCurrentRow = viewModelService.clone(currentRow);
        const initData = _.get(newCurrentRow.value, exposurePath);

        updateInitExposureData(_.cloneDeep(initData));
    }, [currentRowIdPath]);

    useEffect(() => {
        highlightRowFn(exposureVM);
    }, [exposureVM]);

    const getAvailableValues = (path) => {
        const options = _.get(currentRow,  `${path}.aspects.availableValues`) || []
        return options.map((item) => {
            return {
                code: item.code,
                name: translator({ id: item.name })
            }
        });
    };

    const syncCurrentRow = (vm) => {
        const rowIdPath = _.get(vm.value, 'rowIdPath');
        const allData = _.get(currentRow.value, exposurePath, []);
        const index = allData.findIndex((item) => item.rowIdPath === rowIdPath); 
        _.set(currentRow.value, `${exposurePath}[${index}]`, vm.value);
        syncWizardData(currentRow);
        return currentRow;
    };

    const isVisible = (path) => {
        return isFieldVisible(currentRow, path);
    };

    const viewOrEdit = (item, index) => {
        if(!index) {
            return false;
        }
        const childrenVM = _.get(currentRow, `${exposurePath}.children`, []);
        const currIndex = childrenVM.findIndex((itemVM) => _.get(itemVM, 'rowIdPath.value') === index);
        const currentVM = childrenVM[currIndex];
        _.set(currentVM.value, 'isUpdate', true);
        updateExposureVM(currentVM);
        updateSelection([]);
    };

    const addExposure = () => {
        const childrenVM = _.get(currentRow, exposurePath);
        const { _xCenter, _dtoName } = _.get(currentRow, exposurePath);
        const initValues = {
            rowIdPath: ConfigUtil.getUuid(),
            isUpdate: true
        };
        const newVm = viewModelService.create(initValues, _xCenter, _dtoName);
        const data = _.get(childrenVM, 'value', []);
        const exposureData = [...data, newVm.value];
        updateExposureVM(newVm);
        _.set(currentRow.value, exposurePath, exposureData);
        syncWizardData(currentRow);
        updateSelection([]);
    };

    const removeExposure = () => {
        const newCurrentRow = viewModelService.clone(currentRow);
        const allData = _.get(newCurrentRow.value, exposurePath, []);
        const newData = _.filter(allData, (v) => !_.includes(selection, v.rowIdPath));
        _.set(newCurrentRow, exposurePath, newData);
        syncWizardData(newCurrentRow);
        updateSelection([]);
    };
    
    const cancelExposure = () => {
        updateExposureVM(null);
        if(isReadOnly) {
            return false
        }
        const rowIdPath = _.get(exposureVM.value, 'rowIdPath');
        const allData = _.get(currentRow.value, exposurePath, []);
        const currIndex = _.findIndex(allData, ((item) => item.rowIdPath === rowIdPath));
        if(_.get(exposureVM, 'publicId.value')) {
            
            // find the init data about this exposure, and rollback this expsoure when cancel the details
            const initIndex = _.findIndex(initExposureData, ((item) => item.rowIdPath === rowIdPath))
            _.set(currentRow.value, `${exposurePath}[${currIndex}]`, initExposureData[initIndex]);
            syncWizardData(currentRow);
        } else {
            // remove this exposure when cancel the details
            const newData = _.filter(allData, (item) => item.rowIdPath !== rowIdPath);
            _.set(currentRow, exposurePath, newData);
            syncWizardData(currentRow);
        }
    };
    
    const saveExposure = () => {
        if(!_.get(exposureVM, 'aspects.valid') || !_.get(exposureVM, 'aspects.subtreeValid')) {
            handleValidation()
            return false;
        }
        updateExposureVM(null);
    };

    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(currentRow, `${exposurePath}.children`, []);
        const itemVM = childrenVM.find((v) => v.value.rowIdPath === index) || {};
        let type;
        const isItemValid = findItemVMValid(itemVM);
        if(isItemValid) {
            type = 'success';
            
        } else {
            type = 'error';
        }
        return <Icon id={`validationIcon${item.rowIdPath}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
    };

    const writeValue = (value, path) => {
        _.set(exposureVM.value, path, value);
        updateExposureVM(exposureVM);
        syncCurrentRow(exposureVM);
    };

    const overrideProps = {
        '@field': {
            readOnly: isReadOnly,
            showRequired: true,
            shouOptional: false
        },
        glExpTypes: {
            availableValues: getAvailableValues('glExpType'),
        },
        glExposuresSection: {
            visible: !_.isEmpty(glExpTypes)
        },
        tableActions: {
            visible: !isReadOnly
        },
        addExposure: {
            disabled: !!exposureVM
        },
        deleteExposure: {
            disabled: !!exposureVM || _.isEmpty(selection)
        },
        exposureTable: {
            selectionType: isReadOnly ? 'none' : 'multi',
            onSelectionChange: (rows) => updateSelection(rows),
            selectedRows: selection
        },
        glExposureDetailsContainer: {
            visible: !!exposureVM
        },
        glExposureDetails: {
            ...props,
            writeValue,
            exposureVM,
            syncCurrentRow
        },
        viewOrEditLink: {
            label: isReadOnly ? messages.viewLabel : messages.viewAndEditLabel
        },
        saveButton: {
            visible: !isReadOnly
        },
        glOccLimit: {
            visible: isVisible('glOccLimit')
        },
        glAggLimit: {
            visible: isVisible('glAggLimit')
        },
        glProdCompOpsAggLim: {
            visible: isVisible('glProdCompOpsAggLim')
        },
        glPersAdvInj: {
            visible: isVisible('glPersAdvInj')
        },
        // owner and contractors
        glOwnAndConOccLimit: {
            visible: isVisible('glOwnAndConOccLimit')
        },
        glOwnAndConAggLimit: {
            visible: isVisible('glOwnAndConAggLimit')
        },
        // product withdrawal
        glProdWithAggLimit: {
            visible: isVisible('glProdWithAggLimit')
        },
        // railroad
        glRailraodOccLimit: {
            visible: isVisible('glRailraodOccLimit')
        },
        glRailroadAggLimit: {
            visible: isVisible('glRailroadAggLimit')
        },
        ...generateFieldsVisible(currentRow),
       
    };

    const resolvers = {
        callbackMap: {
            viewOrEdit,
            addExposure,
            removeExposure,
            cancelExposure,
            saveExposure,
            renderValidationCell
        },
        componentMap: {
            glExposureDetails: GLExposureDetails
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default GenLiabDetails;


const wniProductCodes = {
    ProductsUtil: `
    import { WniProductsUtil } from 'wni-portals-util-js';
    /**
     * linePattern: Becasure CPP contains one or more products, linePatternCode introduced by PC for products included in CPP
     * lobName: product' lobName. Path for the product data in SubmissionVM
     */

    const {
        PA_PRODUCT_CODE, // ---> product code: 'PersonalAuto' 
        HOP_PRODUCT_CODE, // ---> product code: 'HOPHomeowners' 
        HO_PRODUCT_CODE, // ---> product code: 'Homeowners' 
        DP_PRODUCT_CODE, // ---> product code: 'DwellingProperty' 
        WAL_PRODUCT_CODE, // ---> product code: 'Watercraft' 
        RT_PRODUCT_CODE, // ---> product code: 'RoadTrail' 
        PU_PRODUCT_CODE, // ---> product code: 'PersonalUmbrella' 
        CA_PRODUCT_CODE, // ---> product code: 'CA7CommAuto' 
        WCM_PRODUCT_CODE, // ---> product code: 'WCMWorkersComp' 
        GL_PRODUCT_CODE, // ---> product code: 'GL7GeneralLiability' 
        CP_PRODUCT_CODE, // ---> product code: 'CP7CommercialProperty' 
        IM_PRODUCT_CODE, // ---> product code: 'InlandMarine' 
        CR_PRODUCT_CODE, // ---> product code: 'CR7Crime' 
        CPP_PRODUCT_CODE, // ---> product code: 'CommercialPackage' 
        CU_PRODUCT_CODE, // ---> product code: 'CEL' 

        getAllProductsMap, // ---> All products defined in the Portal

        /**
         * get product icon from productCode
         * @param {string} productCode product code
         * @returns {string} portal product icon
         */
        getProductIcon,

        /** this has been abandoned.
         * get product name from productCode
         * @param {string} productCode product code
         * @returns {string} portal product name
         */
        getProductName,

        /**
         * sort products
         * @param {array} products product code list
         * @returns {array} portal product code list
         */
        getSortProductSelected,

        /**
         * get product short name from productCode. like: GL, CA, PA, CU...
         * @param {string} productCode product code
         * @returns {string} portal product short name
         */
        getProductShortName,

        /**
         * get product quicklink from productCode. wizardSidebar' quick link
         * @param {string} productCode product code
         * @returns {object} portal product wizardSidebar qucik link
         */
        getQuickLink,
        
        /**
         * Determine whether this product belongs to CL
         * @param {string} productCode product code
         * @returns {boolean}
         */
        isCLProduct, // ---> 

        /**
         * get product is available under account. 
         * when creating an account triggers outside match rule, the products under this account will be disabled
         * Underwriter user can control whether to enable product on PC side. (open PC, search account -> Underwriting Files -> Risk Reservation)
         * @param {string} productCode product code
         * @param {array} accountProductAvailableMaps accountProductAvailableMaps
         * @returns {string} portal product short name
         */
        isProductAvailableForAccount, // ---> 

        // for CPP Lines
        policyLinePatternForCPP, // ---> CPP contains product Code and product' linePattern Code and product lobName

        /**
         * get product' linePattern from productCode
         * @param {string} productCode product code
         * @returns {string} portal product' linePattern
         */
        getPolicyLinePattern,

        /**
         * get product name from linePattern code
         * @param {string} linePatternCode
         * @returns {string} portal product name
         */
        getPolicyLinePatternName,

        /**
         * get product' lobName from linePattern code
         * @param {string} linePatternCode
         * @returns {string} portal product' lobName
         */
        getPolicyLobName,

        /**
         * get product' lobName from productCode
         * @param {string} productCode product code
         * @returns {string} portal product' lobName
         */
        getLobName, 

        /**
         * get product' name from product
         * Determine whether the product contains a line pattern.
         * if true, product name needs to be obtained through linePattern Code
         * if false, product name can be obtained through the ProductCode
         * @param {string} productCode product code
         * @returns {string} portal product' linePattern
         */
        getProductNameByLinePattern
    } = WniProductsUtil;
    `,
    AccountsUtil: `
    import { WniAccountsUtil } from "wni-portals-util-js";

    const {
        PERSONAL_ACCOUNT_CODE, // ---> AccountTypeCode: 'person'
        COMMERCIAL_ACCOUNT_CODE, // ---> AccountTypeCode: 'company'
        
        /**
         * get all accounts
         * @returns {array} portal account maps
         */
        getAvailableAccounts,

        /**
         * get account info from contactType
         * @param {string} contactType
         * @returns {object} portal account info (code, name, icon)
         */
        getAccountItem,

        /**
         * get account icon from contactType
         * @param {string} contactType
         * @returns {string} portal account icon
         */
        getAccountIcon,

        /**
         * Determine whether this account belongs to CL
         * @param {string} contactType
         * @returns {boolean}
         */
        isCLAccount,
    } = WniAccountsUtil;
    `
};

const useProductDataCodes = {
    jsx: `
    import { useProductsData } from 'wni-portals-util-react';
    const {
        /**
         * get all available products
         * @returns {array} all available products
         */
        getProductsMap,

        /**
         * get available product information
         * @param {string} productCode product code
         * @returns {object} available product info
         */
        getProductItem,

        /**
         * abandoned
         */
        getProductName,

        /**
         * Determine if the product is enabled
         * @param {string} productCode product code
         * @returns {boolean}
         */
        getProductEnabled,

        /**
         * abandoned
         * don't worry about what you used before. but don't use it again in the future
         */
        getProductEffectiveDate,

        /**
         * abandoned
         * don't worry about what you used before. but don't use it again in the future
         */
        getProductMinDate,
        
        /**
         * get available state for product
         * @param {string} productCode product code
         * @returns {array} available state for product
         */
        getProductAvailableStates,

        /**
         * abandoned
         * don't worry about what you used before. but don't use it again in the future
         */
        getProductStartEffectiveDate,

        // for go live story

        /**
         * get available product by accountType, state
         * @param {string} accountType account code
         * @param {string} state
         * @param {boolean} withCPP deafult is false. if true, will return products contains CPP
         * @returns {array} available products
         */
        getAvailableProducts,

        /**
         * get product effectiveDate by accountType, state
         * @param {string} productCode product code
         * @param {string} baseState product policy state
         * @param {date} AOEffectiveDate if submission from AO with AOEffectiveDate, will compare AOEffectiveDate and select the latest date
         * @returns {date} available effective Date
         */
        getAvailableEffectiveDate,

        /**
         * get available state by productCode, policyEffectiveDate
         * @param {string} productCode product code
         * @param {date} policyEffectiveDate if product effective date already exists, will check which available states are available at this time point
         * @returns {array} available state
         */
        getAvailablePolicyState,

        /**
         * Determine if the product is enabled by baseState
         * @param {string} productCode product code
         * @param {string} baseState
         * @returns {boolean}
         */
        getProductVisibleForState,

        /**
         * Determine if the policy change and cancel policy is enabled by productCode and policyState
         * @param {string} productCode product code
         * @param {string} policyState
         * @returns {boolean}
         */
        isChangeCancelEnable,

        /**
         * abandoned
         * don't worry about what you used before. but don't use it again in the future
         */
        getProductsMinDate
    } = useProductsData();
    `
}




export {
    wniProductCodes,
    useProductDataCodes
}
import _ from 'lodash'

export const getDisplayName = (address) => {
    const addressLine1 = _.get(address, 'addressLine1', _.get(address, 'addressLine1Kanji', ''));
    const addressLine2 = _.get(address, 'addressLine2', _.get(address, 'addressLine2Kanji', ''));
    const addressLine3 = _.get(address, 'addressLine3', _.get(address, 'addressLine3Kanji', ''));
    const city = _.get(address, 'city', _.get(address, 'cityKanji', ''));
    const state = _.get(address, 'state');
    const addressLine1WithComma = addressLine1 ? `${addressLine1},` : '';
    const addressLine2WithComma = addressLine2 ? `${addressLine2},` : '';
    const addressLine3WithComma = addressLine3 ? `${addressLine3},` : '';
    const cityState = [city, state].join(', ');
    const postalCode = _.get(address, 'postalCode', '');
    const dataArray = [addressLine1WithComma, addressLine2WithComma, addressLine3WithComma, cityState, postalCode];
    const content = dataArray.join(' ');

    return content
}

export const getFullAddressDisplayName = (address)=> {
    const AddressLineDisplay = [_.get(address, 'addressLine1'), _.get(address, 'addressLine2'), _.get(address, 'addressLine3')].filter((item) => !_.isNil(item)).join(' ')
    const CityStateZipcodeDisplay = [_.get(address, 'city'), _.get(address, 'state'), _.get(address, 'postalCode')].filter((item) => !_.isNil(item)).join(', ')
    return [AddressLineDisplay, CityStateZipcodeDisplay].filter((item) => !_.isEmpty(item)).join(', ')
}


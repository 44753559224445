
import React, {
    useCallback, useState, useContext,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ServiceManager } from '@jutro/legacy/services';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { TeamTabComponent, useWniModal } from 'wni-components-platform-react';
import { WindowUtil, WizardPageJumpUtil, WniProductsUtil, WizardUtil, WniAccountsUtil } from 'wni-portals-util-js';
import { WniCommonQuoteService } from 'wni-capability-quoteandbind';
import { useProductsData } from 'wni-portals-util-react';
import { CLPolicyHolder } from 'wni-capability-account-commercial-react';
import {
    BreakpointTrackerContext
} from '@jutro/layout';

import appConfig from 'app-config';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { SubmissionService } from 'gw-capability-gateway';
import { WniSubmissionService } from 'wni-capability-gateway';
import DocumentsPage from '../../DocumentPage/DocumentsPage';
import messages from './WizardSidebarExtensionComponent.messages';
import metadata from './WizardSidebarExtensionComponent.metadata.json5';
import styles from './WizardSidebarExtensionComponent.module.scss';
import QuickLinkComponent from '../QuickLink/QuickLinkComponent';

const {
    PA_PRODUCT_CODE,
    HOP_PRODUCT_CODE,
    HO_PRODUCT_CODE,
    DP_PRODUCT_CODE,
    WAL_PRODUCT_CODE,
    RT_PRODUCT_CODE,
    PU_PRODUCT_CODE,
    CA_PRODUCT_CODE,
    WCM_PRODUCT_CODE,
    GL_PRODUCT_CODE,
    CP_PRODUCT_CODE,
    IM_PRODUCT_CODE,
    CPP_PRODUCT_CODE,
    isProductAvailableForAccount,
    productsMap
} = WniProductsUtil;

const ALL_CL_PRODUCTS = _.filter(productsMap, (product) => {
    const { COMMERCIAL_ACCOUNT_CODE } = WniAccountsUtil;
    return _.get(product, 'accountType') === COMMERCIAL_ACCOUNT_CODE;
})

const ALL_CL_PRODUCT_CODES = _.map(ALL_CL_PRODUCTS, (product) => product.code)

const WizardSidebarExtensionComponent = (props) => {
    const modalApi = useWniModal();
    const { quickLinkData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const wizardContext = useContext(WizardContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { getAvailableEffectiveDate, getProductVisibleForState } = useProductsData();
    const localeService = ServiceManager.getService('locale-service');
    const defaultCountryCode = localeService.getDefaultCountryCode();
    const {
        wizardData,
        wizardServiceCallbacks: {
            copySubmission,
        },
        updateWizardPageData,
        wizardPageData,
        currentStepIndex
    } = wizardContext;

    const {
        jobID,
        baseData: {
            accountNumber,
            productCode,
            producerCode_Ext: producerCode,
            producerCodePublicID_Ext: producerCodePublicID,
            baseState_Ext: baseState,
            accountHolder: {
                productAvailable_Ext: productAvailable = []
            } = {}
        },
    } = wizardData.value;

    const { lobQuoteURL } = appConfig;
    const translator = useTranslator();
    const {
        interactionModel,
        loadingMask: { setLoadingMask },
        baseModalConfig: { showBaseModal },
    } = useDependencies(['interactionModel', 'loadingMask', 'baseModalConfig']);

    const { getProductEnabled } = useProductsData();
    const { authHeader } = useAuthentication();
    const history = useHistory();

    const jobType = _.get(wizardData.value, 'baseData.jobType');

    const showUnderwriter = useCallback(() => {
        const underwriterVM = _.get(wizardData, 'baseData.underwriter_Ext');
        const componentProps = {
            title: translator(messages.linkUnderwriter),
            underwriterVM: underwriterVM,
        };
        modalApi.showModal(<TeamTabComponent {...componentProps} />)
            .then(() => _.noop()).catch(() => _.noop());
    }, [translator, wizardData]);

    const showDocument = () => {
        const product = _.get(wizardData, 'value.baseData.productName');
        let fromAccountLanding = null;
        if (jobType === 'PolicyChange') {
            fromAccountLanding = { quoteDetailsData: { jobNumber: jobID } };
        } else {
            fromAccountLanding = {
                quoteDetailsData: {
                    jobNumber: _.get(wizardData, 'value.quoteID') || jobID,
                    loadQuoteSummary: {
                        policy: {
                            product: {
                                productName: product
                            }
                        }
                    }
                }
            };
        }
        const componentProps = {
            title: translator(messages.documents),
            size: 'lg',
            submissionVM: wizardData,
            fromAccountLanding: fromAccountLanding,
            showCancel: 'true',
            breakpoint,
            isInPopup: true,
            isCL: WniProductsUtil.isCLProduct(productCode),
            productCode,
        };
        modalApi.showModal(
            <DocumentsPage {...componentProps} />
        ).then(() => _.noop()).catch(() => _.noop());
    };

    const handleCopySubmission = useCallback(async () => {
        setLoadingMask(true);
        await copySubmission({
            updateWizardPageData,
        });
        // updateWizardPageData({ [WizardConstants.copySubmission]: newSubmission });
        // defined in WizardPageHeader
        WindowUtil.scrollTo('wizardPageHeaderCopiedSubmissionSection');
        setLoadingMask(false);
    }, [copySubmission, updateWizardPageData]);

    const onCopyToSubmissionByWay = async (jobNumber, targetProductCode) => {
        setLoadingMask(true);
        const srcProductShortName = WniProductsUtil.getProductShortName(productCode)
        const targetProductShortName = WniProductsUtil.getProductShortName(targetProductCode)
        const way = `${srcProductShortName}To${targetProductShortName}`
        const newSubmission = await WniSubmissionService.copySubmissionByWay(
            jobNumber,
            way,
            authHeader
        );
        const newProductCode = _.get(newSubmission, 'productCode');
        const postalCode = _.get(
            newSubmission,
            'policy.account.accountHolder.primaryAddress.postalCode'
        );
        if (newSubmission.jobNumber > 0) {
            if (!_.isNil(lobQuoteURL[newProductCode])) {
                await WniSubmissionService.addRecentlyViewedSubmission(newSubmission.jobNumber, authHeader);
                WizardPageJumpUtil.goToNewQuoteWizard(history, {
                    productCode: newProductCode,
                    postalCode,
                    jobID: newSubmission.jobNumber
                });
            } else {
                JobUtil.openJobInXCenter(newSubmission.jobNumber);
            }
        }
        setLoadingMask(false);
    };

    const createHomeOwnerQuote = async () => {
        const quoteID = _.get(wizardData, 'value.quoteID');
        const jobNumber = quoteID || jobID;
        await onCopyToSubmissionByWay(jobNumber, HOP_PRODUCT_CODE);
    };

    const createDwellingPropertyQuote = async () => {
        const quoteID = _.get(wizardData, 'value.quoteID');
        const jobNumber = quoteID || jobID;
        await onCopyToSubmissionByWay(jobNumber, DP_PRODUCT_CODE);
    };

    const startNewCLQuote = () => {
        if(WniProductsUtil.isCLProduct(productCode)){
            showBaseModal({
                size: 'lg',
                title: 'Please Select Products to Quote',
                component: (
                    <CLPolicyHolder
                        className="policyHolderPopup"
                        isPopup
                        accountNumber={accountNumber}
                    />
                )
            }).then((res) => {
            })
        }
    }

    const createNewQuote = useCallback(
        async (newProductCode) => {
            setLoadingMask(true);
            let SubmissionResponse = null;
            if ((productCode === WAL_PRODUCT_CODE || productCode === RT_PRODUCT_CODE) && newProductCode === PA_PRODUCT_CODE) {
                const way = productCode === WAL_PRODUCT_CODE ? 'WALToPA' : 'RTToPA';
                SubmissionResponse = await WniSubmissionService.copySubmissionByWay(
                    jobID,
                    way,
                    authHeader
                );
            } else {
                const effectiveDate = getAvailableEffectiveDate(newProductCode, baseState);

                SubmissionResponse = await SubmissionService
                    .createSubmission({
                        accountNumber: accountNumber,
                        country: defaultCountryCode,
                        effectiveDate: effectiveDate,
                        producerCode: producerCode,
                        producerCodePublicID_Ext: producerCodePublicID,
                        productCode: newProductCode,
                        state: baseState,
                    }, authHeader);
            }
            const postalCode = _.get(
                SubmissionResponse,
                'policy.account.accountHolder.primaryAddress.postalCode'
            );
            if (SubmissionResponse.jobNumber > 0) {
                if (!_.isNil(lobQuoteURL[newProductCode])) {
                    await WniSubmissionService.addRecentlyViewedSubmission(SubmissionResponse.jobNumber, authHeader);
                    WizardPageJumpUtil.goToNewQuoteWizard(history, {
                        productCode: newProductCode,
                        postalCode,
                        jobID: SubmissionResponse.jobNumber
                    });
                } else {
                    JobUtil.openJobInXCenter(SubmissionResponse.jobNumber);
                }
            }
            setLoadingMask(false);
        },
        [
            setLoadingMask,
            productCode,
            jobID,
            authHeader,
            getAvailableEffectiveDate,
            accountNumber,
            defaultCountryCode,
            producerCode,
            producerCodePublicID,
            baseState,
            lobQuoteURL,
            history
        ]
    );

    const viewCoverageForms = () => {
        // To save wizard exit data when leave PE window
        const { jobID: quoteIDParam, sessionUUID } = wizardData.value;
        const wizardExitData = WizardUtil.getQuoteWizardExitData(
            wizardPageData,
            { currentStepIndex }
        );
        const updateResult = WniCommonQuoteService.saveWizardExitData(
            quoteIDParam,
            sessionUUID,
            wizardExitData,
            authHeader
        );
        if (WniProductsUtil.isCLProduct(productCode)) {
            interactionModel.goToPage(null, history, 'formCommercial');
        } else {
            interactionModel.goToPage(null, history, 'formPersonal');
        }
    };

    const overrideProps = {
        '@action': {
            quickLinkData
        },
        '@all': {
            tabIndex: -1
        },
        createAutoQuote: {
            visible: getProductEnabled(PA_PRODUCT_CODE),
            onClick: () => createNewQuote(PA_PRODUCT_CODE)
        },
        createHomeOwnerQuoteBtn: {
            visible: getProductEnabled(HOP_PRODUCT_CODE)
        },
        createDwellingPropertyQuote: {
            visible: getProductVisibleForState(DP_PRODUCT_CODE, baseState)
        },
        createCAQuote: {
            visible: isProductAvailableForAccount(CA_PRODUCT_CODE, productAvailable),
            onClick: () => createNewQuote(CA_PRODUCT_CODE)
        },
        createWCMQuote: {
            visible: isProductAvailableForAccount(WCM_PRODUCT_CODE, productAvailable),
            onClick: () => createNewQuote(WCM_PRODUCT_CODE)
        },
        copySubmission: {
            visible: !(jobType === 'PolicyChange' && (ALL_CL_PRODUCT_CODES.includes(productCode)))
        },
        startNewQuote: {
            onClick: () => startNewCLQuote()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            showUnderwriter,
            showDocument,
            copySubmission: handleCopySubmission,
            createHomeOwnerQuote,
            createDwellingPropertyQuote,
            viewCoverageForms
        },
        resolveComponentMap: {
            quicklinkcomponent: QuickLinkComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

WizardSidebarExtensionComponent.propTypes = {
    wizardData: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    )
};

WizardSidebarExtensionComponent.defaultProps = {
    wizardData: {}
};

export default WizardSidebarExtensionComponent;

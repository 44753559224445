import React, {
    useContext,
    useCallback,
    useEffect,
    useState, useMemo
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext, getFlattenedUiPropsContent} from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import classNames from 'classnames';
import { HOTooltipUtil as tooltips } from 'wni-portals-tooltip';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WniAccountService } from 'wni-capability-gateway';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { 
    QuoteUtil, 
    WindowUtil, 
    IssuanceValidationUtil,
    WniQuestionSetUtil,
    ValidationIssueUtil,
    WniDateUtil,
} from 'wni-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    AgencyOfServiceComponent,
    PrimaryNamedInsuredInputPopup,
} from 'wni-capability-gateway-react';
import { useWniModal, useFeatureToggle } from 'wni-components-platform-react';
import { WizardConstants } from 'wni-portals-config-js';
import { HOHouseholdInfoService } from 'wni-capability-quoteandbind-ho';
import { WniCommonQuoteService } from 'wni-capability-quoteandbind';
import { useProductsData } from 'wni-portals-util-react';
import WizardPage from '../../templates/HOWizardPage';
import HouseholdResidentsPopup from './component/HouseholdResidentsPopup/HouseholdResidentsPopup';

import styles from './HOHouseholdInfoPage.module.scss';
import metadata from './HOHouseholdInfoPage.metadata.json5';
import messages from './HOHouseholdInfoPage.messages';
import HouseholdLocationPopupComponent from './component/HouseholdLocationPopup/HouseholdLocationPopupComponent';
import { IconButton } from '@jutro/legacy/components';

const DEFAULT_COVERAGE_FORM = ['ho3', 'ho4','ho5','ho6']
function HOHouseholdInfoPage(props) {
    const modalApi = useWniModal();
    const viewModelService = useContext(ViewModelServiceContext);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [householdResidentsTableData, updateHouseholdResidentsTableData] = useState([]);
    const {
        authHeader,
        authUserData,
        authUserData: {
            businessData_Ext: {
                systemDate,
                featureToggles = []
            } = {}
        } = {},
    } = useAuthentication();
    const effectiveDateWarningStyle = classNames('wniKnockoutToolTip');

    const {
        currentStep: { id: currentPageId},
        //
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        updateWizardPageData,
        wizardPageData,
        //
        saveHoHouseholdInfo,
        // householdInfoPageOnInit,
        isOpenPNIPopupPageInit,
        checkRequiredForIssuance,
        setAgentComponentReadOnly,
        setCoverageFormReadOnly,
        setEffectiveDateForChangeTransaction = false,
        maximumEffectiveDate
    } = props;

    const {
        [WizardConstants.externalAgencyOptions]: externalAgencyOptions,
        [WizardConstants.licensedAgentOptions]: licensedAgentOptions,
        [WizardConstants.agentStatus]: agentStatus,
        [WizardConstants.servicingAgentOptions]: servicingAgentOptions,
        [WizardConstants.accountCompletedQuotes]: accountCompletedQuotes = []
    } = wizardPageData;

    const {
        jobID: quoteID, 
        sessionUUID,
        baseData,
        lobData: {
            homeowners: {
                isFeb19EntityChangeVerExt,
                isApr16EntityChangeVerExt,
                householdInfoDTO_Ext: {
                    isPropertyRentedToOthers,
                    coveragesForm,
                    underNewConstructionType,
                    isNamedInsuredContractor,
                    totalNumOfHouseholdResidents,
                    householdResidents,
                    namedInsuredOccupancy,
                    hasLivedInLocationLess3Yrs,
                    isHouseholdInfoFirstEnter
                }
            }
        }
    } = submissionVM.value;
    const {
        productCode,
        baseState_Ext: baseState,
        producerCodePublicID_Ext: producerCodePublicID,
        producerDisplay_Ext: producerCodeDisplayName,
        primaryNamedInsured_Ext: primaryNamedInsured,
        producerOrLicensedAgent_Ext: licensedAgentPublicID,
        servicingAgent_Ext:  servicingAgentPublicID,
        policyInfoSource_Ext: policySource,
        jobType,
    } = baseData;

    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerComponentValidation,
        invalidFields
    } = useValidation('HOHouseholdInfoPage');
    const { getProductMinDate, getProductAvailableStates, getProductItem, getProductStartEffectiveDate } = useProductsData();
    
    const productItems = getProductItem(productCode);
    const [showErrors, updateShowErrors] = useState(false);
    const translator = useTranslator();
    
    const effectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
    const showEffectiveDateKnockout = WniDateUtil.isMoreThanXDaysInFuture(effectiveDate,34);

    const featureToggle = useFeatureToggle();
    const enableHOforArizona = featureToggle.getFeatureCodeValue('EnableHOforArizona');

    const effectiveMinDate = useCallback(() => {
        if (setEffectiveDateForChangeTransaction) {
            return _.get(submissionVM, 'value.baseData.periodStartDate')
        }
        return getProductMinDate(productCode, baseState);
    }, [setEffectiveDateForChangeTransaction, productCode, submissionVM, baseState]);


    const effectiveMaxDate = useCallback(() => {
        const maxEffectiveDateForChangeWithoutBoundChange = moment(_.get(submissionVM, 'value.baseData.periodEndDate')).add(-1, 'days').toDate()
        if (setEffectiveDateForChangeTransaction) {
            const maxDate =  ( moment(maximumEffectiveDate).isBefore(maxEffectiveDateForChangeWithoutBoundChange) ?
                maximumEffectiveDate : WniDateUtil.getDateObj(maxEffectiveDateForChangeWithoutBoundChange) )
            return maxDate
        }
        return undefined

    }, [submissionVM, setEffectiveDateForChangeTransaction, maximumEffectiveDate]);

    const getEffectiveDateFieldOverrideProps = () => {
        const prodDate = getProductStartEffectiveDate(productCode, baseState);
        const currentDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const isProdDateBeforeSysDate = moment(prodDate).valueOf() - moment(systemDate).valueOf() < 0
        let shouldShowNewValidation = false
        if (!isProdDateBeforeSysDate) {
            // current<prod show
            if(moment(currentDate).valueOf() - moment(prodDate).valueOf() < 0) {
                shouldShowNewValidation = true
            }
        }
        const effectiveDateFieldOverrideProps = {
            minDate: effectiveMinDate(),
            maxDate: effectiveMaxDate(),
            value: _.get(submissionVM.value, 'baseData.effectiveDate_Ext'),
            // onValueChange: onStartDateChange,
            showErrors: showErrors || shouldShowNewValidation,
            
        };
        if (shouldShowNewValidation && baseState === 'MN') {
            _.set(effectiveDateFieldOverrideProps, 'validationMessages',
                [translator(messages.effectiveDateValidation, {
                    productEffectiveDate: WniDateUtil.formatDateWithPattern(effectiveMinDate(), 'MM/DD/YYYY'),
                })]);
        }
        if (showEffectiveDateKnockout) {
            _.set(effectiveDateFieldOverrideProps, 'tooltip', showEffectiveDateKnockout)
            _.set(effectiveDateFieldOverrideProps, 'tooltip.title', 'Ineligible')
            _.set(effectiveDateFieldOverrideProps, 'tooltip.icon', 'gw-error')
            _.set(effectiveDateFieldOverrideProps, 'tooltip.showOnInit', showEffectiveDateKnockout)
            _.set(effectiveDateFieldOverrideProps, 'tooltip.text',translator(messages.effectiveDateOver35Days))
            _.set(effectiveDateFieldOverrideProps, 'className', effectiveDateWarningStyle)
        }
        return  effectiveDateFieldOverrideProps;
    };


    const isEffectiveDateValid = useCallback(() => {
        const effectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = (setEffectiveDateForChangeTransaction ? _.get(submissionVM.value, 'baseData.periodStartDate'): effectiveMinDate());
        const maxDate = effectiveMaxDate()
        const minDif = moment(effectiveDate).valueOf() - moment(minDate).valueOf();
        const maxDif = (maxDate === undefined ? 1 : moment(maxDate).valueOf() - moment(effectiveDate).valueOf())
        return ((minDif >= 0) && (maxDif >= 0))
    }, [submissionVM.value, setEffectiveDateForChangeTransaction, effectiveMinDate, effectiveMaxDate]);

    const isResidenceLocationValid = useCallback(() => {
        const dwellingAddressLine1 = _.get(submissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.dwellingLocation.address.addressLine1')
        const dwellingZipCode = _.get(submissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.dwellingLocation.address.postalCode')
        const dwellingState = _.get(submissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.dwellingLocation.address.state')
        const dwellingCountry = _.get(submissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.dwellingLocation.address.country')
        const dwellingCity = _.get(submissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.dwellingLocation.address.city')
        return (dwellingAddressLine1 && dwellingZipCode && dwellingState && dwellingCountry && dwellingCity)
    }, [submissionVM.value]);

    const isHOHouseholdInfoValid = useCallback(() => {
        return isEffectiveDateValid() && isResidenceLocationValid() && (policySource === 'Converted' || totalNumOfHouseholdResidents >= _.get(householdResidentsTableData, 'length'));
    }, [isEffectiveDateValid, isResidenceLocationValid, totalNumOfHouseholdResidents, householdResidentsTableData, policySource]);

    useEffect(() => {
        registerComponentValidation(isHOHouseholdInfoValid);
    }, [registerComponentValidation, isHOHouseholdInfoValid]);

    const emptyHOHouseholdContactDTO = viewModelService.create(
        {}, 'pc', 'wni.edge.capabilities.gateway.contact.dto.HOHouseholdContactDTO'
    );
    const relationshipToInsuredListsAvailableValues = _.get(emptyHOHouseholdContactDTO, 'relationshipToInsured_Ext.aspects.availableValues', []);

    const showPrimaryNamedInsuredModal = useCallback(
        (primaryNamedInsuredVM, policyNamedInsuredCandidatesVM) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                primaryNamedInsuredVM,
                policyNamedInsuredCandidatesVM,
                viewModelService,
                authHeader
            };
            return modalApi.showModal(
                <PrimaryNamedInsuredInputPopup {...componentProps} />
            );
        }, [viewModelService, authHeader]
    );
    const openEditPrimaryNamedInsuredPopup = useCallback(() => {
        const primaryNamedInsuredVM = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext');
        const policyNamedInsuredCandidatesVM = _.get(
            submissionVM,
            'baseData.policyNamedInsuredCandidates_Ext'
        );
        showPrimaryNamedInsuredModal(primaryNamedInsuredVM, policyNamedInsuredCandidatesVM)
            .then((updatedVM) => {
                _.set(submissionVM.value, 'baseData.primaryNamedInsured_Ext', updatedVM.value);

                const newAddress = _.get(updatedVM.value, 'primaryAddress');
                _.set(submissionVM.value, 'baseData.policyAddress', newAddress);

                // Find updated account contact
                const accountContactsVM = _.get(
                    submissionVM,
                    'baseData.accountContacts_Ext'
                );
                const accountHolder = _.get(submissionVM.value, 'baseData.accountHolder');
                const newNamedInuseredVMIndex = _.get(accountContactsVM, 'value', []).findIndex(
                    (accountContactVM) => accountContactVM.publicID === _.get(updatedVM, 'value.publicID')
                );
                _.set(submissionVM.value, `baseData.accountContacts_Ext[${newNamedInuseredVMIndex}]`, updatedVM.value);
                if (accountHolder.publicID === _.get(updatedVM, 'value.publicID')) {
                    _.set(submissionVM.value, 'baseData.accountHolder', updatedVM.value);
                }
                updateSubmissionVM(submissionVM);
            }).catch(() => _.noop());
    },[showPrimaryNamedInsuredModal, submissionVM, updateSubmissionVM]);

    useEffect(() => {
        if (isOpenPNIPopupPageInit) {
            openEditPrimaryNamedInsuredPopup();
        }
    }, [isOpenPNIPopupPageInit]);
    
    const userPublicID = authUserData && authUserData.publicID;
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
 
    // require for issuance util
    const isRequiredForIssuance = checkRequiredForIssuance && IssuanceValidationUtil.isRequiredForIssuanceR2(baseData, wizardPageData);

    const requiredForIssuranceAllFields = ['residenceQuestionDropdown', 'generalContractorToggle',
        'constructionToggle', 'estimatedCompletionDateDropdown', 'isThisResidenceOccupiedSeasonallyToggle'];

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const firstName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.firstName');
        const lastName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.lastName');
        return `${firstName} ${lastName}`;
    }, [submissionVM]);

    const getPrimaryNamedInsuredAndDisplay = useCallback(() => {
        if(_.isNil(primaryNamedInsured)) {          
            const displayName = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].displayName');
            let primaryAddress = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode')
            if(_.isNil(primaryAddress)){
                primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.displayName') 
            }
            return (
                <div>
                    <div>{displayName}</div>
                    <div>{primaryAddress}</div>
                </div> 
            );
        }
        // Not use Display Name since it is readOnly in PC
        // const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.displayName');
        let primaryAddress= _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.recommendCode')
        if (_.isNil(primaryAddress)) {
            const primaryAddressInfo = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress')
            const displayAddressLine1 = _.get(primaryAddressInfo, 'addressLine1') ? _.get(primaryAddressInfo, 'addressLine1') : _.get(primaryAddressInfo, 'pobox_Ext')
            primaryAddress = `${displayAddressLine1}, ${_.get(primaryAddressInfo, 'city')} ${_.get(primaryAddressInfo, 'postalCode')}`;
        }
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{primaryAddress}</div>
            </div> 
        );
    }, [getPrimaryNamedInsuredDisplayName, primaryNamedInsured, submissionVM.value]);

    const getResidenceLocaltionAndDisplay = useCallback(() => {
        const dwellingLocation = _.get(submissionVM, 'value.lobData.homeowners.householdInfoDTO_Ext.dwellingLocation');
        let displayAddress;
        let displaystate;
        if (dwellingLocation) {
            if(_.isNil(_.get(dwellingLocation, 'address.addressLine1'))){
                displayAddress = ''
                displaystate = ''
            }else{
                displayAddress = `${_.get(dwellingLocation, 'address.addressLine1')}, ${_.get(dwellingLocation, 'address.city')}`;
                displaystate = `${_.get(dwellingLocation, 'address.state')}, ${_.get(dwellingLocation, 'address.postalCode')}`;
            }
        } else {
            // TODO 
            // For account without existing policy, default to PNI's address if PNI's address is not PO box. If PNI's address is PO Box, leave it as blank.
            // For account with existing policy (PA), we will define requirement in future user story to copy data from PA. As of now, default to PNI's address if PNI's address is not PO box. If PNI's address is PO Box, leave it as blank.
            displayAddress = ''
            displaystate = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{displayAddress}</div>
                <div>{displaystate || '-'}</div>
            </div>
        );
    }, [submissionVM]);

    const getPriorAddressAndDisplay = useCallback(() => {
        const priorAddress = _.get(submissionVM, 'value.lobData.homeowners.householdInfoDTO_Ext.priorAddress');
        let displayName = '';
        let primaryAddress = '';
        if (priorAddress) {
            displayName = _.get(priorAddress, 'addressLine1');
            primaryAddress= `${_.get(priorAddress, 'city')}, ${_.get(priorAddress, 'state')}, ${_.get(priorAddress, 'postalCode')}`;
        }
        return (
            <div>
                <div>{displayName}</div>
                <div>{primaryAddress || '-'}</div>
            </div>
        );
    }, [submissionVM]);

    // const getOccupancyValue = useCallback(() => {
    //     const occupancyValue = _.get(submissionVM, 'lobData.homeowners.householdInfoDTO_Ext.namedInsuredOccupancy.aspects.availableValues')
    //     const displayOccupancy = occupancyValue.map((occupancy) => {
    //         return {
    //             code: occupancy.code,
    //             name:  translator({ id: occupancy.name }),
    //         }
    //     })
    //     return displayOccupancy
    // },[submissionVM, translator])

    const getCoverageFormValue = useCallback(() => {
        const coverageFormValue = _.get(submissionVM, 'lobData.homeowners.householdInfoDTO_Ext.coveragesForm._aspects.availableValues')
        const displayCoverageForm = coverageFormValue.map((coverageForm) => {
            return {
                code: coverageForm.code,
                name: translator({ id: coverageForm.name })
            }
        })
        const filteredCoverageForm = _.filter(displayCoverageForm, (item) => DEFAULT_COVERAGE_FORM.includes(item.code))   
        return filteredCoverageForm
    }, [submissionVM, translator])

    const getDescribeOccupancyValue = useCallback(() => {
        const allDescribeOccupancyValueOptions =  _.get(submissionVM, 'lobData.homeowners.householdInfoDTO_Ext.namedInsuredOccupancy._aspects.availableValues')
        const availableOptionList = ['fulltimeyearround_Ext', 'atleasthalfofeachyear_Ext', 'lessthanhalfofeachyear_Ext', 'unoccupied_Ext', 'vacant_Ext']
        const displayAllResidenceType = allDescribeOccupancyValueOptions.map((options) => {
            return {
                code: options.code,
                name: translator({ id: options.name })
            }
        })
        const filteredOptions = _.filter(displayAllResidenceType, (item) => availableOptionList.includes(item.code))
        return filteredOptions
    }, [submissionVM, translator])

    const getResidenceTypeDropdown = useCallback(() => {
        const allResidenceTypeValue = _.get(submissionVM, 'lobData.homeowners.householdInfoDTO_Ext.residenceType._aspects.availableValues')
        const displayallResidenceType = allResidenceTypeValue.map((residenceType) => {
            return {
                code: residenceType.code,
                name: translator({ id: residenceType.name })
            }
        })
        const ho3ResidenceType = ['singlefamilydwelling_Ext', 'twofamilydwelling_Ext', 'threeormorefamily_Ext', 'townhouse_Ext']
        const ho4ResidenceType = ['singlefamilydwelling_Ext', 'twofamilydwelling_Ext', 'threeormorefamily_Ext', 'hopcondo', 'hopapartment_Ext','townhouse_Ext']
        // const ho5ResidenceType = ['singlefamilydwelling_Ext', 'twofamilydwelling_Ext', 'threeormorefamily_Ext', 'townhouse_Ext']
        // const ho6ResidenceType = ['hopcondo', 'townhouse_Ext']
        switch (coveragesForm) {
            case 'ho3':
            case 'ho5':
                return _.filter(displayallResidenceType, (item) => ho3ResidenceType.includes(item.code));
            case 'ho4':
            case 'ho6':
                return _.filter(displayallResidenceType, (item) => ho4ResidenceType.includes(item.code))
            default:
                return []
        }

    }, [coveragesForm, submissionVM, translator])

    // const getResidenceQuestionDropdown = useCallback(() => {
    //     const residenceQuestionSelections = _.get(submissionVM, 'lobData.homeowners.householdInfoDTO_Ext.underNewConstructionType._aspects.availableValues')
    //     return residenceQuestionSelections.map((selection) => {
    //         return {
    //             code: selection.code,
    //             name: translator({ id: selection.name })
    //         }
    //     })
    // }, [submissionVM, translator])


    const coveragesFormLogic = useCallback(() => {
        switch (coveragesForm) {
            case 'ho3': return true
            case 'ho4': return false
            case 'ho5': return true
            case 'ho6': return false
            default: ''
        }
    },[coveragesForm])

    const baseStateLogic = useCallback(() => {
        switch (baseState) {
            case 'AK': 
            case 'IA': 
            case 'MN': 
            case 'SD': 
            case 'WI': return true;
            default: return false;
        }
    },[baseState])

    const shouldShowResidenceQuestionDropdown = useCallback(() => {
        return coveragesFormLogic() && baseStateLogic()
    },[baseStateLogic, coveragesFormLogic])

    const isNamedInsuredContractorEnteredNo = useCallback(() => {
        if (isNamedInsuredContractor === false) {
            return true;
        } 
        return false;
    }, [isNamedInsuredContractor])
    const shouldShowGeneralContractorToggle = useCallback(() => {
        const selection = (underNewConstructionType === 'yeswithnewconstruction' ||
                    underNewConstructionType === 'yeswithstructuralrenovation');
        return selection && coveragesFormLogic() && baseStateLogic()
    }, [baseStateLogic, coveragesFormLogic, underNewConstructionType])

    const shouldShowConstructionToggle = useCallback(() => {
        const selection = (underNewConstructionType === 'yeswithnewconstruction' && isNamedInsuredContractorEnteredNo());
        return selection && coveragesFormLogic() && baseStateLogic()
    },[baseStateLogic, coveragesFormLogic, isNamedInsuredContractorEnteredNo, underNewConstructionType])

    const shouldShowEstimatedCompletionDateDropdown = useCallback(() => {
        const selection = (underNewConstructionType ===
            'yeswithstructuralrenovation' ||
        (underNewConstructionType === 'yeswithnewconstruction' && isNamedInsuredContractorEnteredNo()));
        return selection && coveragesFormLogic() && baseStateLogic()
    },[baseStateLogic, coveragesFormLogic, isNamedInsuredContractorEnteredNo, underNewConstructionType]);

    const createEmptyVM = useCallback(() => {
        const emptyVM = viewModelService.create({ 
            address: {
                country: 'US'
            }
        },
        'pc', 'edge.capabilities.policylocation.lob.workerscomp.dto.PolicyLocationDTO');
        return emptyVM;
    }, [viewModelService]);

    const openResidenceLocationPopup = useCallback(async() => {
        const dwellingLocation = _.get(
            submissionVM, 
            'lobData.homeowners.householdInfoDTO_Ext.dwellingLocation'
        );

        const dwellingLocationList = await HOHouseholdInfoService.retrieveDwellinglocationList(quoteID, sessionUUID, authHeader);
        
        const refactDwellingLocationList = ((_.get(dwellingLocationList,'length', 0) > 0) ? dwellingLocationList : [])
            .map((locationDTO) => {
                return {
                    index:parseInt(locationDTO.dwellingLocation.displayName),
                    publicID: locationDTO.publicID || locationDTO.accountLocPublicID,
                    address: locationDTO.dwellingLocation
                }
            })
        const orderedDwellingLocationList = _.orderBy(refactDwellingLocationList, ['index'], ['asc'])

        const title = messages.editResidenceLocation
        const componentProps = {
            iconClassType: false,
            title: title,
            actionBtnLabel: 'OK',
            cancelBtnLabel: 'Cancel',
            showEmployeeFlagToggle: true,
            addressDataVM: dwellingLocation,
            addressOptions: orderedDwellingLocationList,
            viewModelService,
            emptyVM: createEmptyVM()
        };
        modalApi.showModal(
            <HouseholdLocationPopupComponent {...componentProps}/>
        ).then(async (res) => {
            setLoadingMask(true);
            const isResidenceLocChanged = !_.isEqual(res, dwellingLocation.value)
            const rs = await HOHouseholdInfoService.saveOrUpdateResidenceLocation(quoteID, sessionUUID, isResidenceLocChanged, res, authHeader);
            updateWizardPageData({ [WizardConstants.calledRCTFlag]: false });
            const newSubmissionVM = _.clone(submissionVM);
            // update dwellingLocation filed
            const newDwellingLocation = _.get(rs, 'dwellingLocation');
            _.set(newSubmissionVM, 'value.lobData.homeowners.householdInfoDTO_Ext.dwellingLocation', newDwellingLocation);
            updateSubmissionVM(newSubmissionVM);
        }).catch(() => {
            _.noop();
        }).finally(() => {
            setLoadingMask(false);
        });
    }, [authHeader, createEmptyVM, quoteID, sessionUUID,
        setLoadingMask, submissionVM, updateSubmissionVM,
        viewModelService, updateWizardPageData])

    const openPriorAddressPopup = useCallback(() => {
        const priorAddress = _.get(
            submissionVM,
            'value.lobData.homeowners.householdInfoDTO_Ext.priorAddress'
        );
        const emptyVM = createEmptyVM();
        if (priorAddress) {
            _.set(emptyVM, 'value.address', priorAddress);
        }
        const title = messages.editPreviousLocation
        const componentProps = {
            iconClassType: false,
            title: title,
            actionBtnLabel: 'OK',
            cancelBtnLabel: 'Cancel',
            showEmployeeFlagToggle: true,
            addressDataVM: emptyVM,
            viewModelService,
            emptyVM: createEmptyVM(),
            showDropDown: false
        };
        modalApi.showModal(
            <HouseholdLocationPopupComponent {...componentProps}/>
        ).then(async (res) => {
            setLoadingMask(true);
            const rs = await HOHouseholdInfoService.saveOrUpdatePreviousAddressLocation(quoteID, sessionUUID, res, authHeader);
            const newSubmissionVM = _.clone(submissionVM);
            // update Previous Address filed
            const newPriorAddress = _.get(rs, 'priorAddress');
            _.set(newSubmissionVM, 'value.lobData.homeowners.householdInfoDTO_Ext.priorAddress', newPriorAddress);
            updateSubmissionVM(newSubmissionVM);
        }).catch(() => {
            _.noop();
        }).finally(() => {
            setLoadingMask(false);
        });
    }, [authHeader, createEmptyVM, quoteID, sessionUUID,
        setLoadingMask, submissionVM, updateSubmissionVM,
        viewModelService])

    const getNamedInsuredOwnThePropertyValue = useCallback(() => {
        switch (coveragesForm) {
            case 'ho3': 
            case 'ho5': 
            case 'ho6': 
                _.set(submissionVM, 'lobData.homeowners.householdInfoDTO_Ext.isNamedInsuredOwnTheProperty', true)
                return true
            case 'ho4': 
                _.set(submissionVM, 'lobData.homeowners.householdInfoDTO_Ext.isNamedInsuredOwnTheProperty', false)
                return false
            default: ''
        }
    },[coveragesForm])
    const getExternalAgencyOptions = async () => {
        if (isExternalUser) {
            const agencies = await WniAccountService.getAgencyMatchData('', 6, authHeader).then((agencyResponse) => {
                return agencyResponse.map((selectRow) => {
                    const agencyDisplay = `${selectRow.code} - `
                        + `${selectRow.description ? `${selectRow.description}-` : null} `
                        + `${selectRow.city_Ext ? selectRow.city_Ext : null}, `
                        + `${selectRow.state_Ext ? selectRow.state_Ext : null}`;
                    return {
                        code: selectRow.code,
                        name: agencyDisplay,
                        publicID: selectRow.publicID
                    };
                });
            });
            return agencies;
        }
        return null;
    };

    const updateLicensedAgent = (value) => {
        _.set(submissionVM, 'baseData.producerOrLicensedAgent_Ext', value)
        updateSubmissionVM(submissionVM)
    };

    const updateServicingAgent = (value) => {
        _.set(submissionVM, 'baseData.servicingAgent_Ext', value)
        updateSubmissionVM(submissionVM)
    };

    const getLicensedAgentOptions = async (newProducerCodePublicID) => {
        const res = await WniAccountService
            .getLicensedAgentData(newProducerCodePublicID, authHeader);
        const options = res.map((value) => {
            return {
                code: value.publicID,
                name: value.displayName
            };
        });
        return options;
    };

    const getAgentStatus = async () => {
        if (producerCodePublicID) {
            const res = await WniAccountService.getAgentStatus(producerCodePublicID, userPublicID, authHeader);
            return res
        }
        return '';
    };

    const getServicingAgentOptions = async (newProducerCodePublicID) => {
        const res = await WniAccountService.getServicingAgentData(newProducerCodePublicID, authHeader);
        const options = res.map((value) => {
            return {
                code: value.publicID,
                name: value.displayName
            };
        });
        return options;
    };

    const updateExternalAgency = async (value) => {
        const changedName = value.name
        const changedCode = value.code
        let changedProducerCodePublicID = ''
        if(isExternalUser) {
            changedProducerCodePublicID = value.code;
        }else{
            changedProducerCodePublicID = value.publicID
        }
        const servicingRes = await getServicingAgentOptions(changedProducerCodePublicID);
        const licensedAgentsRes = await getLicensedAgentOptions(changedProducerCodePublicID);
        const newWizardPageData = {
            [WizardConstants.servicingAgentOptions]: servicingRes,
            [WizardConstants.licensedAgentOptions]: licensedAgentsRes,
        };
        _.set(submissionVM, 'baseData.producerCodePublicID_Ext', changedProducerCodePublicID)
        _.set(submissionVM, 'baseData.producerDisplay_Ext', changedName)
        _.set(submissionVM, 'baseData.producerCode_Ext', changedCode)
        updateWizardPageData(newWizardPageData);
        updateSubmissionVM(submissionVM)
        // updateExternalAgencyValue(value);
    };


    /**
     * When initializing updateWizardPageData,
     * it needs to be integrated in a method, otherwise data coverage will occur
     */
    const getAgentInfo = async () => {
        let agencyRes;
        let servicingRes;
        let agentStatusRes;
        let licensedAgentsRes;
        const newWizardPageData = {};

        if (!agentStatus) {
            agentStatusRes = await getAgentStatus();
            _.set(newWizardPageData, WizardConstants.agentStatus, agentStatusRes);
        }
        

        if (!externalAgencyOptions && isExternalUser) {
            agencyRes = await getExternalAgencyOptions();
            _.set(newWizardPageData, WizardConstants.externalAgencyOptions, agencyRes);
               
        }
        if (producerCodePublicID) {
            if (!servicingAgentOptions) {
                servicingRes = await getServicingAgentOptions(producerCodePublicID);
                _.set(newWizardPageData, WizardConstants.servicingAgentOptions, servicingRes);
            }
            if (!licensedAgentOptions) {
                licensedAgentsRes = await getLicensedAgentOptions(producerCodePublicID);
                _.set(newWizardPageData, WizardConstants.licensedAgentOptions, licensedAgentsRes);
                if (isExternalUser && agentStatusRes === 'Licensed' && !_.get(submissionVM, 'baseData.producerOrLicensedAgent_Ext.value')) {
                    updateLicensedAgent(userPublicID);
                }
            }
            
        }

        // No need to update wizardData here. In fact, not only it is redundant,
        // it also causes troube: it will grey out the following pages in sideBar
        // updateSubmissionVM(submissionVM)
        updateWizardPageData(newWizardPageData);
    };

    const preQualQuestionSets = _.get(submissionVM.value, 'lobData.homeowners.preQualQuestionSets', [])
    const policyInfoQuestions = preQualQuestionSets
        .find((questionSet) => questionSet.code === 'HOPPolicyInfoUWQuestions_Ext')
    
    const hasAnyDeclinedHomeOwnerCov = policyInfoQuestions.answers.hasAnyDeclinedHomeOwnerCov_Ext
    const hasForeclosure = policyInfoQuestions.answers.hasForeclosure_Ext
    const hasBeenIndictedIn5Years = policyInfoQuestions.answers.hasBeenIndictedIn5Years_Ext
    
    const questionSetFilter = WniQuestionSetUtil.getQSFilterForHoHouseholdInfo(isFeb19EntityChangeVerExt, isApr16EntityChangeVerExt, policyInfoQuestions)
    const questionSetMapper = WniQuestionSetUtil.getQSMapperForHoHousehold(baseState, hasAnyDeclinedHomeOwnerCov,hasForeclosure,hasBeenIndictedIn5Years);

    const updateExistFinishedPolicyAnswers = () => {
        let isExistFinishedPA = null
        accountCompletedQuotes.forEach((job) => {
            if(job.productCode === 'PersonalAuto' && (job.jobStatus === 'Bound' || job.jobStatus === 'Scheduled')) {
                isExistFinishedPA = true
            }
        });
        const isThereExistingPolicyExt = 'lobData.homeowners.preQualQuestionSets.children[0].answers.value.isThereExistingPolicy_Ext';
        if(isExistFinishedPA && !_.get(submissionVM, isThereExistingPolicyExt)) {
            _.set(submissionVM, isThereExistingPolicyExt, isExistFinishedPA);
        }
    }
    
    const refreshHouseholdResidentsTable = useCallback((datas) => {
        const pni = _.cloneDeep(primaryNamedInsured);
        _.set(pni, 'relationshipToInsured_Ext', 'PRIMARYNAMEDINSURED');
        const initData = [];
        initData.push(pni);
        _.each(datas, (contact) => {
            if (_.get(contact, 'contactDenormPublicID') !== _.get(pni, 'publicID')) {
                initData.push(contact);
            }
        })
        updateHouseholdResidentsTableData(initData)
    }, [primaryNamedInsured]);

    useEffect(() => {
        getAgentInfo();
        refreshHouseholdResidentsTable(householdResidents);
        updateExistFinishedPolicyAnswers();
    }, []);


    const updateLicensedAgentOptions = (value) => {
        updateWizardPageData({ [WizardConstants.licensedAgentOptions]: value });
    };

    const updateServicingAgentOptions = (value) => {
        updateWizardPageData({ [WizardConstants.servicingAgentOptions]: value });

    };

    const handleAddHouseholdResident = async (event, editItem = null, editMode = false) => {      
        // fetch options
        const exsitingContacts = await HOHouseholdInfoService.getUnassignedContacts(quoteID, sessionUUID, authHeader);
        const exsitingContactsOptions = [];
        _.each(exsitingContacts, (contact) => {
            const opt = {
                code: JSON.stringify(contact),
                name: _.get(contact, 'displayName')
            };
            exsitingContactsOptions.push(opt);
        })
        exsitingContactsOptions.push({
            code: 'New',
            name: 'New'
        });
        // create Contact and using PNI address as default
        let initialValue = {
            westernNationalEmployee: false
        };
        if (editItem) {
            initialValue = {
                ...editItem
            }
        }
        const contactVM = viewModelService.create(
            initialValue, 'pc', 'wni.edge.capabilities.gateway.contact.dto.HOHouseholdContactDTO'
        );
        let contactVMList = householdResidentsTableData.map((res) => viewModelService.create(
            res, 'pc', 'wni.edge.capabilities.gateway.contact.dto.HOHouseholdContactDTO'
        ))

        if(!editItem) {
            const newVM = viewModelService.create(
                {publicID: ''}, 'pc', 'wni.edge.capabilities.gateway.contact.dto.HOHouseholdContactDTO'
            )
            contactVMList = contactVMList.concat(newVM)
        }

        const title = editMode ? translator(messages.editHouseholdResident) : translator(messages.addHouseholdResident);
        const nextButtonVisible = (totalNumOfHouseholdResidents >= 3)
        const componentProps = {
            title: title,
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: 'OK',
            cancelBtnLabel: 'Cancel',
            contactVM: contactVM,
            defaultList: contactVMList,
            selectedContactPublicID: editItem ? editItem.publicID : '',
            viewModelService: viewModelService,
            exsitingContactsOptions,
            editMode,
            baseState,
            nextButtonVisible,
            saveAndNextMessageLabel: 'Save & Next Person',
            productCode,
            displayFields: {
                additionalNamedInsured: true,
            },
        };
        modalApi.showModal(<HouseholdResidentsPopup {...componentProps} />)
            .then(async (res) => {
                const listToUpdate = res.filter((elt) => _.get(elt, 'relationshipToInsured_Ext') !== 'PRIMARYNAMEDINSURED')
                setLoadingMask(true);
                const rs = await HOHouseholdInfoService.updatePersonContactList(quoteID, sessionUUID, listToUpdate, authHeader);
                // update tableData
                refreshHouseholdResidentsTable(rs.contacts);

                const enableHODPAccountSync = _.find(featureToggles, ({ code }) => code === 'EnableHODPAccountSync');
                if (enableHODPAccountSync) {
    
                    // get error and warning messages
                    const errorsAndWarnings = _.get(rs, 'errorsAndWarnings');
                    const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
                    updateValidationIssues(newValidationIssues);
                    updateShowErrors(true);
                }
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            });
    };

    const renderRelationshipToInsured = useCallback((item, index, property) => {
        const relationshipToInsuredValue = _.get(item, property.path);
        let value = '';
        if (relationshipToInsuredValue === 'PRIMARYNAMEDINSURED') {
            return 'Primary Named Insured';
        }
        if (!_.isNil(relationshipToInsuredValue)) {
            const interestTypeKey = _.find(relationshipToInsuredListsAvailableValues,
                (ttx) => ttx.code === relationshipToInsuredValue);
            value = translator({ id: _.get(interestTypeKey, 'name') });
        }
        return value;
    }, [relationshipToInsuredListsAvailableValues, translator]);

    const renderAdditionalNamedInsured = useCallback((item, index, property) => {
        const additionalNamedInsuredValue = _.get(item, property.path);
        let value = 'No';
        if (additionalNamedInsuredValue === 'PolicyAddlNamedInsured') {
            value = 'Yes';
        }
        return value;
    }, []);
    
    const editHouseholdRow = (event, item) => {
        return handleAddHouseholdResident(event, item, true);
    };

    const deleteHouseholdRow = useCallback(async (event, item) => {
        modalApi.showConfirm({
            title: messages.deleteHouseholdTitle,
            message: messages.deleteHouseholdMessage,
            status: 'warning',
            icon: 'gw-error-outline'
        }).then(
            async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                const publicID = _.get(item, 'publicID');
                try {
                    setLoadingMask(true);
                    const rs = await HOHouseholdInfoService.removeContacts(quoteID, sessionUUID, publicID, authHeader);
                    // update tableData
                    refreshHouseholdResidentsTable(rs.contacts);
                    const enableHODPAccountSync = _.find(featureToggles, ({ code }) => code === 'EnableHODPAccountSync');
                    if (enableHODPAccountSync) {
        
                        // get error and warning messages
                        const errorsAndWarnings = _.get(rs, 'errorsAndWarnings');
                        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
                        updateValidationIssues(newValidationIssues);
                        updateShowErrors(true);
                    }
                } finally {
                    setLoadingMask(false);
                }
                return true;
            }, _.noop
        );
    }, [authHeader, quoteID, refreshHouseholdResidentsTable, sessionUUID, setLoadingMask]);

    const renderHouseholdTableAction = (item, index) => {
        const isPNI = _.get(item, 'relationshipToInsured_Ext') === 'PRIMARYNAMEDINSURED';
        if (isPNI) {
            return '';
        }
        return (
            <>
                <IconButton
                    id={`edit${index}`}
                    icon="gw-edit"
                    iconColor="dark"
                    size="medium"
                    onClick={(e) => editHouseholdRow(e, item)}
                />
                <IconButton
                    id={`delete${index}`}
                    icon="gw-delete"
                    iconColor="dark"
                    size="medium"
                    onClick={(e) => deleteHouseholdRow(e, item)
                    }
                />
            </>
        );
    };

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            // When coverages Form is changed, clear residence type value
            if (path === 'lobData.homeowners.householdInfoDTO_Ext.coveragesForm') {
                _.set(newSubmissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.residenceType', '')
            }
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );
    const getFrontendRequiredValiationErrors = useCallback(() => {
        if (!isRequiredForIssuance) {
            return [];
        } 
        const newRequiredForIssuranceFields = invalidFields.filter((item) =>{
            return requiredForIssuranceAllFields.includes(item);
        });
        return newRequiredForIssuranceFields.map((item) => {
            return {
                type: 'error',
                reason: `Below required fields for issuance are missing: "${translator(messages[item])}"`
            };
        });
    }, [submissionVM, invalidFields]);

    const getResidenceLocationValidationErrors = useCallback(() => {
        const isResidenceLocationDataValid = isResidenceLocationValid()
        if (!isResidenceLocationDataValid) {
            return [{
                type: 'error',
                reason: `Residence Location is required.`
            }]
        }

    },[isResidenceLocationValid])

    const baseStateValidation = () => {
        const productRules = _.get(productItems, 'productRules')
        const filterProductWithState = _.find(productRules, (item) => { return item.state === baseState} );
        const availability = _.get(filterProductWithState, 'availability');
        const productCodes = _.get(filterProductWithState, 'productCode');
        const state = _.get(filterProductWithState, 'state');

        if (availability === 'Available') {
        if (productCodes === 'HOPHomeowners' && state === 'AZ' && !enableHOforArizona) {
            return false;
        }
        return true;
        }
        return false
    }
    const isBaseStateAvailble = baseStateValidation()
    const handleValidation = useCallback(
        () => {
            const frontendRequiredValiationErrors = getFrontendRequiredValiationErrors();
            const residenceLocationValidationErrors = getResidenceLocationValidationErrors();
            // updateRequiredForIssuanceFields(frontendRequiredValiationErrors);
            updateValidationIssues(frontendRequiredValiationErrors);
            updateValidationIssues(residenceLocationValidationErrors);
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },
        [updateShowErrors, invalidFields]
    );


    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        const hoHouseholdInfoData = _.get(submissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext')
        const questionSetData = _.get(submissionVM.value, 'lobData.homeowners.preQualQuestionSets')
        const quoteBaseData = _.get(submissionVM.value, 'baseData')
        // const res = await HOHouseholdInfoService
        //     .saveHoHouseholdInfoData(
        const res = await saveHoHouseholdInfo(
            quoteID, 
            sessionUUID,
            hoHouseholdInfoData,
            questionSetData,
            quoteBaseData,
            authHeader);
        setLoadingMask(false);
        submissionVM.value = res;
        updateSubmissionVM(submissionVM);
        
        // Next step: move this and the service call into HOWizardPage as a common feature
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }

        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            return false;
        }
        return submissionVM;
    }, [setLoadingMask,
        submissionVM, 
        quoteID, 
        sessionUUID, 
        authHeader, 
        viewModelService,
        updateSubmissionVM,
        displayWarnings]);

    const productAvailableStates = getProductAvailableStates(productCode);
    const getPolicyAvailableStates = () => {
        const policyStates = _.get(submissionVM, `baseData.baseState_Ext.aspects.availableValues`);
        const availablePolicyStates = policyStates.filter((item) => productAvailableStates.includes(item.code));
        return availablePolicyStates.map((state) => {
            return {
                code: state.code,
                name: translator({ id: state.name })
            }
        })
    };

    useEffect(() => {
        if (isHouseholdInfoFirstEnter) {
            const policyAvailableStates = getPolicyAvailableStates();
            const findBaseState = policyAvailableStates.find((item) => item.code === baseState);
            const policyStateValue = (findBaseState || policyAvailableStates[0] || {}).code;
            writeValue(policyStateValue, 'baseData.baseState_Ext');
        }
    }, []);

    const isPolicyStatesReadonly = !isHouseholdInfoFirstEnter || getPolicyAvailableStates().length === 1;

    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(metadata.pageContent), []);
    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrideProps = {
        '@field': {
            // apply to all fields
            // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            labelPosition: 'left',
            showRequired: true,
            isRequiredForIssuance
        },
        coverageFormDropdown: {
            readOnly: setCoverageFormReadOnly,
            availableValues: getCoverageFormValue(),
        },
        describeOccupancyDropdown: {
            availableValues: getDescribeOccupancyValue(),
            tooltip: {
                text: tooltips.namedInsuredOccupancy
            }
        },
        agencyServiceContent: {
            isReadOnly: setAgentComponentReadOnly,
            externalAgencyOptions,
            licensedAgentOptions,
            servicingAgentOptions,
            showErrors,
            updateExternalAgency,
            externalAgencyName: producerCodeDisplayName,
            // externalAgencyCode: producerCode,
            externalAgencyPublicID: producerCodePublicID,
            updateLicensedAgent,
            licensedAgentValue: licensedAgentPublicID,
            servicingAgentValue: servicingAgentPublicID,
            updateServicingAgent,
            updateLicensedAgentOptions,
            updateServicingAgentOptions,
            onValidate
        },
        primaryNamedInsured: {
            value: getPrimaryNamedInsuredAndDisplay(),
        },
        residenceTypeDropdown: {
            availableValues: getResidenceTypeDropdown(),
        },
        residenceLocation: {
            value: getResidenceLocaltionAndDisplay(),
        },
        residenceQuestionDropdown: {
            visible: shouldShowResidenceQuestionDropdown()
        },
        generalContractorToggle: {
            visible: shouldShowGeneralContractorToggle()
        },
        constructionToggle: {
            visible: shouldShowConstructionToggle()
        },
        estimatedCompletionDateDropdown: {
            visible: shouldShowEstimatedCompletionDateDropdown(),
        },
        namedInsuredOwnThePropertyToggle: {
            value: getNamedInsuredOwnThePropertyValue(),
        },
        isThisResidenceOccupiedSeasonallyToggle: {
            visible: (namedInsuredOccupancy !== 'fulltimeyearround_Ext')
        },
        householdResidentsTable: {
            data: householdResidentsTableData,
        },
        relationshipToInsuredColumn: {
            path: 'relationshipToInsured_Ext',
            renderCell: renderRelationshipToInsured,
        },
        additionalNamedInsuredColumn: {
            path: 'subType',
            renderCell: renderAdditionalNamedInsured,
        },
        actionColumn: {
            renderCell: renderHouseholdTableAction,
        },
        primaryNamedInsuredEditIcon: {
            onClick: openEditPrimaryNamedInsuredPopup,
        },
        residenceLocationEditIcon: {
            onClick: openResidenceLocationPopup,
            disabled: jobType !== 'Submission' && coveragesForm !== 'ho4',
        },
        provideThePreviousAddress: {
            visible: hasLivedInLocationLess3Yrs,
            value: getPriorAddressAndDisplay(),
            required: hasLivedInLocationLess3Yrs,
            path: 'lobData.homeowners.householdInfoDTO_Ext.priorAddress'
        },
        provideThePreviousAddressEditIcon: {
            onClick: openPriorAddressPopup,
        },
        effectiveDate: {
            ...getEffectiveDateFieldOverrideProps(),
        },
        policyStateDropdown: {
            availableValues: getPolicyAvailableStates(),
            visible: !isPolicyStatesReadonly
        },
        policyStateDropdownReadonly: {
            visible: isPolicyStatesReadonly
        },
        isAnyPortionOfThePropertyToggle: {
            defaultValue: false,
            value: isPropertyRentedToOthers,
        },
        totalNumberofHouseholdResidents: {
            required: policySource !== 'Converted',
            minValue: _.get(householdResidentsTableData, 'length', 0)
        },
        addHouseholdResident: {
            onClick: handleAddHouseholdResident,
            disabled: totalNumOfHouseholdResidents
                ? totalNumOfHouseholdResidents <=
                    _.get(householdResidentsTableData, 'length', 0)
                : true
        },
        policyUnderwritingQuestionSets: {
            isRequiredForIssuance: isRequiredForIssuance,
            contentFilter: questionSetFilter,
            contentMapper: questionSetMapper,
            // onValidate,
            // getInvalidAnswerKeys: questionSetGetInvalidAnswerKeys,
        }
    };
    const overrides = _.merge(overrideProps, overridesForIssuance);
    //---------------------
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            // validationissuescomponent: ValidationIssuesComponent,
            agencyServiceinfo: AgencyOfServiceComponent,
            // policyholderuwquestion: AQPolicyHolderUWQuestionComponent,
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage
            onNext={isComponentValid && isBaseStateAvailble ? onPageNext : handleValidation}
            showPrevious={false}
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            //
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrides}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

HOHouseholdInfoPage.propTypes = {
    ...wizardProps,
    saveHoHouseholdInfo: PropTypes.func,
    // householdInfoPageOnInit: PropTypes.func,
    isOpenPNIPopupPageInit: PropTypes.bool,
    checkRequiredForIssuance: PropTypes.bool
};

HOHouseholdInfoPage.defaultProps = {
    saveHoHouseholdInfo: HOHouseholdInfoService.saveHoHouseholdInfoData,
    // householdInfoPageOnInit: undefined,
    isOpenPNIPopupPageInit: false,
    checkRequiredForIssuance: true
};

export default HOHouseholdInfoPage;

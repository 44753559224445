import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import React, { useEffect, useState } from 'react';
import { WniInputNumber } from 'wni-common-base-components';

const TermFieldDirectInputNumber = (props) => {
    const {
        term,
        setScheduleItemFunc,
        onValidate: onContainerValidate,
        showErrors,
        isEditable,
        setIsEditing,
    } = props;

    const { code_Ext: termCode, name } = term;

    const { onValidate, isComponentValid } = useValidation(termCode);

    useEffect(() => {
        if (onContainerValidate) {
            onContainerValidate(isComponentValid, termCode);
        }
        return () => {
            if (onContainerValidate) {
                onContainerValidate(true, termCode);
            }
        };
    }, [isComponentValid, onContainerValidate, termCode]);

    const { directValue } = term;
    const [editingValue, setEditingValue] = useState(directValue);

    useEffect(() => {
        setEditingValue(directValue);
    }, [directValue]);

    const handleOnBlur = async () => {
        setIsEditing(false);
        const updatedTerm = {
            ...term,
            directValue: editingValue,
            updated: true,
        };
        setScheduleItemFunc((oldScheduleItem) => {
            const newScheduleItem = _.clone(oldScheduleItem);
            const newTerms = _.get(
                oldScheduleItem,
                'scheduleItemTerms',
                []
            ).map((t) => {
                if (t.code_Ext === termCode) {
                    return updatedTerm;
                }
                return t;
            });
            _.set(newScheduleItem, 'scheduleItemTerms', newTerms);
            return newScheduleItem;
        });
    };

    return (
        <WniInputNumber
            id={termCode}
            label={name}
            onValueChange={(value) => {
                setIsEditing(true);
                setEditingValue(value);
            }}
            onBlur={handleOnBlur}
            showErrors={showErrors}
            value={editingValue}
            readOnly={!isEditable || term.readOnly_Ext}
            required={term.required}
            showRequired={term.required}
            onValidationChange={onValidate}
            decimalPlaces={3}
        />
    );
};

export default TermFieldDirectInputNumber;

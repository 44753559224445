import { defineMessages } from 'react-intl';

export default defineMessages({
    injuredPerson: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.singleInjuryIncidentPopup.injuredPerson',
        defaultMessage: 'Injured Person'
    },
    addNewPerson: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.singleInjuryIncidentPopup.addNewPerson',
        defaultMessage: 'Add new person'
    },
    injuredPersonFirstName: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.singleInjuryIncidentPopup.InjuredPersonFirstName',
        defaultMessage: 'Injured Person First Name'
    },
    injuredPersonLastName: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.singleInjuryIncidentPopup.InjuredPersonLastName',
        defaultMessage: 'Injured Person Last Name'
    },
    injuryDescription: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.singleInjuryIncidentPopup.injuryDescription',
        defaultMessage: 'Injury Description'
    },
    cancel: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.singleInjuryIncidentPopup.cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'wni.pe.fnol.ho.views.incidentdetails.singleInjuryIncidentPopup.save',
        defaultMessage: 'Save'
    }
})
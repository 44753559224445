import { Tab } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import React, { useState } from 'react';
import PreCode from '../../Code/PreCode';
import metadata from './WniImageCheckboxTab.metadata.json5';
import { 
    checkboxGroupCode,
    imageCheckboxFieldCode,
    imageCheckboxGroupCode
 } from './WniImageCheckboxTab.util';

function WniImageCheckboxTab(props) {

    const [checkboxValue1, updateCheckboxValue1] = useState([]);
    const [checkboxValue2, updateCheckboxValue2] = useState(false);
    const [checkboxValue3, updateCheckboxValue3] = useState([]);
    
    const overrideProps = {
        wniCheckboxGroup: {
            value: checkboxValue1,
            onValueChange: (val) => updateCheckboxValue1(val)
        },
        wniCheckboxGroupCodes: {
            data: checkboxGroupCode
        },
        imageCheckboxField: {
            value: checkboxValue2,
            onValueChange: (val) => updateCheckboxValue2(val),
        },
        imageCheckboxFieldCodes: {
            data: imageCheckboxFieldCode
        },
        imageCheckboxGroup: {
            value: checkboxValue3,
            onValueChange: (val) => updateCheckboxValue3(val),
        },
        imageCheckboxGroupCodes: {
            data: imageCheckboxGroupCode
        }
        
    };
    const resolvers = {
        resolveComponentMap: {
            preCode: PreCode
        },
        resolveCallbackMap: {
         
        }
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}

export default WniImageCheckboxTab;

import { Card } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import React from 'react';
import PreCode from '../../Code/PreCode';
import metadata from './WniProductDataTab.metadata.json5';
import { useProductDataCodes, wniProductCodes } from './WniProductDataTab.util';
import styles from './WniProductDataTab.module.scss'

const renderProductDataContent = () => {
    return (
        <Card title="Determine if the product is enabled">
            <ul>
                <li className='ph-5'>1. Determine whether the product is available in the ProductDesigner of the PolicyCenter.</li>
                <li className='ph-5'>2. Determine whether the product is available in Portal Management.</li>
                <li className='ph-5'>3. Determine the availableQuoteStates and defaultQuoteStates configured in Portal Config.json5</li>
            </ul>
        </Card>
    );
};

function WniProductDataTab(props) {
    const overrideProps = {
        WniProductsCodes: {
            data: wniProductCodes,
            activeTab: 'ProductsUtil',
        },
        useProductData: {
            content: renderProductDataContent(),
        },
        useProductDataCodes: {
            data: useProductDataCodes,
        },
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            preCode: PreCode,
        },
        resolveCallbackMap: {},
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}

export default WniProductDataTab;

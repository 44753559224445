
const inputTextCode = {
    jsx: `
    import { Button } from '@jutro/legacy/components';
    import { WniInputTextWithAction } from 'wni-common-base-components';

    <WniInputTextWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            // text: "Search",
            icon: "gw-search",
            type: "outlined"
        }}
        type="gap",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 1"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniInputTextWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            // text: "Search",
            icon: "gw-search",
            type: "filled"
        }}
        type="gap",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 2"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniInputTextWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            text: "Search",
            icon: "gw-search",
            type: "outlined"
        }}
        type="inline",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 3"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniInputTextWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            text: "Search",
            icon: "gw-search",
            type: "filled"
        }}
        type="inline",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 4"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniInputTextWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            icon: "cust-percent",
            type: "text"
        }}
        type="inline",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 5"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniInputTextWithAction
        type="content",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 6"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    >
        <div className="font-error-light-16">Coverage amount cannot be below current market value</div>
    </WniInputTextWithAction>

    <WniInputTextWithAction
        type="content",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 7"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    >
        <Button>view Example Details</Button>
    </WniInputTextWithAction>
    `,
    metadata: `
    {
        "id": "inputWithAction_1",
        "type": "field",
        "component": "WniInputTextWithAction",
        "componentProps": {
            "actionFn": "onSearch",
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                // "text": "Search",
                "icon": "gw-search",
                "type": "outlined"
            },
            "type": "gap",
            "label": {
                "id": "wni.ui platform.example.field action.Text",
                "defaultMessage": "Text example 1"
            }
        }
    },
    {
        "id": "inputWithAction_2",
        "type": "field",
        "component": "WniInputTextWithAction",
        "componentProps": {
            "actionFn": "onSearch",
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                // "text": "Search",
                "icon": "gw-search",
                "type": "filled"
            },
            "type": "gap",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Text example 2"
            }
        }
    },
    
    {
        "id": "inputWithAction_3",
        "type": "field",
        "component": "WniInputTextWithAction",
        "componentProps": {
            "actionFn": "onSearch",
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                "text": "Search",
                "icon": "gw-search",
                "type": "outlined"
            },
            "type": "inline",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Text example 3"
            }
        }
    },
    {
        "id": "inputWithAction_4",
        "type": "field",
        "component": "WniInputTextWithAction",
        "componentProps": {
            "actionFn": "onSearch",
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                "text": "Search",
                "icon": "gw-search",
                "type": "filled"
            },
            "type": "inline",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Text example 4"
            }
        }
    },
    {
        "id": "inputWithAction_5",
        "type": "field",
        "component": "WniInputTextWithAction",
        "componentProps": {
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                "icon": "cust-percent",
                "type": "text"
            },
            "type": "inline",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Text example 5"
            }
        }
    },
    {
        "id": "inputWithAction_6",
        "type": "field",
        "component": "WniInputTextWithAction",
        "componentProps": {
            "type": "content",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Text example 6"
            }
        },
        "content": [
            {
                "id": "coverageAmountWarningMsg",
                "component": "div",
                "type": "element",
                "componentProps": {
                    "className": "font-error-light-16"
                },
                "content": {
                    "id": "wni.quoteandbind.rt.vehicle.Coverage amount cannot be below current market value",
                    "defaultMessage": "Coverage amount cannot be below current market value"
                }
            }
        ]
    },
    {
        "id": "inputWithAction_7",
        "type": "field",
        "component": "WniInputTextWithAction",
        "componentProps": {
            "type": "content",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Text example 7"
            }
        },
        "content": [
            {
                "id": "inputWithActionContent",
                "component": "button",
                "type": "action",
                "content": "view Example Details"
            }
        ]
    },
    `
}

const dropdownCode = {
    jsx: `
    import { WniDropdownWithAction } from 'wni-common-base-components';
    import { Button } from '@jutro/legacy/components';

    <WniDropdownWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            // text: "Search",
            icon: "gw-search",
            type: "outlined"
        }}
        type="gap",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Dropdown example 1"
        }}
        availableValues={[
            {
                "name": "option 1",
                "code": "option1"
            },
            {
                "name": "option 2",
                "code": "option2"
            },
            {
                "name": "option 3",
                "code": "option3"
            }
        ]}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniDropdownWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            // text: "Search",
            icon: "gw-search",
            type: "filled"
        }}
        type="gap",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Dropdown example 2"
        }}
        availableValues={[
            {
                "name": "option 1",
                "code": "option1"
            },
            {
                "name": "option 2",
                "code": "option2"
            },
            {
                "name": "option 3",
                "code": "option3"
            }
        ]}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniDropdownWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            text: "Search",
            icon: "gw-search",
            type: "outlined"
        }}
        type="inline",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Dropdown example 3"
        }}
        availableValues={[
            {
                "name": "option 1",
                "code": "option1"
            },
            {
                "name": "option 2",
                "code": "option2"
            },
            {
                "name": "option 3",
                "code": "option3"
            }
        ]}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniDropdownWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            text: "Search",
            icon: "gw-search",
            type: "filled"
        }}
        type="inline",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Dropdown example 4"
        }}
        availableValues={[
            {
                "name": "option 1",
                "code": "option1"
            },
            {
                "name": "option 2",
                "code": "option2"
            },
            {
                "name": "option 3",
                "code": "option3"
            }
        ]}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniDropdownWithAction
        type="content",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Dropdown example 5"
        }}
        availableValues={[
            {
                "name": "option 1",
                "code": "option1"
            },
            {
                "name": "option 2",
                "code": "option2"
            },
            {
                "name": "option 3",
                "code": "option3"
            }
        ]}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    >
        <div className="font-error-light-16">Coverage amount cannot be below current market value</div>
    </WniDropdownWithAction>

    <WniDropdownWithAction
        type="content",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Dropdown example 6"
        }}
        availableValues={[
            {
                "name": "option 1",
                "code": "option1"
            },
            {
                "name": "option 2",
                "code": "option2"
            },
            {
                "name": "option 3",
                "code": "option3"
            }
        ]}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    >
        <Button>view Example Details</Button>
    </WniDropdownWithAction>
    
    `,
    metadata: `
    {
        "id": "dropdowns_1",
        "type": "field",
        "component": "WniDropdownWithAction",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Dropdown Example 1"
            },
            "actionFn": "onSearch",
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                "icon": "gw-search",
                "type": "outlined"
            },
            "type": "gap",
            "availableValues": [
                {
                    "name": "option 1",
                    "code": "option1"
                },
                {
                    "name": "option 2",
                    "code": "option2"
                },
                {
                    "name": "option 3",
                    "code": "option3"
                }
            ]
        }
    },
    {
        "id": "dropdowns_2",
        "type": "field",
        "component": "WniDropdownWithAction",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Dropdown Example 2"
            },
            "tooltip": {
                "text": "this is a input Dropdown tooltip"
            },
            "actionFn": "onSearch",
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                // "text": "Search",
                "icon": "gw-search",
                "type": "filled"
            },
            "type": "gap",
            "availableValues": [
                {
                    "name": "option 1",
                    "code": "option1"
                },
                {
                    "name": "option 2",
                    "code": "option2"
                },
                {
                    "name": "option 3",
                    "code": "option3"
                }
            ]
        }
    },
    {
        "id": "dropdowns_3",
        "type": "field",
        "component": "WniDropdownWithAction",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Multi Dropdown",
                "defaultMessage": "Dropdown Example 3"
            },
            "tooltip": {
                "text": "this is a tooltip"
            },
            "actionFn": "onSearch",
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                "text": "Search",
                "icon": "gw-search",
                "type": "outlined"
            },
            "type": "inline",
            "availableValues": [
                {
                    "name": "option 1",
                    "code": "option1"
                },
                {
                    "name": "option 2",
                    "code": "option2"
                },
                {
                    "name": "option 3",
                    "code": "option3"
                }
            ]
        }
    },
    {
        "id": "dropdowns_4",
        "type": "field",
        "component": "WniDropdownWithAction",
        "componentProps": {
            "label": {
                "id": "wni.ui platform.example.field action.Multi Dropdown",
                "defaultMessage": "Dropdown Example 4"
            },
            "tooltip": {
                "text": "this is a tooltip"
            },
            "actionFn": "onSearch",
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                "text": "Search",
                "icon": "gw-search",
                "type": "filled"
            },
            "type": "inline",
            "availableValues": [
                {
                    "name": "option 1",
                    "code": "option1"
                },
                {
                    "name": "option 2",
                    "code": "option2"
                },
                {
                    "name": "option 3",
                    "code": "option3"
                }
            ]
        }
    },
    {
        "id": "dropdowns_5",
        "type": "field",
        "component": "WniDropdownWithAction",
        "componentProps": {
            "type": "content",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Dropdown Example 5"
            },
            "availableValues": [
                {
                    "name": "option 1",
                    "code": "option1"
                },
                {
                    "name": "option 2",
                    "code": "option2"
                },
                {
                    "name": "option 3",
                    "code": "option3"
                }
            ]
        },
        "content": [
            {
                "id": "coverageAmountWarningMsg",
                "component": "div",
                "type": "element",
                "componentProps": {
                    "className": "font-error-light-16"
                },
                "content": {
                    "id": "wni.quoteandbind.rt.vehicle.Coverage amount cannot be below current market value",
                    "defaultMessage": "Coverage amount cannot be below current market value"
                }
            }
        ]
    },
    {
        "id": "dropdowns_6",
        "type": "field",
        "component": "WniDropdownWithAction",
        "componentProps": {
            "type": "content",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Dropdown Example 6"
            },
            "availableValues": [
                {
                    "name": "option 1",
                    "code": "option1"
                },
                {
                    "name": "option 2",
                    "code": "option2"
                },
                {
                    "name": "option 3",
                    "code": "option3"
                }
            ]
        },
        "content": [
            {
                "id": "inputWithActionContent",
                "component": "button",
                "type": "action",
                "content": "view Example Details"
            }
        ]
    },
    `
};

const inputNumberCode = {
    jsx: `
    import { Button } from '@jutro/legacy/components';
    import { WniInputNumberWithAction } from 'wni-common-base-components';

    <WniInputNumberWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            // text: "Search",
            icon: "gw-search",
            type: "outlined"
        }}
        type="gap",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 1"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniInputNumberWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            // text: "Search",
            icon: "gw-search",
            type: "filled"
        }}
        type="gap",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 2"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniInputNumberWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            text: "Search",
            icon: "gw-search",
            type: "outlined"
        }}
        type="inline",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 3"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniInputNumberWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            text: "Search",
            icon: "gw-search",
            type: "filled"
        }}
        type="inline",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 4"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniInputNumberWithAction
        actionFn={onSearch}
        actionVisible={true}
        actionConfig={{
            id: "action",
            icon: "cust-percent",
            type: "text"
        }}
        type="inline",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 5"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    />

    <WniInputNumberWithAction
        type="content",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 6"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    >
        <div className="font-error-light-16">Coverage amount cannot be below current market value</div>
    </WniInputNumberWithAction>

    <WniInputNumberWithAction
        type="content",
        label= {{
            "id": "wni.ui platform.example.field action.Text",
            "defaultMessage": "Text example 7"
        }}
        path={path}
        onValueChange={_.noop}
        onValidate={_.noop}
    >
        <Button>view Example Details</Button>
    </WniInputNumberWithAction>
    
    `,
    metadata: `
    {
        "id": "inputNumber_1",
        "type": "field",
        "component": "WniInputNumberWithAction",
        "componentProps": {
            "actionFn": "onSearch",
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                // "text": "Search",
                "icon": "gw-search",
                "type": "outlined"
            },
            "type": "gap",
            "label": {
                "id": "wni.ui platform.example.field action.Text",
                "defaultMessage": "Input Number 1"
            }
        }
    },
    {
        "id": "inputNumber_2",
        "type": "field",
        "component": "WniInputNumberWithAction",
        "componentProps": {
            "actionFn": "onSearch",
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                // "text": "Search",
                "icon": "gw-search",
                "type": "filled"
            },
            "type": "gap",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Input Number 2"
            }
        }
    },
    {
        "id": "inputNumber_3",
        "type": "field",
        "component": "WniInputNumberWithAction",
        "componentProps": {
            "actionFn": "onSearch",
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                "text": "Search",
                "icon": "gw-search",
                "type": "outlined"
            },
            "type": "inline",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Input Number 3"
            }
        }
    },
    {
        "id": "inputNumber_4",
        "type": "field",
        "component": "WniInputNumberWithAction",
        "componentProps": {
            "actionFn": "onSearch",
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                "text": "Search",
                "icon": "gw-search",
                "type": "filled"
            },
            "type": "inline",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Input Number 4"
            }
        }
    },
    {
        "id": "inputNumber_5",
        "type": "field",
        "component": "WniInputNumberWithAction",
        "componentProps": {
            "actionVisible": true,
            "actionConfig": {
                "id": "action",
                "icon": "cust-percent",
                "type": "text"
            },
            "type": "inline",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Input Number 5"
            }
        }
    },
    {
        "id": "inputNumber_6",
        "type": "field",
        "component": "WniInputNumberWithAction",
        "componentProps": {
            "type": "content",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Input Number 6"
            }
        },
        "content": [
            {
                "id": "coverageAmountWarningMsg",
                "component": "div",
                "type": "element",
                "componentProps": {
                    "className": "font-error-light-16"
                },
                "content": {
                    "id": "wni.quoteandbind.rt.vehicle.Coverage amount cannot be below current market value",
                    "defaultMessage": "Coverage amount cannot be below current market value"
                }
            }
        ]
    },
    {
        "id": "inputNumber_7",
        "type": "field",
        "component": "WniInputNumberWithAction",
        "componentProps": {
            "type": "content",
            "label": {
                "id": "wni.ui platform.example.field action.Dropdown",
                "defaultMessage": "Input Number 7"
            }
        },
        "content": [
            {
                "id": "inputWithActionContent",
                "component": "button",
                "type": "action",
                "content": "view Example Details"
            }
        ]
    },
    `
};



export {
    inputTextCode,
    dropdownCode,
    inputNumberCode,
}
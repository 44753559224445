import React, {
    Component,
    useEffect,
    useState,
    useCallback,
    useContext,
} from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { withIntl, IntlContext, useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withDependencies, useDependencies } from '@xengage/gw-portals-dependency-react';
import { AccountService } from 'gw-capability-gateway-policy';
import { WniAccountService } from 'wni-capability-gateway';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { FieldLinkComponent } from 'gw-components-platform-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { useBusinessData } from 'wni-portals-util-react';
import { WniDateUtil, WniAccountsUtil } from 'wni-portals-util-js';
import { SelectProducerCode } from 'gw-gateway-common-react';
import { BannerMessageComponent } from 'wni-capability-common-react';
import messages from 'gw-capability-gateway-react/Accounts/Accounts.messages';
import currentMessages from './Accounts.messages';
import metadata from './Accounts.metadata.json5';
import styles from './Accounts.module.scss';

const { gatewayParamConfig = {} } = appConfig;

function Accounts(props) {
    const intl = useContext(IntlContext);
    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();
    const { enableCommercialLine } = useBusinessData();
    const { interactionModel } = useDependencies(['interactionModel']);
    const [currentView, updateCurrentView] = useState('recentlyViewedTile');
    const [selectedProducerCode, updateSelectedProducerCode] = useState('all');

    const getQueryParam = (selectedProducerCodeParam) => {
        if (selectedProducerCodeParam === 'myWork') {
            return [
                {
                    propertyName: 'CreateUser',
                    entityName: 'User',
                    entityPublicId: authUserData.publicID,
                    isDbProperty: true,
                    orOperation: false
                }
            ];
        }
        if (selectedProducerCodeParam !== 'all') {
            return selectedProducerCodeParam;
        }
        return null;
    };

    const processResponseData = (accountData) => {
        const accountArray = accountData.map((accountInfo) => {
            const account = {
                isPersonalAccount: accountInfo.isPersonalAccount,
                accountType: WniAccountsUtil.getAccountType(accountInfo.isPersonalAccount),
                insuredName: accountInfo.accountHolder,
                policy: accountInfo.policySummaries.length,
                dateCreated: WniDateUtil.formatDateWithPattern(accountInfo.accountCreatedDate),
                address: accountInfo.accountHolderAddress.displayName,
                openActivities: accountInfo.numberOfOpenActivities,
                accountNumber: accountInfo.accountNumber
            };
            return account;
        });
        return accountArray;
    };

    const getCell = (items, index, { id: property }) => {
        let toolTipMessage = '';
        switch (property) {
            case 'dateCreated':
                toolTipMessage = translator(messages.DateCreated);
                break;
            case 'address':
                toolTipMessage = translator(messages.Address);
                break;
            default:
                toolTipMessage = '';
        }
        return <span title={toolTipMessage}>{items[property]}</span>;
    };

    const getAccount = (item, index, { id: property }) => {
        const toolTipMessage = translator(messages.Account);
        /** defaut account Url to PE url */
        let accountUrl = interactionModel.getURLObj(item.accountType, 'accountSummary', item.accountNumber);
         /** if accountType == 'person': online env need to jump to AO env, and local still jump to PE url 
         *  if accountType == 'company': only need to jump PE only URL
        */
        // POI-51875 Clicking account name navigates user to PE Account summary page For CL
        if (enableCommercialLine) {
            accountUrl = {
                to: `/accounts/${item.accountNumber}/summary`
            }
        }
        return (
            <FieldLinkComponent
                id={`account${item[property]}`}
                value={item[property]}
                title={toolTipMessage}
                {...accountUrl}
            />
        );
    };

    const getPolicyLink = (item, index, { id: property }) => {
        const toolTipMessage = property === 'policy' ? messages.Policies : messages.OpenActivities;
        const redirectPath = property === 'policy' ? 'summary' : 'activities';
        return (
            <FieldLinkComponent
                id={`policy${item[property]}`}
                value={item[property]}
                to={{
                    pathname: `/accounts/${item.accountNumber}/${redirectPath}`,
                    state: redirectPath
                }}
                title={translator(toolTipMessage)}
            />
        );
    };

    const handleValueChange = (producerCodeParam) => {
        updateSelectedProducerCode(producerCodeParam);
    };

    const handleTilesOnClick = (id) => {
        updateCurrentView(id);
    };

    const recentView = async (sorted) => {
        const accountResponse = await WniAccountService.getRecentlyViewedAccountsByAgency(authHeader);
        const data = processResponseData(accountResponse);
        const sortedData = _.orderBy(
            data,
            sorted.map(({ id }) => (row) => row[id]),
            sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
        );
        return {
            rows: sortedData,
            numberOfRows: sortedData.length
        };
    };

    const passingProducerCode = (paramProducerCode) => {
        const isProducerCodeNotSpecific = ['all', 'myWork'].indexOf(paramProducerCode) > -1;
        return isProducerCodeNotSpecific || _.isNull(paramProducerCode);
    };

    const recentCreated = async (paginationParams, paramProducerCode, sorted) => {
        let accountsCreatedInLastXDays = null;
        if (enableCommercialLine) {
            // POI-51950 have been created in the past 2 weeks.
            accountsCreatedInLastXDays = 14;
        }
        const queryParam = getQueryParam(paramProducerCode);
        let accountResponse = null;
        if (passingProducerCode(paramProducerCode)) {
            accountResponse = await AccountService.getAccountsForCurrentUser(
                accountsCreatedInLastXDays || gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        } else {
            accountResponse = await AccountService.getAccountsForProducerCode(
                accountsCreatedInLastXDays || gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        }

        const data = processResponseData(accountResponse.accounts);
        const sortedData = _.orderBy(
            data,
            sorted.map(({ id }) => (row) => row[id]),
            sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
        );
        return {
            rows: sortedData,
            numberOfRows: accountResponse.maxNumberOfResults
        };
    };

    const personalAccounts = async (paginationParams, paramProducerCode, sorted) => {
        const queryParam = getQueryParam(paramProducerCode);
        let accountResponse = null;
        if (passingProducerCode(paramProducerCode)) {
            accountResponse = await AccountService.getPersonalAccountsForCurrentUser(
                gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        } else {
            accountResponse = await AccountService.getPersonalAccountsForProducerCode(
                gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        }

        const data = processResponseData(accountResponse.accounts);
        const sortedData = _.orderBy(
            data,
            sorted.map(({ id }) => (row) => row[id]),
            sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
        );
        return {
            rows: sortedData,
            numberOfRows: accountResponse.maxNumberOfResults
        };
    };

    const commercialAccounts = async (paginationParams, paramProducerCode, sorted) => {
        const queryParam = getQueryParam(paramProducerCode);
        let accountResponse = null;
        if (passingProducerCode(paramProducerCode)) {
            accountResponse = await AccountService.getCommercialAccountsForCurrentUser(
                gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        } else {
            accountResponse = await AccountService.getCommercialAccountsForProducerCode(
                gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        }

        const data = processResponseData(accountResponse.accounts);
        const sortedData = _.orderBy(
            data,
            sorted.map(({ id }) => (row) => row[id]),
            sorted.map(({ desc }) => (desc ? 'desc' : 'asc'))
        );
        return {
            rows: sortedData,
            numberOfRows: accountResponse.maxNumberOfResults
        };
    };

    const loadData = useCallback(({ sorted, pageSize, page }) => {
        const paramProducerCode = selectedProducerCode === 'all' ? null : selectedProducerCode;
        const startIndex = pageSize * page;
        const offsetEndMinusOne = startIndex + pageSize - 1;
        const paginationParams = {
            offsetStart: startIndex,
            offsetEnd: offsetEndMinusOne,
            orderBy: gatewayParamConfig.landingOrderBy,
            orderByDescending: gatewayParamConfig.landingOrderByDescending
        };
        if (currentView === 'recentlyViewedTile') {
            return recentView(sorted);
        }
        if (currentView === 'recentlyCreatedTile') {
            return recentCreated(paginationParams, paramProducerCode, sorted);
        }
        if (currentView === 'personalAccountsTile') {
            return personalAccounts(paginationParams, paramProducerCode, sorted);
        }
        if (currentView === 'commercialAccountsTile') {
            return commercialAccounts(paginationParams, paramProducerCode, sorted);
        }
        return { rows: [], numberOfRows: 0 };
    }, [currentView, selectedProducerCode]);

    // const initData = async(paramProducerCode) => {
    //     const queryParam = getQueryParam(paramProducerCode);
    //     const paginationParams = {
    //         orderBy: gatewayParamConfig.landingOrderBy,
    //         orderByDescending: gatewayParamConfig.landingOrderByDescending
    //     };
    //     const accountResponse = await WniAccountService.getAccounts(
    //         gatewayParamConfig.accountsCreatedInLastXDays,
    //         paginationParams,
    //         queryParam,
    //         authHeader
    //     );
    // };
    // useEffect(() => {
    //     initData(producerCode)
    // }, [producerCode]);
    // ===============================================
    const render = () => {
        const tableKey = currentView + selectedProducerCode;
        const overrides = {
            accountsListTable: {
                onFetchData: loadData,
                key: tableKey,
                defaultSorted: [
                    { id: 'insuredNameColumnTitle', desc: true },
                    { id: 'createdDateColumnTitle', desc: true },
                    // { id: 'openActivitiesColumnTitle', desc: true },
                    { id: 'policiesColumnTitle', desc: true }
                ]
            },
            accountsListTableContainer: {
                title: currentMessages[currentView]
            },
            [currentView]: {
                active: true
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: getCell,
                getAccount: getAccount,
                getPolicyLink: getPolicyLink,
                handleTilesOnClick: handleTilesOnClick,
                handleValueChange: handleValueChange,
                sortNumber: DatatableUtil.sortNumber,
                sortString: DatatableUtil.sortString,
                sortDate: DatatableUtil.sortDate
            },
            resolveComponentMap: {
                selectproducercode: SelectProducerCode,
                bannermessagecomponent: BannerMessageComponent
            }
        };

        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    };
    return render();
}

Accounts.propTypes = {
    
};

export const AccountsComponent = Accounts;
export default Accounts;

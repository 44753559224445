import {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalNext,
    Button
} from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import React from 'react';
import metadata from './SecondModalTest.metadata.json5';

function SecondModalTest(props) {
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        useAuthenticationData,
        useDependenciesData,
        useBusinessData,
        viewModelService,
        breakpoint,
        translator,
    } = props;

    const { interactionModel, loadingMask, workflowType, applicationCache } = useDependenciesData;

    const handleSave = () => {
        onResolve('return the second modal result');
    };

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
        },
    };
    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {},
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} onClose={onReject} />
            <ModalBody id="modalTest">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={{}}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} variant="secondary" label='Cancel'/>
                <Button onClick={handleSave} label="Confirm" />
            </ModalFooter>
        </ModalNext>
    );
}

SecondModalTest.propTypes = {};
SecondModalTest.defaultProps = {
    size: 'md',
};
export default SecondModalTest;
